import React, { FC, useContext } from "react";
import { Redirect, Route } from "react-router-dom";

import { AppContext } from "../context";

interface AppProtectedRouteProps {
  component: any;
  exact: any;
  path: string;
}

const AppProtectedRoute: FC<AppProtectedRouteProps> = (props) => {
  const { component: Component, ...rest } = props;

  const { authenticatedUser } = useContext(AppContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        return authenticatedUser &&
          authenticatedUser !== "" &&
          authenticatedUser !== null &&
          authenticatedUser !== undefined ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        );
      }}
    />
  );
};

export default AppProtectedRoute;
