import React, { FC, useContext, useEffect, useState } from "react";

import {
  Form,
  Input,
  Button,
  message,
  Row,
  Col,
  Upload,
  Switch,
  Radio,
  Select,
  DatePicker,
} from "antd";

import { useHistory } from "react-router-dom";

import { AppContext } from "../context";

import { getBase64, t } from "../utils/helpers";
import { useForm } from "antd/lib/form/Form";

import { UploadOutlined, EditOutlined } from "@ant-design/icons";

import { updateProfile } from "../api/catalog";
import usStates from "../assets/json/us.states.json";
import User from "../models/User";

import DefaultUserProfilePicture from "../assets/image/default-user-profile-image.png";
import { uploadMemberProfileImage } from "../api/auth";
import moment from "moment";

interface EditProfileFormProps {
  User?: User | null;
  updateBilling?: Boolean | false;
}

const EditProfileForm: FC<EditProfileFormProps> = (
  props: EditProfileFormProps
) => {
  const { User, updateBilling } = props;
  const [form] = useForm();
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [fileType, setFileType] = useState<any>("");
  const [cardType, setCardType] = useState<string>("");
  const { authToken, authenticatedUser, userData } = useContext(AppContext);
  const [expiryDate, setExpiryDate] = useState(null)

  useEffect(() => {}, [imageUrl]);

  const onFinish = async (values: any) => {
    if (updateBilling && !expiryDate) {
      message.error("Please select expiry date.")
      return false
    }else if (updateBilling && expiryDate) {
      values.creditCardExpirationDate = expiryDate
    }
    try {
      // console.log("values ", values);
      setLoading(true);
      if (User) {
        const updatedInfo = {
          ...authenticatedUser,
          ...values,
          disabled: false,
          trailExpired: false,
          id: authenticatedUser.id,
        };
        const updateReq = await updateProfile(
          authToken,
          authenticatedUser.id as string,
          updatedInfo as User
        );
        if (updateBilling) {
        } else {
          if (imageUrl) {
            const uploadImageReq = await uploadMemberProfileImage(
              authToken,
              authenticatedUser.id,
              imageUrl.replace(/^data:image\/[a-z]+;base64,/, "")
            );
          }
        }
      }
      setLoading(false);
      updateBilling
        ? history.push(`/subscription`)
        : history.push(`/my-profile/`);
    } catch (err) {
      message.error("Invalid Username and Password Combination!");
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onDatePicker = (date: any) => {
    setExpiryDate(date)
  }

  return (
    <div className="catalog-form-fields-wrapper">
      <Row>
        {updateBilling ? null : (
          <Col xl={6} lg={8} md={24} sm={24} xs={24}>
            <Form>
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item>
                    <Upload
                      accept={"image/*"}
                      multiple={false}
                      className={"poster-upload"}
                      showUploadList={false}
                      onChange={async (info:any) => {
                        const originFileObj = info.file.originFileObj;
                        const isBlob = originFileObj instanceof Blob;

                        if (isBlob === true) {
                          getBase64(originFileObj as File, (data) => {
                            setImageUrl(data.toString());
                          });
                        }
                        setFileType(info.file.type);
                      }}
                    >
                      <div className={"user-profile-picture-upload"}>
                        {imageUrl ? (
                          <img src={imageUrl} alt="avatar" />
                        ) : (
                          <img
                            src={
                              User && User.profileImage
                                ? User?.profileImage
                                : DefaultUserProfilePicture
                            }
                            alt="avatar"
                          />
                        )}
                        {User ? <EditOutlined /> : <UploadOutlined />}
                      </div>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        )}
        <Col
          xl={updateBilling ? 24 : 18}
          lg={updateBilling ? 24 : 16}
          md={24}
          sm={24}
          xs={24}
        >
          <Form
            form={form}
            name="edit-profile-form"
            initialValues={User ? User : {}}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Row gutter={8}>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: t("First Name is required!"),
                    },
                  ]}
                >
                  <Input
                    value={User?.firstName}
                    className={"input-primary"}
                    placeholder={t("First Name")}
                  />
                </Form.Item>
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: t("Last Name is required!"),
                    },
                  ]}
                >
                  <Input
                    value={User?.lastName}
                    className={"input-primary"}
                    placeholder={t("Last Name")}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Form.Item label="Phone Number" name="phoneNumber">
                  <Input
                    value={User?.phoneNumber}
                    className={"input-primary"}
                    placeholder={t("Phone Number")}
                  />
                </Form.Item>
              </Col>

              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Form.Item label="Zip Code" name="zipCode">
                  <Input
                    value={User?.zipCode}
                    className={"input-primary"}
                    placeholder={t("Zip Code")}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Form.Item label="Email" name="email">
                  <Input
                    disabled={true}
                    value={User?.email}
                    className={"input-primary"}
                    placeholder={t("Email")}
                  />
                </Form.Item>
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Form.Item label="AddressLine 1" name="addressLine1">
                  <Input
                    value={User?.addressLine1}
                    className={"input-primary"}
                    placeholder={t("AddressLine 1")}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col span={24}>
                <Form.Item label="AddressLine 2" name="addressLine2">
                  <Input
                    value={User?.addressLine2}
                    className={"input-primary"}
                    placeholder={t("AddressLine 2")}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Form.Item label="City" name="city">
                  <Input
                    value={User?.city}
                    className={"input-primary"}
                    placeholder={t("City")}
                  />
                </Form.Item>
               
              </Col>

              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Form.Item label="State" name="state">
                  <Select value={User?.state} placeholder="State">
                    <Select.Option value={""}> Please Select</Select.Option>
                    {usStates &&
                      usStates.map((state) => {
                        return (
                          <Select.Option value={state.abbr}>
                            {state.name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                  {/* <Input className={"input-primary"} placeholder={t("State")} /> */}
                </Form.Item>
              </Col>
            </Row>

            {updateBilling ? (
              <>
                <Row gutter={8}>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  
                    
                    <div><span style={{color:"red"}}>* </span> Expiry Date</div>
                    <div>
                    <DatePicker
                      className="date-picker-item"
                        //defaultValue={moment(User?.subscriptionExperationDate, 'MM/YYYY')}
                        picker="month"
                        format={"MM/YYYY"}
                        placeholder={"MM/YYYY"}
                        onChange={onDatePicker}
                        disabledDate={(current) => {
                          let customDate = moment().format("MM/DD/YYYY");
                          return current && current < moment(customDate, "MM/DD/YYYY");
                        }} 
                      />
                    </div>
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                      label="Credit Card Number"
                      name="creditCardNumber"
                      rules={[
                        {
                          required: true,
                          message: t("Card Number is Required!"),
                        },
                        ({}) => ({
                          validator(_, value) {
                            var visaPattern = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
                            var mastPattern = /^(?:5[1-5][0-9]{14})$/;
                            var amexPattern = /^(?:3[47][0-9]{13})$/;
                            var discPattern =
                              /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;

                            var isVisa = visaPattern.test(value) === true;
                            var isMast = mastPattern.test(value) === true;
                            var isAmex = amexPattern.test(value) === true;
                            var isDisc = discPattern.test(value) === true;

                            if (!value || isVisa) {
                              setCardType("Visa");
                            } else if (!value || isMast) {
                              setCardType("Mastercard");
                            } else if (!value || isAmex) {
                              setCardType("American Express");
                            } else if (!value || isDisc) {
                              setCardType("Discover Card");
                            }

                            if (
                              !value ||
                              isVisa ||
                              isMast ||
                              isAmex ||
                              isDisc
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                t("Must be a valid credit card Number!")
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Input
                        value={User?.creditCardNumber}
                        className={"input-primary"}
                        placeholder={t("**** **** **** ****")}
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                      name="subscriptionType"
                      label="Subscription Type"
                    >
                      {/* <Select
                        placeholder="Please Select Subscription Type"
                        // onChange={}
                        allowClear
                      >
                        <Select.Option value="2">Yearly</Select.Option>
                        <Select.Option value="1">Monthly</Select.Option>
                        <Select.Option value="0">Do not renew</Select.Option>
                      </Select> */}
                      <Radio.Group
                        value={User?.subscriptionType}
                        className="radios-vertical"
                        // onChange={(event) => {
                        //   setSubscriptionType(event.target.value);
                        // }}
                      >
                        <Radio value={2}>
                          $64.95 per year ( Best save over 20% )
                        </Radio>
                        <Radio value={1}>$6.99 per month</Radio>
                        <Radio value={0}>Do not Renew</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row gutter={8}>
                  <Col span={24}>
                    <Form.Item label="Dealer Account" name="dealerAccount">
                      <Switch
                        defaultChecked={
                          User && User.dealerAccount ? true : false
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}

            <Row gutter={8}>
              <Col span={24}>
                <Button
                  className="btn-primary"
                  type="link"
                  htmlType="submit"
                  loading={loading}
                >
                  {updateBilling
                    ? t("Update Billing Information")
                    : t("Update Profile")}
                </Button>
                &nbsp;
                <Button
                  className="btn-primary"
                  type="link"
                  htmlType="button"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  {t("Cancel")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default EditProfileForm;
