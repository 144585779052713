import React, { FC, useEffect, useContext } from "react";

import { useHistory } from "react-router-dom";

import { Row, Col, Card } from "antd";

import { AppContext } from "../../context";

import LoginForm from "../../forms/LoginForm";
// import { t } from "../../utils/helpers";
import image from "../../assets/image/logo.png";
interface LoginProps {}

const Login: FC<LoginProps> = (props: LoginProps) => {
  // const {} = props;

  const history = useHistory();

  const { authenticatedUser } = useContext(AppContext);

  useEffect(() => {
    if (authenticatedUser) {
      history.push("/catalogs");
    }
  }, [authenticatedUser]);

  return (
    <Row className="loginForm" gutter={8} justify="center" align="middle">
      <Col className="columns" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
        <Card className="login-card">
          <Row gutter={8}>
            <Col span={24} className="text-center">
              <div className="logo">
                <img src={image} alt={"logo"} />
              </div>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24} className="text-center">
              <LoginForm />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default Login;
