import React, { FC, useState } from "react";

import { Form, Input, Button, message } from "antd";

import { t } from "../utils/helpers";
import { resetPassword } from "../api/auth";
import { useHistory, useLocation } from "react-router";

interface ResetPasswordFormProps { }

const ResetPasswordForm: FC<ResetPasswordFormProps> = (
    props: ResetPasswordFormProps
) => {
    // const {} = props;

    const history = useHistory();

    const [sent, setSent] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    let query = new URLSearchParams(useLocation().search);
    // console.log('query ', query.get("resetpasswordkey"));
    const onFinish = async (values: any) => {
        const { password, confirmPassword } = values;
        try {
            setLoading(true);
            if (password != confirmPassword) {
                message.warning("Password and Confirm Password do not Match! Please Check.");
                setTimeout(() => {
                    setLoading(false);
                }, 1500);
            } else {
                const forgotPassReq = await resetPassword(query.get("id"), query.get("resetpasswordkey"), password);                
                if (forgotPassReq.status && forgotPassReq.status === 200) {
                    message.success("Password Reset!");
                    history.push("/login");
                } else {
                    message.error("Could not complete your request!");
                }
            }
            setSent(false);
            // setLoading(false);
        } catch (err) {
            message.error("Could not complete your request!,Please try back later.");

            setLoading(false);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <Form
            name="reset-password-form"
            initialValues={{}}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
            <div className="forgot-password-form-fields-wrapper">
                {!sent ? (
                    <>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: t("Password is required!"),
                                },
                            ]}
                        >
                            <Input.Password className={"input-primary"} placeholder="Password" />
                        </Form.Item>

                        <Form.Item
                            name="confirmPassword"
                            rules={[
                                {
                                    required: true,
                                    message: t("Confirm Password is required!"),
                                },
                            ]}
                        >
                            <Input.Password className={"input-primary"} placeholder="Confirm Password" />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                className="btn-primary"
                                type="primary"
                                htmlType="submit"
                                block
                                loading={loading}
                            >
                                Reset Password
                            </Button>
                        </Form.Item>
                    </>
                ) : (
                    null
                )}
            </div>
        </Form>
    );
};

export default ResetPasswordForm;
