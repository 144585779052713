import React, { FC, useContext, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Radio,
  Checkbox,
  Modal,
  Select,
  DatePicker,
  message,
} from "antd";

import { AppContext } from "../context";
import { t } from "../utils/helpers";

import { register, login, updateMemberAccount } from "../api/auth";

import User from "../models/User";

import usStates from "../assets/json/us.states.json";
import TermsContent from "../screens/TermsAndConditions/Partials/termsContent";
import moment from "moment";
interface RegisterFormProps {}
interface StepOneProps {
  form: any;
  currentStep: number;
  setSubscriptionType: any;
}
interface StepTwoProps {
  form: any;
  currentStep: number;
  setCardType: any;
}

const StepOneFields = (props: StepOneProps) => {
  const { currentStep, setSubscriptionType } = props;
  return (
    <div className={currentStep === 1 ? "step-visible" : "step-hidden"}>
      <Row className={"register-step-one text-left"} gutter={8}>
        <Col span={24}>
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                name="firstName"
                rules={[
                  { required: true, message: t("First name is required!") },
                  { min: 3, message: t("First name must contain min 3 characters!") },
                  { max: 20, message: t("First name must not contain more than 20 characters!") },
                ]}
              >
                <Input
                  className={"input-primary"}
                  placeholder={t("First Name")}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                name="lastName"
                rules={[
                  { required: true, message: t("Last name is required!") },
                  { min: 3, message: t("Last name must contain min 3 characters!") },
                  { max: 20, message: t("Last name must not contain more than 20 characters!") },
                ]}
              >
                <Input
                  className={"input-primary"}
                  placeholder={t("Last Name")}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: t("Email is required!"),
                  },
                  {
                    type: "email",
                    message: t("Must be a valid email address!"),
                  },
                ]}
              >
                <Input className={"input-primary"} placeholder={t("Email")} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: t("Password is Required!") },
                  () => ({
                    validator(_, value) {
                      var passwordPattern =
                        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

                      var isValid = passwordPattern.test(value) === true;

                      if (!value || isValid) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          t(
                            "Password must be min 8 characters, include special character, uppercase, lowercase characters and numbers!"
                          )
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  onChange={(event) => {}}
                  className={"input-primary"}
                  placeholder={t("Password")}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                name="confirm"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: t("Please confirm your password!"),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          t("The two passwords that you entered do not match!")
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  className={"input-primary"}
                  placeholder={t("Confirm password")}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                name="dealerAccount"
                rules={[
                  {
                    required: true,
                    message: t("Please Select your Account Type"),
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={true}>Dealer Account</Radio>
                  <Radio value={false}>Personal Account</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                className="h-auto"
                label="Choose Subscription Plan"
                name="subscriptionType"
                rules={[
                  {
                    required: true,
                    message: t("You must choose a subscription plan!"),
                  },
                ]}
              >
                <Radio.Group
                  className="radios-vertical"
                  onChange={(event) => {
                    setSubscriptionType(event.target.value);
                  }}
                >
                  <Radio value={2}>
                    $64.95 per year ( Best save over 20% )
                  </Radio>
                  <Radio value={1}>$6.99 per month</Radio>
                  <Radio value={0}>Try It - 10-Day FREE Trial </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

const StepTwoFields = (props: StepTwoProps) => {
  const { currentStep, setCardType } = props;
  return (
    <div className={currentStep === 2 ? "step-visible" : "step-hidden"}>
      <Row className={"register-step-two text-left"} gutter={8}>
        <Col span={24}>
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                name="nameOnCard"
                rules={[
                  { required: true, message: t("Name is required!") },
                  { min: 3, message: t("Name of card must contain min 3 characters!") },
                  { max: 20, message: t("Name of card must not contain more than 20 characters!") },
              ]}
              >
                <Input
                  className={"input-primary"}
                  placeholder={t("Name on Card")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                name="address"
                rules={[
                  { required: true, message: t("Address is required!") },
                  { min: 3, message: t("Address must contain min 3 characters!") },
              ]}
              >
                <Input className={"input-primary"} placeholder={t("Address")} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item name="address2">
                <Input
                  className={"input-primary"}
                  placeholder={t("Address 2")}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={24}>
              <Form.Item name="city">
                <Input className={"input-primary"} placeholder={t("City")} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={8}>
              <Form.Item
                name="state"
                rules={[
                  {
                    required: true,
                    message: t("State is Required!"),
                  },
                ]}
              >
                <Select placeholder="State">
                  <Select.Option value={""}> Please Select</Select.Option>
                  {usStates &&
                    usStates.map((state) => {
                      return (
                        <Select.Option value={state.abbr}>
                          {state.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name="zipCode"
                rules={[
                  {
                    required: true,
                    message: t("Zip Code is Required!"),
                  },
                  {
                    min: 3,
                    message: t("Invalid Zip Code!"),
                  },
                  {
                    max: 7,
                    message: t("Invalid Zip Code!"),
                  },
                  {
                    pattern: /^([-]?[1-9][0-9]*|0)$/,
                    message: t("Only Number Acceptable!"),
                  },
                ]}
              >
                <Input
                  className={"input-primary"}
                  placeholder={t("Zip Code")}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                name="cardNumber"
                rules={[
                  {
                    required: true,
                    message: t("Card Number is Required!"),
                  },
                  ({}) => ({
                    validator(_, value) {
                      var visaPattern = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
                      var mastPattern = /^(?:5[1-5][0-9]{14})$/;
                      var amexPattern = /^(?:3[47][0-9]{13})$/;
                      var discPattern = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;

                      var isVisa = visaPattern.test(value) === true;
                      var isMast = mastPattern.test(value) === true;
                      var isAmex = amexPattern.test(value) === true;
                      var isDisc = discPattern.test(value) === true;

                      if (!value || isVisa) {
                        setCardType("Visa");
                      } else if (!value || isMast) {
                        setCardType("Mastercard");
                      } else if (!value || isAmex) {
                        setCardType("American Express");
                      } else if (!value || isDisc) {
                        setCardType("Discover Card");
                      }

                      if (!value || isVisa || isMast || isAmex || isDisc) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(t("Must be a valid credit card Number!"))
                      );
                    },
                  }),
                ]}
              >
                <Input
                  className={"input-primary"}
                  placeholder={t("Card Number")}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                name="expiration"
                rules={[
                  { required: true, message: t("Expiration is Required!") },
                ]}
              >
                <DatePicker
                  picker="month"
                  format={"MM/YYYY"}
                  placeholder={"mm/yyyy"}
                  disabledDate={(current) => {
                    let customDate = moment().format("MM/DD/YYYY");
                    return current && current < moment(customDate, "MM/DD/YYYY");
                  }} 
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="cvc"
                rules={[
                  { required: true, message: t("CVC is Required!") },
                  {
                    min: 3,
                    message: t("Invalid CVC!"),
                  },
                  {
                    max: 5,
                    message: t("Invalid CVC!"),
                  },
                  {
                    pattern: /^([-]?[1-9][0-9]*|0)$/,
                    message: t("Only Number Acceptable!"),
                  },
              ]}
              >
                <Input className={"input-primary"} placeholder={t("CVC")} />
              </Form.Item>
            </Col>
          </Row>

          {/* <Row gutter={8}>
            <Col span={24} className={"text-left"}>
              <Form.Item
                name="termsAndConditions"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(new Error("Should accept agreement")),
                  },
                ]}
              >
                <Checkbox>Terms &amp; Conditions</Checkbox>
              </Form.Item>
            </Col>
          </Row> */}
        </Col>
      </Row>
    </div>
  );
};

const RegisterForm: FC<RegisterFormProps> = (props: RegisterFormProps) => {
  // const {} = props;
  const today = moment();
  const { setAuthenticatedUser, authToken, setAuthToken } =
    useContext(AppContext);

  const [subscriptionType, setSubscriptionType] = useState<number>(1);

  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(1);

  const [cardType, setCardType] = useState<string>("");

  const [stepOneValues, setStepOneValues] = useState<any>({});
  const [formValues, setFormValues] = useState<any>({});
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const handleOk = () => {
    setIsModalVisible(false);
    setTermsAccepted(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setTermsAccepted(false);
    setTimeout(() => {
      setIsModalVisible(true);
    }, 2000);
  };
  useEffect(() => {
    if (termsAccepted) {
      onFinish(formValues);
    }
  }, [formValues, termsAccepted]);
  const onFinish = async (values: any) => {
    // console.log("values ", cardType);
    const abortConst = new AbortController();
    setFormValues(values);
    if (termsAccepted) {
      const {
        firstName,
        lastName,
        email,
        password,
        dealerAccount,
        city,
        state,
        zipCode,
        subscriptionType,
        cardNumber,
        expiration,
        cvc,
      } = values;
      try {
        setLoading(true);
        const userData = {
          username: email,
          firstName: firstName,
          lastName: lastName,
          email: email,
          password: password,
        } as User;
        const registerReq = await register(userData);
        const { data } = registerReq;

        if (data && data.status.toLowerCase() === "success") {
          // console.log("subscriptionType ", subscriptionType);
          message.success(data.message);
          const postMemberData = {
            username: email,
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password,
            city: city,
            state: state,
            // cardType: cardType,
            zipCode: zipCode,
            dealerAccount: dealerAccount,
            subscriptionExpirationDate: expiration,
            subscriptionType: subscriptionType,
            CreditCardNumber: cardNumber,
            creditCardExpirationDate: expiration,
            profileImage: "",
          };
          //attempt login
          const loginReq = await login(email, password);
          const tokenInfo = loginReq.data;
          setAuthToken(tokenInfo.token);

          const updateMemberAccountResult = await updateMemberAccount(
            authToken,
            postMemberData.username,
            postMemberData
          );
          setAuthenticatedUser(tokenInfo.memberAccount);
          // history.push("/catalogs");
        } else {
          message.error(data.message);
        }
        setLoading(false);
      } catch (err:any) {
        setTermsAccepted(false);
        setIsModalVisible(false);
        const msg = err.response.data.message ?? "Something went wrong while registration!"
        message.error(msg);
        setLoading(false);
      }
    } else {
      setIsModalVisible(true);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Form
        form={form}
        name="register-form"
        initialValues={{}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="register-form-fields-wrapper">
          {currentStep === 1 ? (
            <>
              <StepOneFields
                form={form}
                currentStep={currentStep}
                setSubscriptionType={setSubscriptionType}
              />
            </>
          ) : (
            <>
              <StepOneFields
                form={form}
                currentStep={currentStep}
                setSubscriptionType={setSubscriptionType}
              />
              <StepTwoFields
                form={form}
                setCardType={setCardType}
                currentStep={currentStep}
              />
            </>
          )}
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item>
                <Button
                  className="btn-primary"
                  type="link"
                  block
                  disabled={loading}
                  onClick={() => {
                    history.push("/login");
                  }}
                >
                  {t("Back")}
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item className="h-auto">
                {currentStep === 1 ? (
                  <>
                    {subscriptionType === 0 ? (
                      <Button
                        className="btn-primary"
                        type="link"
                        block
                        htmlType="submit"
                        loading={loading}
                      >
                        {t("Register")}
                      </Button>
                    ) : (
                      <Button
                        className="btn-primary"
                        type="primary"
                        block
                        loading={loading}
                        onClick={() => {
                          form
                            .validateFields()
                            .then((values) => {
                              setStepOneValues(values);
                              setCurrentStep(2);
                            })
                            .catch((err) => {});
                        }}
                      >
                        {t("Next")}
                      </Button>
                    )}
                  </>
                ) : (
                  <Button
                    className="btn-primary"
                    type="link"
                    block
                    htmlType="submit"
                    loading={loading}
                  >
                    {t("Confirm and Pay")}
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
          {/*  
        <Row gutter={8}>
          <Col
            span={12}
            className={currentStep === 1 ? "text-right" : "text-center"}
          >
            <Form.Item className="h-auto">
              {currentStep === 1 ? (
                <>
                  {subscriptionType === 0 ? (
                    <Button
                      className="btn-primary"
                      type="link"
                      htmlType="submit"
                      loading={loading}
                    >
                      {t("Register")}
                    </Button>
                  ) : (
                    <Button
                      className="btn-primary"
                      type="primary"
                      loading={loading}
                      onClick={() => {
                        form
                          .validateFields()
                          .then((values) => {
                            setStepOneValues(values);
                            setCurrentStep(2);
                          })
                          .catch((err) => {});
                      }}
                    >
                      {t("Next")}
                    </Button>
                  )}
                </>
              ) : (
                <Button
                  className="btn-primary"
                  type="link"
                  htmlType="submit"
                  loading={loading}
                >
                  {t("Confirm and Pay")}
                </Button>
              )}
            </Form.Item>
          </Col>
        </Row>
        */}
        </div>
      </Form>
      <Modal
        className="maxHeightt"
        title="Terms and Conditions"
        visible={isModalVisible}
      >
        <div className="maxHeight">
          <TermsContent />
          <Row gutter={8} justify="center">
            <Col span={10} className="center">
              <Form.Item>
                <Button
                  className="btn-primary"
                  onClick={() => {
                    handleCancel();
                  }}
                >
                  {t("Cancel")}
                </Button>
              </Form.Item>
            </Col>
            <Col span={10} className="center">
              <Form.Item>
                <Button
                  className="btn-primary"
                  onClick={() => {
                    handleOk();
                  }}
                >
                  {t("Accept")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default RegisterForm;
