import React, { FC } from "react";
import { Layout } from "antd";
import moment from "moment";
const { Footer } = Layout;

interface AppFooterProps {}

const today = moment();

const AppFooter: FC = (props: AppFooterProps) => {
  return (
    <Footer className={"main-footer"}>
      <p className="text-right">Collector's Catalogs Version 1.0.0 &copy; {today.format('Y')}</p>
    </Footer>
  );
};

export default AppFooter;
