import React, { FC, useContext, useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  Spin,
  Divider,
  Button,
  Popconfirm,
  Modal,
  Alert,
} from "antd";

import { useParams, useHistory } from "react-router-dom";
import { getUserProfileById, cancelSubscription } from "../../api/catalog";
import { AppContext } from "../../context";
import User from "../../models/User";
import moment from "moment";

interface SubscriptionProps {}

const Subscription: FC = (props: SubscriptionProps) => {
  //@ts-ignoreF
  const { authToken, authenticatedUser } = useContext(AppContext);
  const [userData, setUserData] = useState<User | null>(null);
  const [loading, setLoading] = useState(false);
  const [cancleSubscription, setCancleSubscription] = useState(false);
  const history = useHistory();
  const [cancleSubscriptionVisible, setCancleSubscriptionVisible] =
    useState<boolean>(false);
  const [doNotReviewSubscriptionVisible, setDoNotReviewSubscriptionVisible] =
    useState<boolean>(false);
  const [doNotReviewSubscription, setDoNotReviewSubscription] =
    useState<boolean>(false);
  useEffect(() => {
    (async () => {
      setLoading(true);
      const { data } = await getUserProfileById(
        authToken,
        authenticatedUser.id
      );
      // console.log(data);
      setUserData(data);
      setLoading(false);
    })();
  }, []);

  return loading ? (
    <Row gutter={8} justify="center">
      <Col>
        <Spin />
      </Col>
    </Row>
  ) : (
    <Row gutter={20} justify="center" style={{ margin: "20px" }}>
      <Col xs={24} sm={24} md={24} lg={12} xl={10}>
        <Card
          title="Manage Subscription"
          hoverable
          className={"card-mode subscription-card"}
        >
          <p>
            You have been a member since
            <b> {moment(userData?.subscriptionStartDate).format("MM/DD/YYYY")}</b>
          </p>
          <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
          <p>
            Your next payment date is
            <b>
              {" "}
              {moment(userData?.subscriptionExpirationDate).format("MM/DD/YYYY")}
            </b>
          </p>
          {userData?.lastPaymentAmount && userData?.lastPaymentDate ? (
            <p>
              Last Payment of{" "}
              <b>
                $ {userData?.lastPaymentAmount} was successful on{" "}
                {moment(userData?.lastPaymentDate).format("MM/DD/YYYY")}
              </b>
            </p>
          ) : (
            <p>
              <b>No Previous Payments</b>
            </p>
          )}
          <Divider
            style={{
              borderColor: "transparent",
              marginTop: "0",
              marginBottom: "15px",
            }}
          />
          <Row gutter={20} justify="space-between">
            <Col xl={10} lg={10} md={12} sm={12} xs={24}>
              <Popconfirm
                title={"Are you sure you want to Cancle Subscription?"}
                onConfirm={async () => {
                  setCancleSubscription(true);
                  const updateReq = await cancelSubscription(
                    authToken,
                    authenticatedUser?.id,
                    {}
                  );
                  if (updateReq) {
                    setCancleSubscriptionVisible(false);
                    setCancleSubscription(false);
                  }
                }}
                okButtonProps={{ loading: loading }}
                okText={"Ok!"}
                onCancel={() => {
                  setCancleSubscriptionVisible(false);
                }}
                visible={cancleSubscriptionVisible}
                className={"popconfFirm-primary"}
              >
                <Button
                  type="primary"
                  loading={cancleSubscription}
                  onClick={() => {
                    setCancleSubscriptionVisible(true);
                  }}
                >
                  Cancel Subscription
                </Button>
              </Popconfirm>
            </Col>
            <Col xl={14} lg={14} md={12} sm={12} xs={24}>
              <Popconfirm
                title={"Are you sure you don not want to Review Subscription"}
                onConfirm={async () => {
                  setDoNotReviewSubscription(true);
                  const updateReq = await cancelSubscription(
                    authToken,
                    authenticatedUser?.id,
                    {}
                  );
                  if (updateReq) {
                    setDoNotReviewSubscriptionVisible(false);
                    setDoNotReviewSubscription(false);
                  }
                }}
                okButtonProps={{ loading: loading }}
                okText={"Ok!"}
                onCancel={() => {
                  setDoNotReviewSubscriptionVisible(false);
                }}
                visible={doNotReviewSubscriptionVisible}
                className={"popconfFirm-primary"}
              >
                <Button
                  type="primary"
                  loading={doNotReviewSubscription}
                  onClick={() => {
                    setDoNotReviewSubscriptionVisible(true);
                  }}
                >
                  Do Not Review Subscription
                </Button>
              </Popconfirm>
            </Col>
          </Row>
          <Divider style={{ borderColor: "transparent", marginBottom: "0" }} />
        </Card>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={10}>
        <Card
          title="Billing Information"
          hoverable
          className={"card-mode billing-card"}
        >
          {userData?.creditCardNumber ? (
            <p>
              Current Credit Card:
              <br />
              <b>
                {userData?.cardType ? userData?.cardType : "American Express"}
              </b>
              <br />
              <b>
                {userData?.creditCardNumber
                  ? userData?.creditCardNumber
                  : "**** **** **** ****"}
              </b>
              <br />
              Expires :
              <b>
                {userData?.creditCardExpirationDate
                  ? moment(userData?.creditCardExpirationDate).format(
                      "MM/DD/YYYY"
                    )
                  : ""}
              </b>
            </p>
          ) : (
            <p>
              <b>No Credit Card on File</b>
            </p>
          )}

          <Divider style={{ borderColor: "transparent" }} />
          <Row gutter={20} justify="space-between">
            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
              <Button
                type="primary"
                onClick={() => {
                  history.push("/update-billing-information");
                }}
              >
                Update Billing Information
              </Button>
            </Col>
            
          </Row>
          <Divider style={{ borderColor: "transparent", marginBottom: "0" }} />
        </Card>
      </Col>
    </Row>
  );
};

export default Subscription;
