import React, { FC } from "react";
import { Switch, Route } from "react-router-dom";

import { t } from "../utils/helpers";

import NotFound from "../screens/NotFound";
import Login from "../screens/Login";
import Register from "../screens/Register";
import ForgotPassword from "../screens/ForgotPassword";
import ResetPassword from "../screens/ResetPassword";
import Catalogs from "../screens/Catalogs";
import UserProfile from "../screens/UserProfile";
import EditProfile from "../screens/EditProfile";
import BillingInformation from "../screens/BillingInformation";
import Catalog from "../screens/Catalog";

import AppProtectedRoute from "./AppProtectedRoute";
import CreateCatalog from "../screens/CreateCatalog";
import CreateArtifact from "../screens/CreateArtifact";
import EditCatalog from "../screens/EditCatalog";
import Subscription from "../screens/Subscription";
import TransferOwnerShip from "../screens/TransferOwnerShip";
import ReceiveArtifact from "../screens/ReceiveArtifact";
import EditArtifact from "../screens/EditArtifact";
import ViewCollection from "../screens/ViewCollection";
import ArtifactCollection from "../screens/ArtifactCollection";
import TermsAndConditions from "../screens/TermsAndConditions";
import ContactUs from "../screens/ContactUs";
import About from "../screens/About";
import Faqs from "../screens/Faqs";
import MemberDirectoty from "../screens/MembersDirectory";
import CatalogList from "../screens/MembersDirectory/CatalogList";

interface AppRoutesProps { }

const AppRoutes: FC = (props: AppRoutesProps) => {
  return (
    <Switch>
      <Route exact path={t("/about")} component={About} />
      <Route exact path={t("/login")} component={Login} />
      <Route exact path={t("/register")} component={Register} />
      <Route
        exact
        path={t("/view/collection/:collectionId")}
        component={ViewCollection}
      />

      <Route
        exact
        path={t("/view/artifact/:artifactId")}
        component={ArtifactCollection}
      />

      <Route exact path={t("/Reset/GetMemberAccount")} component={ResetPassword} />

      {/* <Route exact path={t("/Reset/?sk=stringOfKey&memberAccountId=memberAccountId")} component={ResetPassword} /> */}
      <Route exact path={t("/forgot-password")} component={ForgotPassword} />
      <Route
        exact
        path={t("/terms-and-conditions")}
        component={TermsAndConditions}
      />
      <Route exact path={t("/contact-us")} component={ContactUs} />
      <Route exact path={t("/faqs")} component={Faqs} />
      <Route exact path={t("/members-directory")} component={MemberDirectoty} />

      <Route exact path={"/"} component={Login} />
      <AppProtectedRoute
        exact
        path={t("/catalogs/edit/:catalogId")}
        component={EditCatalog}
      />

      <AppProtectedRoute
        exact
        path={t("/listmembercatalogs/:memberId")}
        component={CatalogList}
      />

      <AppProtectedRoute
        exact
        path={t("/transfer/:transferType/:id")}
        component={TransferOwnerShip}
      />

      <AppProtectedRoute
        exact
        path={t("/catalogs/artifacts/receive/:transferedArtifactId")}
        component={ReceiveArtifact}
      />

      <AppProtectedRoute
        exact
        path={t("/artifact/edit/:catalogId/:artifactId")}
        component={EditArtifact}
      />

      <AppProtectedRoute
        exact
        path={t("/artifact/view/:artifactId")}
        component={EditArtifact}
      />

      <AppProtectedRoute
        exact
        path={t("/catalogs/view/:catalogId")}
        component={Catalog}
      />
      <AppProtectedRoute
        exact
        path={t("/catalogs/new")}
        component={CreateCatalog}
      />
      <AppProtectedRoute exact path={t("/catalogs")} component={Catalogs} />
      <AppProtectedRoute
        exact
        path={t("/my-profile")}
        component={UserProfile}
      />

      <AppProtectedRoute
        exact
        path={t("/my-profile/edit")}
        component={EditProfile}
      />

      <AppProtectedRoute
        exact
        path={t("/update-billing-information")}
        component={BillingInformation}
      />

      <AppProtectedRoute
        exact
        path={t("/subscription")}
        component={Subscription}
      />

      <AppProtectedRoute
        exact
        path={t("/catalogs/:catalogId/add-artifact")}
        component={CreateArtifact}
      />
      <Route exact component={NotFound} />
    </Switch>
  );
};

export default AppRoutes;
