import axios from "axios";

const {
  REACT_APP_ENV,
  REACT_APP_DEV_API_BASE,
  REACT_APP_PROD_API_BASE,
} = process.env;

export const makeApiRequest = async (
  authToken: string | null,
  url: string,
  data: any,
  isJson: boolean = true,
  method: string = "post"
) => {
  const api = axios.create({
    baseURL:
      REACT_APP_ENV === "development"
        ? REACT_APP_DEV_API_BASE
        : REACT_APP_PROD_API_BASE,
  });

  if (authToken && authToken !== "") {
    api.defaults.headers.common["authorization"] = "Bearer " + authToken;
  }

  if (isJson === true) {
    api.defaults.headers.common["Content-Type"] =
      "application/json; charset=UTF-8";
  } else {
    api.defaults.headers.common["Content-Type"] = "multipart/form-data;";
  }

  switch (method.toLowerCase()) {
    case "post":
      return api.post(url, data);
      break;
    case "get":
      return api.get(url);
      break;
    case "put":
      return api.put(url, data);
      break;
    case "delete":
      return api.delete(url);
      break;
    default:
      return api.get(url, data);
  }
};
