import React, { FC, useState, useContext } from "react";
import { Layout, Row, Popconfirm, Drawer, Col, Button, Menu } from "antd";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { t } from "../utils/helpers";
import {
  CaretLeftOutlined,
  MenuOutlined,
  CodepenOutlined,
  CloseOutlined,
  DashboardOutlined,
  LogoutOutlined,
  UserOutlined,
  DollarOutlined,
  UserSwitchOutlined,
  QuestionOutlined,
  MailOutlined,
  InfoOutlined,
  BankOutlined,
} from "@ant-design/icons";
import image from "../assets/image/logo.png";
import { AppContext } from "../context";
import AppUserMenu from "./AppUserMenu";
import AppLeftNavigation from "./AppLeftNavigation";
import CatalogSearchForm from "../forms/CatalogSearchForm";
import ArtifactSearchForm from "../forms/ArtifactSearchForm";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
const { Header } = Layout;

interface AppHeaderProps {}

const AppHeader: FC<AppHeaderProps> = (props: AppHeaderProps) => {
  const {
    setAuthenticatedUser,
    setIsAuthenticated,
    setAuthToken,
    isAuthenticated,
    collapsed,
    setCollapsed,
    currentPageTitle,
  } = useContext(AppContext);
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const DrawerMenu = () => {
    return (
      <Menu
        className="drawer-menu"
        defaultSelectedKeys={[currentPageTitle.toLowerCase()]}
        mode={"vertical"}
        theme={"light"}
      >
        <Menu.Item onClick={onClose} key="catalog" icon={<DashboardOutlined />}>
          <Link to={t("/catalogs")}>{t("My Collections")}</Link>
        </Menu.Item>
        <Menu.Item onClick={onClose} key="Profile" icon={<UserOutlined />}>
          <Link to={t("/my-profile")}>{t("Profile")}</Link>
        </Menu.Item>
        <Menu.Item onClick={onClose} key="Subscription" icon={<DollarOutlined />}>
          <Link to={t("#")}>{t("Subscription")}</Link>
        </Menu.Item>
        <Menu.Item onClick={onClose} key="MemberDirectory" icon={<UserSwitchOutlined />}>
          <Link to={t("/members-directory")}>{t("Member Directory")}</Link>
        </Menu.Item>
        <Menu.Item onClick={onClose} key="About" icon={<InfoOutlined />}>
          <Link to={t("/about")}>{t("About Collector's Catalogs")}</Link>
        </Menu.Item>
        <Menu.Item onClick={onClose} key="Faq" icon={<QuestionOutlined />}>
          <Link to={t("/faqs")}>{t("FAQ")}</Link>
        </Menu.Item>
        <Menu.Item key="Contact" onClick={onClose} icon={<MailOutlined />}>
          <Link to={t("/contact-us")}>{t("Contact Us")}</Link>
        </Menu.Item>
        <Menu.Item key="terms" onClick={onClose} icon={<BankOutlined />}>
          <Link to={t("/terms-and-conditions")}>
            {t("Terms and Condition")}
          </Link>
        </Menu.Item>
      </Menu>
    );
  };
  const pathname = location.pathname.toLowerCase();
  const [logoutConfirmVisible, setLogoutConfirmVisible] =
    useState<boolean>(false);  
  return (
    <>
      <Header
        className={
          isAuthenticated ? "main-header authenticated-header" : "main-header"
        }
      >
        {isAuthenticated ? (
          <>
            <Row className="mobile-grid-row" gutter={8} align="middle">
              <Col xl={2} lg={2} md={3} sm={6} xs={6}>
                <span
                  onClick={() => {
                    setCollapsed(!collapsed);
                  }}
                >
                  {<MenuOutlined className="mobile-menu-trigger" />}
                  <MenuOutlined
                    onClick={() => setVisible(true)}
                    className="mobile-menu-trigger drawer-trigger"
                  />
                </span>
              </Col>
              <Col xl={14} lg={14} md={13} sm={18} xs={18}>
                <div className={"flex-items-center"}>
                  <h1 className=" mobile-padding-left current-page-title">
                    <span>
                      {currentPageTitle
                        ? currentPageTitle
                        : "Collectible Artifacts"}
                    </span>
                  </h1>
                </div>
              </Col>
              <Col xl={8} lg={8} md={8} sm={0} xs={0}>
                <div className="flex-items-center hide-on-mobile user-menu-wrapper">
                  <AppUserMenu />
                </div>
                <p className="text-right show-on-mobile menu-icon-toggler">
                  <MenuOutlined onClick={showDrawer} />
                  <Drawer
                    placement="left"
                    closable={true}
                    push={true}
                    className="menu-drawer-mobile"
                    closeIcon={
                      <CloseOutlined style={{ color: "#fff !important" }} />
                    }
                    onClose={onClose}
                    visible={visible}
                  >
                    {/* <MenuOutlined /> */}
                    <Row>
                      <Col span={24}>
                        <div className="brand-name">
                          <img
                            onClick={() => {
                              history.push(`/catalogs`);
                            }}
                            src={image}
                            alt={"logo"}
                          />
                        </div>
                      </Col>
                    </Row>
                    {/* <AppUserMenu /> */}
                    <DrawerMenu />
                    <Popconfirm
                      title={"Are you sure you want to logout?"}
                      onConfirm={() => {
                        setLoading(true);
                        setTimeout(function () {
                          setAuthenticatedUser("");
                          setIsAuthenticated("");
                          setAuthToken("");
                          history.push(`/`);
                          setLoading(false);
                        }, 2000);
                      }}
                      okButtonProps={{ loading: loading }}
                      okText={"Yes log me out!"}
                      onCancel={() => {
                        setLogoutConfirmVisible(false);
                      }}
                      visible={logoutConfirmVisible}
                      className={"popconfirm-primary"}
                    >
                      <Button
                        onClick={() => {
                          setLogoutConfirmVisible(true);
                        }}
                        type="link"
                        className="logout-btn"
                      >
                        <LogoutOutlined />
                        <span>{"Logout"}</span>
                      </Button>
                    </Popconfirm>
                  </Drawer>
                </p>
              </Col>
            </Row>

            {pathname == "/catalogs" ? <CatalogSearchForm /> : null}
            {/* {pathname == "/catalogs/view/" ? <ArtifactSearchForm /> : null} */}
            {pathname.match(t("/catalogs/view")) ? (
              <ArtifactSearchForm />
            ) : null}
          </>
        ) : (
          <Row>
            <Col xs={20} sm={20} md={20} lg={18} xl={18} xxl={18}>
              <div className="brand-name">
                <CodepenOutlined /> Collectible Artifacts
              </div>
            </Col>
            <Col xs={4} sm={4} md={4} lg={6} xl={6} xxl={6}>
              {/* <div className="menu-togller">
              <MenuIcon />
            </div> */}
            </Col>
          </Row>
        )}
      </Header>
    </>
  );
};

export default AppHeader;
