import React, { createContext, FC, useState, useEffect } from "react";
import User from "../models/User";
export const AppContext = createContext<any>({});

interface ContextProviderProps {
  children: any;
}

export const ContextProvider: FC<ContextProviderProps> = (
  props: ContextProviderProps
) => {
  const { children } = props;

  /** App State */
  const [appName, setAppName] = useState<string>("Collectible Artifacts");
  const [authToken, setAuthToken] = useState<string | null>(null);
  const [authenticatedUser, setAuthenticatedUser] = useState<any | null>(null);
  const [userProfile, setUserProfile] = useState<any | null>(null);

  const [isAuthenticated, setIsAuthenticated] = useState<Boolean>(false);

  const [currentPageTitle, setCurrentPageTitle] = useState<string>(
    "Collectible Artifacts"
  );

  const [pwUpdated, setPwUpdated] = useState<boolean>(false);

  const [metaTitle, setMetaTitle] = useState<string>("Collectible Artifacts");
  const [metaDescription, setMetaDescription] = useState<string>(
    "Collectible Artifacts"
  );
  const [metaKeywords, setMetaKeywords] = useState<string>(
    "collectibles, artifacts"
  );
  const [metaAuthor, setMetaAuthor] = useState<string>("Ryan");
  const [metaUrl, setMetaUrl] = useState<string>("%PUBLIC_URL%");
  const [metaImageUrl, setMetaImageUrl] = useState<string>(
    "%PUBLIC_URL%/logo192.png"
  );
  const [metaImageAltTxt, setMetaImageAltTxt] = useState<string>(
    "Collectible Artifacts"
  );

  const [metaSiteName, setMetaSiteName] = useState<string>(
    "Collectible Artifacts"
  );
  const [metaTwitterSite, setMetaTwitterSite] = useState<string>(
    "Collectible Artifacts"
  );
  const [metaFbAppId, setMetaFbAppId] = useState<string>("");

  /** Catalogs */
  const [memberCatalogs, setMemberCatalogs] = useState<any[]>([]);
  const [seachedCatalog, setSeachedCatalog] = useState<any[]>([]);

  const [artifactImages, setArtifactImages] = useState<string[]>([]);
  const [catalog, setCatalog] = useState<any[]>([]);
  const [filteredArtifact, setFilteredArtifact] = useState<any[]>([]);  
  const [userData, setUserData] = useState<User | null>(null);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [allMemberAccountList, setAllMemberAccountList] = useState<[]>();
  const setMetaTags = (
    title: string,
    description: string,
    keywords: string,
    url: string,
    imgUrl: string,
    imgAlt: string,
    author: string = "Ryan"
  ) => {
    setMetaTitle(title);
    setMetaDescription(description);
    setMetaKeywords(keywords);
    setMetaAuthor(author);
    setMetaUrl(url);
    setMetaImageUrl(imgUrl);
    setMetaImageAltTxt(imgAlt);
  };

  useEffect(() => {
    if (authenticatedUser) {
      setIsAuthenticated(true);
    }
  }, [authenticatedUser, setIsAuthenticated]);

  /** App State */

  const value: any = {
    /** vars */
    appName,
    authToken,
    authenticatedUser,
    isAuthenticated,
    currentPageTitle,
    userProfile,
    pwUpdated,
    userData,
    artifactImages,
    catalog,
    filteredArtifact,
    collapsed,
    allMemberAccountList,
    memberCatalogs,
    seachedCatalog,

    /** meta */
    metaTitle,
    metaDescription,
    metaKeywords,
    metaAuthor,
    metaUrl,
    metaImageUrl,
    metaImageAltTxt,
    metaSiteName,
    metaTwitterSite,
    metaFbAppId,

    /** funcs */
    setAppName,
    setUserData,
    setCollapsed,
    setAuthToken,
    setAuthenticatedUser,
    setIsAuthenticated,
    setCurrentPageTitle,
    setUserProfile,
    setPwUpdated,
    setMetaSiteName,
    setArtifactImages,
    setMetaTwitterSite,
    setMetaFbAppId,
    setMetaTags,
    setAllMemberAccountList,

    setMemberCatalogs,
    setSeachedCatalog,
    setCatalog,
    setFilteredArtifact,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
