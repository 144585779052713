import React, { FC, useContext, useEffect, useState } from "react";

import { Row, Col, Modal, Alert } from "antd";
import CatalogDetail from "../Catalog/_partials/CatalogDetail";
import ArtifactForm from "../../forms/ArtifactForm";
import { AppContext } from "../../context";
import { useHistory } from "react-router";

interface CreateArtifactProps { }

const CreateArtifact: FC = (props: CreateArtifactProps) => {
  const history = useHistory();
  const { authenticatedUser } = useContext(AppContext);
  const [subscriptionModal, setSubscriptionModal] = useState<boolean>(false);
  useEffect(() => {
    if (authenticatedUser.disabled && authenticatedUser.trailExpired) {
      setSubscriptionModal(true);
      setTimeout(() => {
        history.push("/subscription");
      }, 2000);
    }
  }, [])
  return (
    <Row gutter={8}>
      <Modal title="Your Account Expired" onCancel={() => setSubscriptionModal(false)} onOk={() => setSubscriptionModal(false)} visible={subscriptionModal}>
        <Alert
          message=""
          description="Please update your billing Information."
          type="warning"
          showIcon
        />
      </Modal>
      <Col span={24}>
        <CatalogDetail />
        <ArtifactForm />
      </Col>
    </Row>
  );
};

export default CreateArtifact;
