import { makeApiRequest } from "./index";

import User from "../models/User";

export const login = async (email: string, password: string) => {
  const loginReq = await makeApiRequest(null, `/api/Authenticate/login`, {
    username: email,
    password: password,
  });

  return loginReq;
};

export const register = async (user: User) => {
  const registerReq = await makeApiRequest(
    null,
    `/api/Authenticate/register`,
    user
  );

  return registerReq;
};

export const updateMemberAccount = async (
  token: string,
  userName: string,
  postMemberData: User
) => {
  const createReq = await makeApiRequest(
    token,
    `/api/MemberAccount/UpdateMemberAccount/${userName}`,
    postMemberData,
    true,
    "put"
  );

  return createReq;
};

// export const postMemberAccount = async (postMemberData: User) => {
//   const registerReq = await makeApiRequest(
//     null,
//     `/api/MemberAccount/PostMemberAccount`,
//     postMemberData
//   );

//   return registerReq;
// };
export const resetPassword = async (
  id: string | null,
  sk: string | null,
  newPassword: string
) => {
  const forgotPasswordReq = await makeApiRequest(
    null,
    `/api/MemberAccount/Reset/${id}`,
    { sk, newPassword },
    true,
    "put"
  );

  return forgotPasswordReq;
};
export const forgotPassword = async (username: string) => {
  const forgotPasswordReq = await makeApiRequest(
    null,
    `/api/Authenticate/forgotpassword`,
    { username }
  );

  return forgotPasswordReq;
};

export const uploadMemberProfileImage = async (
  token: string,
  accountId: string,
  image: string
) => {
  const uploadReq = await makeApiRequest(
    token,
    `/api/MemberAccount/PostImage`,
    {
      accountId,
      image,
    }
  );

  return uploadReq;
};
