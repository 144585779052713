import React, { FC, useContext, useEffect, useState } from "react";

import { Form, Input, Button, message, Row, Col, Checkbox } from "antd";

import { useHistory, Link } from "react-router-dom";

import { AppContext } from "../context";

import { useForm } from "antd/lib/form/Form";
import { useParams } from "react-router";
import { SearchOutlined, SendOutlined } from "@ant-design/icons";

import { getAllMemberAccount, GetFilteredMemberAccounts } from "../api/catalog";

import { set } from "lodash";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

const TransferOwnerShipSearchForm: FC<any> = () => {
    const [loading, setLoading] = useState<boolean>(false);
    //   const {catalogId} = props;
    const [form] = useForm();

    const history = useHistory();
    const { authToken, allMemberAccountList, setAllMemberAccountList } = useContext(AppContext);

    const onFinish = async (values: any) => {
        const { search } = values;        
        try {
            setLoading(true);
            const createReq = await GetFilteredMemberAccounts(
                authToken,
                search,
            );
            setAllMemberAccountList(createReq.data);
            setLoading(false);
            //   history.push("/catalogs");
        } catch (err) {        
            message.error("Invalid Username and Password Combination!");
            setLoading(false);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };
    const handleDealersSearch = async (event: CheckboxChangeEvent) => {
        if (event.target.checked === true) {
            const a = allMemberAccountList.filter((member: { dealerAccount: boolean; }) => {
                return member.dealerAccount === true
            })
            setAllMemberAccountList(a);
        } else {
            const data = await getAllMemberAccount(authToken);
            setAllMemberAccountList(data.data);
        }

    }
    return (
        <Row style={{ alignItems: "center" }}  >
            <Col className="columns" xs={20} sm={20} md={20} lg={20} xl={20} xxl={20}>
                <div className="catalog-form-fields-wrapper">
                    <Form
                        form={form}
                        name="catalog-search-form"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        layout="horizontal"
                    >
                        <Form.Item name="search">
                            <Input className={"input-primary"} placeholder={"Search"} />
                        </Form.Item>
                        <Form.Item className="submit-btn">
                            <Button className="btn-primary" type="link" htmlType="submit">
                                <span className="send">
                                    <SearchOutlined />
                                </span>
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Col>
            <Col className="columns" xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                <Checkbox onChange={(event) => handleDealersSearch(event)} >Dealers</Checkbox>
            </Col>
        </Row>
    );
};

export default TransferOwnerShipSearchForm;
