import React, { FC, useContext, useEffect } from "react";

import { useHistory } from "react-router-dom";

import { Row, Col, Card } from "antd";

import { AppContext } from "../../context";
import image from "../../assets/image/logo.png";
import RegisterForm from "../../forms/RegisterForm";
// import { t } from "../../utils/helpers";

interface RegisterProps { }

const Register: FC<RegisterProps> = (props: RegisterProps) => {
  // const {} = props;

  const history = useHistory();

  const { authenticatedUser } = useContext(AppContext);

  useEffect(() => {
    if (authenticatedUser) {
      history.push("/catalogs");
    }
  }, [authenticatedUser]);

  return (
    <Row gutter={8} justify="center" className="loginForm" align="middle">
      <Col xs={24} sm={24} md={24} lg={16} xl={10} xxl={8}>
        <Card className="register-card">
          <Row gutter={8}>
            <Col span={24} className="text-center">
              <div className="logo">
                <img src={image} alt={"logo"} />
              </div>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24} className="text-center">
              <RegisterForm />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default Register;
