import React, { FC, useEffect, useContext, useState } from "react";

import { useHistory } from "react-router-dom";

import { Row, Col, Card } from "antd";

import { AppContext } from "../../context";
import { getAllMemberAccount } from "../../api/catalog";
import image from "../../assets/image/logo.png";
import MemberDirectotySearchForm from '../../forms/MemberDirectorySearchForm';
interface MembersDirectoryProps { }

const MembersDirectory: FC<MembersDirectoryProps> = (
  props: MembersDirectoryProps
) => {
  const history = useHistory();
  const { authToken, allMemberAccountList, setAllMemberAccountList } = useContext(AppContext);
  useEffect(() => {
    (async () => {
      const data = await getAllMemberAccount(authToken);
      setAllMemberAccountList(data.data);
    })();
  }, []);
  // const {} = props;

  return (
    <Row className="" style={{ alignItems: "center" }} >
      <Col className="columns" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
        {/* <div className="logo">
          <img src={image} alt={"logo"} />
        </div> */}
        <MemberDirectotySearchForm />
        {allMemberAccountList
          ? allMemberAccountList.map((MemberAccount: any) => {
            const value = MemberAccount.id + "," + MemberAccount.firstName + " " + MemberAccount.lastName;
            return (
              <div
                key={MemberAccount.id}
                onClick={() => {
                  history.push(`/listmembercatalogs/${MemberAccount.id}`);
                }}
                className="checbox-wrapper-item"
              >
                <img className="image" src={MemberAccount.profileImage ? MemberAccount.profileImage : "https://via.placeholder.com/100"} />
                <div className="name">
                  {MemberAccount.firstName + " " + MemberAccount.lastName}
                  {MemberAccount.dealerAccount ? (
                    <i className="dealer"> D </i>
                  ) : null}
                </div>
              </div>
            );
          })
          : null}
      </Col>
    </Row>
  );
};

export default MembersDirectory;
