import React, { FC, useContext, useState } from "react";
import { Dropdown, Menu, Avatar } from "antd";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import {
  DownOutlined,
  DashboardOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { AppContext } from "../context";
import { t } from "../utils/helpers";
interface AppUserMenuProps { }

const AppUserMenu: FC = (props: AppUserMenuProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  const history = useHistory();
  const { authenticatedUser } = useContext(AppContext);


  const menu = (
    <Menu onClick={() => { }}>
      <Menu.Item key="catalogs" icon={<DashboardOutlined />}>
        <Link to={t("/catalogs")}>{t("Catalogs")}</Link>
      </Menu.Item>
      <Menu.Item key="my-profile" icon={<UserOutlined />}>
        <Link to={t("/my-profile")}>{t("My Profile")}</Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      className={"user-menu"}
      overlay={menu}
      onVisibleChange={(visible) => {
        setVisible(visible);
      }}
      visible={visible}
    >
      <a
        className="ant-dropdown-link user-display-name"
      // onClick={() => {
      //   history.push(`/my-profile`);
      // }}
      >
        <Avatar className={"user-avatar"} size={48} icon={<UserOutlined />} />{" "}
        {authenticatedUser.firstName}&nbsp;
        <DownOutlined />
      </a>
    </Dropdown>
  );
};

export default AppUserMenu;
