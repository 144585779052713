import React, { FC, useContext, useEffect, useState } from "react";

import { Row, Col, Radio, Divider, Card, Button, Spin, Modal, Alert } from "antd";

import { UnorderedListOutlined, IdcardOutlined } from "@ant-design/icons";
import { useHistory, Link } from "react-router-dom";
import { AppContext } from "../../context";
import Artifact from "../../models/Artifact";
import ArtifactCard from "./_partials/ArtifactCard";
import CatalogDetailCard from "./_partials/CatalogDetail";
import { useParams } from "react-router";
import { getAllArtifactsByCatalogId } from "../../api/catalog";
interface CatalogProps { }

const Catalog: FC = (props: CatalogProps) => {
  const {
    authToken,
    setFilteredArtifact,
    filteredArtifact,
    authenticatedUser,
    catalog,
    setCatalog,
  } = useContext(AppContext);
  const history = useHistory();
  const [displayMode, setDisplayMode] = useState<string>("card");

  const [loading, setLoading] = useState<boolean>(false);
  const { catalogId } = useParams<any>();
  const [subscriptionModal, setSubscriptionModal] = useState<boolean>(false);
  useEffect(() => {
    if (authenticatedUser.disabled && authenticatedUser.trailExpired) {
      setSubscriptionModal(true);
      setTimeout(() => {
        history.push("/subscription");
      }, 2000);
    }
    (async () => {
      setLoading(true);
      const { data } = await getAllArtifactsByCatalogId(authToken, catalogId);
      setCatalog(data);
      setFilteredArtifact(data.artifactProfiles);
      setLoading(false);
    })();
  }, [catalogId]);
  return (
    <div className="catalog-wrapper">
      <Divider />
      <CatalogDetailCard />
      <Divider />
      <Modal title="Your Account Expired" onCancel={() => setSubscriptionModal(false)} onOk={() => setSubscriptionModal(false)} visible={subscriptionModal}>
        <Alert
          message=""
          description="Please update your billing Information."
          type="warning"
          showIcon
        />
      </Modal>
      {/* <Row className='search-form'>
        <Col xl={12} lg={12} md={20} sm={24} xs={24}>
          <ArtifactSearchForm  />
        </Col>
      </Row>
      <Divider /> */}
      <Row>
        <Col span={24}>
          <Button
            type="primary"
            onClick={() => {
              history.push(`/catalogs/${catalogId}/add-artifact`);
            }}
          >
            +Artifact
          </Button>
          &nbsp;
          <Radio.Group
            defaultValue={displayMode}
            onChange={(e) => {
              setDisplayMode(e.target.value);
            }}
            buttonStyle="solid"
          >
            <Radio.Button value="list">
              <UnorderedListOutlined />
            </Radio.Button>
            <Radio.Button value="card">
              <IdcardOutlined />
            </Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
      <Divider />

      { (!loading && filteredArtifact) && filteredArtifact.length > 0 ? (
        <Row gutter={8}>
          {filteredArtifact.map((artifacts: Artifact) => {
            return (
              <ArtifactCard
                catalogId={catalogId}
                artifact={artifacts}
                renderAs={displayMode}
                catalogCover={catalog.coverPhotoUrl}
              />
            );
          })}
        </Row>
      ) : (
        <Row gutter={8} justify="center" align="middle">
          <Col xs={24} sm={24} md={20} lg={12} xl={8}>
            <Card className="text-center" style={{ marginBottom: "100px" }}>
              <h2>No Artifact found.</h2>
              <h3>Click "+Artifact" to create your first Artifact</h3>
            </Card>
          </Col>
        </Row>
      )}

      {loading ? (
        <Row gutter={8} justify="center">
          <Col>
            <Spin />
          </Col>
        </Row>
      ) : null}
    </div>
  );
};

export default Catalog;
