import Artifact from "../models/Artifact";
import Catalog from "../models/Catalog";
import User from "../models/User";

import { makeApiRequest } from "./index";

export const createCatalog = async (token: string, catalog: Catalog) => {
  const createReq = await makeApiRequest(
    token,
    `/api/Catalog/PostCatalog`,
    catalog
  );

  return createReq;
};

export const postCatalogImage = async (
  token: string,
  catalogId: string,
  image: string
) => {
  const createReq = await makeApiRequest(token, `/api/Catalog/PostImage`, {
    catalogId,
    image,
  });

  return createReq;
};

export const updateCatalogImage = async (
  token: string,
  catalogId: string,
  imageUrl: string
) => {
  const createReq = await makeApiRequest(
    token,
    `/api/Catalog/UpdateImage/${catalogId}`,
    imageUrl,
    true,
    "put"
  );

  return createReq;
};

export const uploadArtifactImage = async (
  token: string,
  artifactId: string,
  image: string,
  order: number
) => {
  const createReq = await makeApiRequest(
    token,
    `/api/ArtifactProfile/PostImage`,
    {
      artifactId,
      image,
      order,
    }
  );
  return createReq;
};

export const uploadArtifactImages = async (
  token: string,
  artifactId: string | any,
  images: string[],
  currentOrder: number
  // 2 images to is case 2
) => {
  const uploads = await Promise.all(
    images.map((image) => {
      const img = image.replace(/^data:image\/[a-z]+;base64,/, "");
      return uploadArtifactImage(token, artifactId, img, ++currentOrder);
    })
  );

  return uploads;
};

export const createArtifact = async (token: string, artifact: Artifact) => {
  const createReq = await makeApiRequest(
    token,
    `/api/ArtifactProfile/PostProfile`,
    artifact
  );

  return createReq;
};

export const updateImageOrderById = async (
  token: string,
  artifactProfileId: string | undefined,
  currentOrder: number,
  NewOrder: number,
) => {
  const createReq = await makeApiRequest(
    token,
    `api/ArtifactProfile/UpdateImage?artifactProfileId=${artifactProfileId}&currentOrder=${currentOrder}&newOrder=${NewOrder}`,
    {},
    true,
    "put"
  );
  return createReq;
};

export const updateCatalog = async (
  token: string,
  catalogId: string,
  catalog: Catalog
) => {
  const createReq = await makeApiRequest(
    token,
    `/api/Catalog/UpdateCatalog/${catalogId}`,
    catalog,
    true,
    "put"
  );

  return createReq;
};

export const updateArtifact = async (
  token: string,
  artifactId: string,
  artifact: Artifact
) => {
  const createReq = await makeApiRequest(
    token,
    `/api/ArtifactProfile/UpdateProfile/${artifactId}`,
    artifact,
    true,
    "put"
  );

  return createReq;
};

export const cancelSubscription = async (
  token: string,
  id: string,
  userObject: any
) => {
  const createReq = await makeApiRequest(
    token,
    `/api/MemberAccount/CancelReoccuringPayment/${id}`,
    userObject,
    true,
    "put"
  );

  return createReq;
};

export const updateProfile = async (token: string, id: string, user: User) => {
  const createReq = await makeApiRequest(
    token,
    `/api/MemberAccount/UpdateMemberAccountById/${id}`,
    user,
    true,
    "put"
  );

  return createReq;
};

export const getUserProfileById = async (token: string, id: string) => {
  const req = await makeApiRequest(
    token,
    `/api/MemberAccount/GetMemberAccountById/${id}`,
    {},
    true,
    "get"
  );

  return req;
};

export const getPublicCatalogList = async (token: string, id: string) => {
  const req = await makeApiRequest(
    token,
    `/api/Catalog/GetUserPublicCatalogs?MemberAccountId=${id}`,
    {},
    true,
    "get"
  );

  return req;
};

export const completeTransfer = async (
  token: string,
  artifactProfileId: string,
  newOwnerMemberAccountId: string
) => {
  const req = await makeApiRequest(
    token,
    `/api/APaceffiilorrtt/CompleteTransfer?artifactProfileId=${artifactProfileId}&newOwnerMemberAccountId=${newOwnerMemberAccountId}`,
    {},
    true,
    "get"
  );

  return req;
};

// export const transferCatalogToNewMemberAccount = async (
//   token: string,
//   id: string,
//   newMemberAccountId: string
// ) => {
//   const req = await makeApiRequest(
//     token,
//     `/api/Catalog/Transfer?catalogId=${id}&newOwnerMemberAccountId=${newMemberAccountId}`,
//     {},
//     true,
//     "get"
//   );

//   return req;
// };

export const transferArtifactToNewMemberAccount = async (
  token: string,
  id: string,
  newMemberAccountId: string
) => {
  const req = await makeApiRequest(
    token,
    `/api/ArtifactProfile/Transfer?artifactProfileId=${id}&newOwnerMemberAccountId=${newMemberAccountId}`,
    {},
    true,
    "get"
  );

  return req;
};

export const memberAccountGetPendingTransfers = async (
  token: string,
  memberAccountId: string
) => {
  const req = await makeApiRequest(
    token,
    `/api/MemberAccount/GetPendingTransfers/${memberAccountId}`,
    {},
    true,
    "get"
  );

  return req;
};

export const getAllMemberAccount = async (token: string) => {
  const req = await makeApiRequest(
    token,
    `/api/MemberAccount/GetPublicMemberAccounts`,
    {},
    true,
    "get"
  );

  return req;
};

export const searchCatalogByText = async (
  token: string,
  searchText: string,
  MemberAccountId: string
) => {
  const createReq = await makeApiRequest(
    token,
    `/api/Catalog/GetFilteredCatalogs?searchText=${searchText}&MemberAccountId=${MemberAccountId}`,
    {},
    true,
    "get"
  );

  return createReq;
};
export const searchArtifactByText = async (
  token: string,
  searchText: string,
  catalogId: string
) => {
  const createReq = await makeApiRequest(
    token,
    `/api/ArtifactProfile/GetFilteredProfiles?searchText=${searchText}&catalogId=${catalogId}`,
    {},
    true,
    "get"
  );

  return createReq;
};

export const GetFilteredMemberAccounts = async (
  token: string,
  searchText: string
) => {
  const createReq = await makeApiRequest(
    token,
    `/api/MemberAccount/GetFilteredMemberAccounts?searchText=${searchText}`,
    {},
    true,
    "get"
  );

  return createReq;
};

export const getAllArtifactsByCatalogId = async (
  token: string,
  catalogId: string
) => {
  const req = await makeApiRequest(
    token,
    `/api/Catalog/GetCatalogById/${catalogId}`,
    {},
    true,
    "get"
  );

  return req;
};

export const getAllMemberCatalogs = async (
  token: string,
  memberAccountId: string
) => {
  const req = await makeApiRequest(
    token,
    `/api/Catalog/GetAllCatalogs?MemberAccountId=${memberAccountId}`,
    {},
    true,
    "get"
  );

  return req;
};

export const getCatalogById = async (token: string, catalogId: string) => {
  const req = await makeApiRequest(
    token,
    `/api/Catalog/GetCatalogById/${catalogId}`,
    {},
    true,
    "get"
  );

  return req;
};

export const getCatalogCollectionById = async (
  token: string,
  catalogId: string
) => {
  const req = await makeApiRequest(
    token,
    `api/Catalog/GetCatalogById/${catalogId}`,
    {},
    true,
    "get"
  );

  return req;
};

export const getArtifactById = async (token: string, artifactId: string|any) => {
  const req = await makeApiRequest(
    token,
    `/api/ArtifactProfile/GetProfileById/${artifactId}`,
    {},
    true,
    "get"
  );

  return req;
};

export const deleteCatalogById = async (token: string, catalogId: string) => {
  const req = await makeApiRequest(
    token,
    `/api/Catalog/Delete/${catalogId}`,
    {},
    true,
    "delete"
  );

  return req;
};

export const deleteArtifactImage = async (
  token: string,
  artifactImageId: string
) => {
  const req = await makeApiRequest(
    token,
    `/api/ArtifactProfile/DeleteImage/${artifactImageId}`,
    {},
    true,
    "delete"
  );

  return req;
};

export const duplicateCatalog = async (token: string, catalogId: string) => {
  const req = await makeApiRequest(
    token,
    `/api/Catalog/Duplicate?catalogId=${catalogId}`,
    {},
    true,
    "get"
  );

  return req;
};

export const duplicateArtifact = async (
  token: string,
  artifactProfileId: string
) => {
  const req = await makeApiRequest(
    token,
    `/api/ArtifactProfile/Duplicate?artifactProfileId=${artifactProfileId}`,
    {},
    true,
    "get"
  );

  return req;
};

export const getLinkCatalog = async (token: string, catalogId: string) => {
  const req = await makeApiRequest(
    token,
    `/api/Catalog/GetLink?catalogId=${catalogId}`,
    {},
    true,
    "get"
  );

  return req;
};
export const geArtifactShareLink = async (
  token: string,
  artifactProfileId: string
) => {
  const req = await makeApiRequest(
    token,
    `/api/ArtifactProfile/GetLink?artifactProfileId=${artifactProfileId}`,
    {},
    true,
    "get"
  );

  return req;
};

export const getExportLinkCatalog = async (
  token: string,
  catalogId: string
) => {
  const req = await makeApiRequest(
    token,
    `/api/Catalog/ExportToExcel?catalogId=${catalogId}`,
    {},
    true,
    "get"
  );

  return req;
};

export const getExportLinkArtifact = async (
  token: string,
  artifactId: string
) => {
  const req = await makeApiRequest(
    token,
    `/api/ArtifactProfile/ExportToExcel?artifactId=${artifactId}`,
    {},
    true,
    "get"
  );

  return req;
};

export const deleteArtifactById = async (token: string, artifactId: string) => {
  const req = await makeApiRequest(
    token,
    `/api/ArtifactProfile/Delete/${artifactId}`,
    {},
    true,
    "delete"
  );

  return req;
};
