import React, { FC, useContext, useEffect, useState } from "react";

import { Row, Col, Spin, Modal, Alert } from "antd";

import ArtifactForm from "../../forms/ArtifactForm";
import { useHistory, useParams } from "react-router-dom";

import { getArtifactById } from "../../api/catalog";
import { AppContext } from "../../context";
import Artifact from "../../models/Artifact";

interface EditCatalogProps { }

const EditCatalog: FC = (props: EditCatalogProps) => {
  //@ts-ignore
  const { artifactId } = useParams();
  // const artifactId = "5bf3ce64-f923-4358-d567-08d91ba99707";

  const { authToken, authenticatedUser } = useContext(AppContext);
  const [subscriptionModal, setSubscriptionModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [artifact, setArtifact] = useState<Artifact>();
  const history = useHistory();
  useEffect(() => {
    if (authenticatedUser.disabled && authenticatedUser.trailExpired) {
      setSubscriptionModal(true);
      setTimeout(() => {
        history.push("/subscription");
      }, 2000);
    }
    (async () => {
      setLoading(true);
      const { data } = await getArtifactById(authToken, artifactId);

      setArtifact(data);
      setLoading(false);
    })();
  }, [artifactId]);
  return (
    <Row gutter={8}>
      <Modal title="Your Account Expired" onCancel={() => setSubscriptionModal(false)} onOk={() => setSubscriptionModal(false)} visible={subscriptionModal}>
        <Alert
          message=""
          description="Please update your billing Information."
          type="warning"
          showIcon
        />
      </Modal>
      <Modal title="Your Account Expired" onCancel={() => setSubscriptionModal(false)} onOk={() => setSubscriptionModal(false)} visible={subscriptionModal}>
        <Alert
          message=""
          description="Please update your billing Information."
          type="warning"
          showIcon
        />
      </Modal>
      <Col span={24}>
        {loading ? (
          <>
            <Spin size="large" />
          </>
        ) : (
          <>
            <ArtifactForm ExistingArtifact={artifact} ArtifactId={artifactId} />
          </>
        )}
      </Col>
    </Row>
  );
};

export default EditCatalog;
