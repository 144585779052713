import React, { FC, useContext, useState } from "react";

import { Row, Col, Card, Button, Popconfirm } from "antd";
import { AppContext } from "../../../context";
import { useHistory } from "react-router";

import Catalog from "../../../models/Catalog";
import {
  deleteCatalogById,
  getAllMemberCatalogs,
  duplicateCatalog,
  getLinkCatalog,
  getExportLinkCatalog,
} from "../../../api/catalog";

interface CatalogCardProps {
  renderAs: string;
  itemId: string;
  catalog: Catalog;
}

const CatalogCard: FC<CatalogCardProps> = (props: CatalogCardProps) => {
  const { itemId, renderAs, catalog } = props;

  const { authToken, setMemberCatalogs, authenticatedUser } =
    useContext(AppContext);

  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);
  const [duplicateLoading, setduplicateLoading] = useState<boolean>(false);
  const [getLinkLoading, setgetLinkLoading] = useState<boolean>(false);
  const [exportLinkLoading, setExportLinkLoading] = useState<boolean>(false);
  const featuredImage = catalog.coverPhotoUrl ?? "https://via.placeholder.com/500"

  return (
    <Col
      xs={renderAs === "list" ? 24 : 8}
      sm={renderAs === "list" ? 24 : 8}
      md={renderAs === "list" ? 24 : 8}
      lg={renderAs === "list" ? 24 : 8}
      xl={renderAs === "list" ? 24 : 8}  
    >
      <Card
        onClick={() => {
          history.push(`/catalogs/view/${catalog.id}`);
        }}
        hoverable
        cover={
          <img
            onClick={() => {
              history.push(`/catalogs/view/${catalog.id}`);
            }}
            src={featuredImage}
          />
        }
        className={renderAs === "list" ? "list-mode" : "card-mode"}        
      >
        <Card.Meta
          className={"catalog-meta"}
          title={catalog.name ? catalog.name : <span>&nbsp;</span>}
          description={
            renderAs === "list" ? (
              catalog.description1 ? (
                catalog.description1
              ) : catalog.description2 ? (
                catalog.description2
              ) : (
                <span>&nbsp;</span>
              )
            ) : null
          }
        />
      </Card>
    </Col>
  );
};

export default CatalogCard;
