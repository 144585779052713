import React, { FC, useState, useContext } from "react";
import { Layout, Row, Col, Menu } from "antd";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { t } from "../utils/helpers";
import logo from "../assets/image/logo.png";
import { AppContext } from "../context";

import { CodepenOutlined } from "@ant-design/icons";
import AppUserMenu from "./AppUserMenu";

const { Header } = Layout;

interface AppPublicHeaderProps { }

const AppPublicHeader: FC<AppPublicHeaderProps> = (
  props: AppPublicHeaderProps
) => {
  const { } = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();

  const { authenticatedUser } = useContext(AppContext);

  const pathname = location.pathname.toLowerCase();

  return (
    <Header className={"public-header"}>
      <Row>
        <Col span={4}>
          <div className="public-header-logo">
            <img src={logo} alt="Collectible Artifacts" />
          </div>
        </Col>
        <Col span={4}></Col>
        <Col span={16} style={{ textAlign: "right" }}>
          {authenticatedUser ? (
            <AppUserMenu />
          ) : (
            <Menu mode="horizontal" theme="dark">
              <Menu.Item
                onClick={() => {
                  history.push("/about");
                }}
                key="about"
              >
                About
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  history.push("/faqs");
                }}
                key="faq"
              >
                FAQ
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  history.push("/terms-and-conditions");
                }}
                key="termsandcondition"
              >
                Terms and Conditions
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  history.push("/contact-us");
                }}
                key="contactus"
              >
                Contact Us
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  history.push("/login");
                }}
                key="login"
              >
                Login
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  history.push("/register");
                }}
                key="register"
              >
                Register
              </Menu.Item>
            </Menu>
          )}
        </Col>
      </Row>
    </Header>
  );
};

export default AppPublicHeader;
