import React, { FC, useContext, useState } from "react";

import { Form, Input, Button, message, Row, Col } from "antd";

import { useHistory, Link } from "react-router-dom";

import { AppContext } from "../context";
import { t } from "../utils/helpers";
import { login } from "../api/auth";
import { getUserProfileById } from "../api/catalog";

interface LoginFormProps { }

const LoginForm: FC<LoginFormProps> = (props: LoginFormProps) => {
  // const {} = props;

  const [loading, setLoading] = useState<boolean>(false);

  const history = useHistory();

  const { setAuthenticatedUser, setAuthToken } = useContext(AppContext);

  const onFinish = async (values: any) => {
    const { email, password } = values;

    try {
      setLoading(true);

      //attempt login
      const loginReq = await login(email, password);

      const tokenInfo = loginReq.data;
      setAuthToken(tokenInfo.token);
      setLoading(false);
      setAuthenticatedUser(tokenInfo.memberAccount);
      if (tokenInfo.memberAccount.disabled && tokenInfo.memberAccount.trailExpired) {
        history.push("/subscription");
      } else {
        history.push("/catalogs");
      }
    } catch (err) {
      message.error("Invalid Username and Password Combination!");
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      name="login-form"
      initialValues={{}}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <div className="login-form-fields-wrapper">
        <Row gutter={8}>
          <Col span={24}>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: t("Email is required!"),
                },
                {
                  type: "email",
                  message: t("Must be a valid email address!"),
                },
              ]}
            >
              <Input className={"input-primary"} placeholder={t("Email")} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={24}>
            <Form.Item name="password">
              <Input.Password
                className={"input-primary"}
                placeholder={t("Password")}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={12}>
            <Form.Item>
              <Button
                className="btn-primary"
                type="link"
                htmlType="submit"
                block
                loading={loading}
              >
                {t("Login")}
              </Button>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Button
                className="btn-primary"
                type="link"
                block
                disabled={loading}
                onClick={() => {
                  history.push("/register");
                }}
              >
                {t("Register")}
              </Button>
            </Form.Item>
          </Col>
        </Row>

        <Link to={t("/forgot-password")} className={"link-primary"}>
          {t("Forgot Password?")}
        </Link>

        <Link to={t("/about")} className={"link-primary"}>
          {t("About Collector's Catalogs?")}
        </Link>
      </div>
    </Form>
  );
};

export default LoginForm;
