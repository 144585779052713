import React, { FC, useEffect, useContext, useState } from "react";

import { useHistory, useParams } from "react-router-dom";

import { Row, Col, Card, Modal, Alert } from "antd";
import Catalog from "../../models/Catalog";
import { AppContext } from "../../context";
import { getPublicCatalogList } from "../../api/catalog";
interface CatalogListProps { }
const CatalogList: FC<CatalogListProps> = (
    props: CatalogListProps
) => {
    const { memberId } = useParams<any>();
    const history = useHistory();
    const [allCatalogList, setAllCatalogList] = useState<any>();
    const [subscriptionModal, setSubscriptionModal] = useState<boolean>(false);
    const { authToken, authenticatedUser } = useContext(AppContext);
    useEffect(() => {
        if (authenticatedUser.disabled && authenticatedUser.trailExpired) {
            setSubscriptionModal(true);
            setTimeout(() => {
                history.push("/subscription");
            }, 2000);
        }
        (async () => {
            const data = await getPublicCatalogList(authToken, memberId);
            setAllCatalogList(data.data);
        })();
    }, [memberId]);
    useEffect(() => {
    }, [allCatalogList]);
    // const {} = props;

    return (
        <Row gutter={20} >
            <Modal title="Your Account Expired" onCancel={() => setSubscriptionModal(false)} onOk={() => setSubscriptionModal(false)} visible={subscriptionModal}>
                <Alert
                    message=""
                    description="Please update your billing Information."
                    type="warning"
                    showIcon
                />
            </Modal>
            <Col className="columns" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                {allCatalogList && allCatalogList.length > 0 ?
                    (
                        <Row gutter={20}>
                            {allCatalogList.map((catalog: Catalog) => {
                                return (
                                    <Col
                                        xs={8}
                                        sm={8}
                                        md={8}
                                        lg={8}
                                        xl={8}
                                    >
                                        <Card
                                            key={catalog.id}
                                            hoverable
                                            cover={
                                                <img
                                                    onClick={() => {
                                                        history.push(`/view/collection/${catalog.id}/yes`);
                                                    }}
                                                    src={
                                                        catalog.coverPhotoUrl
                                                            ? catalog.coverPhotoUrl
                                                            : "https://via.placeholder.com/500"
                                                    }
                                                />
                                            }
                                            className={"card-mode"}
                                        >
                                            <Card.Meta
                                                className={"catalog-meta"}
                                                title={catalog.name ? catalog.name : <span>&nbsp;</span>}
                                                description={
                                                    catalog.description1 ? (
                                                        catalog.description1
                                                    ) : catalog.description2 ? (
                                                        catalog.description2
                                                    ) : (
                                                        <span>&nbsp;</span>
                                                    )
                                                }
                                            />
                                        </Card>
                                    </Col>
                                );
                            })}
                        </Row>
                    )
                    : null}
            </Col>
        </Row>
    );
};

export default CatalogList;
