import React, { FC, useContext, useEffect, useState } from "react";
import * as _ from "lodash";
import { Divider, Button, Row, Col, Card, Popconfirm } from "antd";
import Artifact from "../../../models/Artifact";
import { AppContext } from "../../../context";
import { useHistory } from "react-router";
import {
  EditOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";



interface ArtifactCardProps {
  renderAs: string;
  catalogId: string;
  artifact: Artifact;
  catalogCover: string
}

const ArtifactCard: FC<ArtifactCardProps> = (props: ArtifactCardProps) => {
  const { renderAs, catalogId, artifact, catalogCover } = props;
  const { setCatalog, authToken, authenticatedUser } = useContext(AppContext);
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [coverImage, setCoverImage] = useState<string>("");

  useEffect(() => {
    if (artifact.artifactImages && artifact.artifactImages.length > 0) {
      setCoverImage(_.map(artifact.artifactImages, "photoUrl")[0]?.toString())
    } else {
      setCoverImage("https://via.placeholder.com/500")
    }
  }, [artifact])

  const CoverImage = () => {
    if (artifact.artifactImages) {
      return <img src={_.map(artifact.artifactImages, "photoUrl")[1].toString()} />
    } else {
      return <img src="https://via.placeholder.com/500" />
    }
  }

  return (
    <Col
      xs={renderAs === "list" ? 24 : 8}
      sm={renderAs === "list" ? 24 : 8}
      md={renderAs === "list" ? 24 : 8}
      lg={renderAs === "list" ? 24 : 8}
      xl={renderAs === "list" ? 24 : 8}
    >
      <Card
        onClick={() => {
          history.push(`/artifact/edit/${catalogId}/${artifact.id}`);
        }}
        hoverable
        cover={
          <img src={coverImage} alt="coverImage" />
        }
        className={renderAs === "list" ? "list-mode" : "card-mode"}
        actions={[
          <Button
            onClick={() => {
              history.push(`/artifact/edit/${catalogId}/${artifact.id}`);
            }}
          >
            <EditOutlined />
          </Button>,
          // <Button
          //   onClick={() => {
          //     history.push(`/view/artifact/${artifact.id}`);
          //   }}
          // >
          //   <InfoCircleOutlined />
          // </Button>,
        ]}
      >
        <Card.Meta
          title={artifact.name}
          description={renderAs === "list" ? artifact.description : null}
        />
      </Card>
    </Col>
  );
};

export default ArtifactCard;
