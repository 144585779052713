import React, { FC, useContext, useEffect, useState } from "react";

import { Row, Col, Spin, Modal, Alert } from "antd";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context";
import EditProfileForm from "../../forms/EditProfileForm";
import { getUserProfileById } from "../../api/catalog";
import User from "../../models/User";
interface BillingInformation {}
const BillingInformation: FC = (props: BillingInformation) => {
  const history = useHistory();
  const { authToken, authenticatedUser } = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [userData, setUserData] = useState<User | null>(null);
  useEffect(() => {
    (async () => {
      setLoading(true);
      const { data } = await getUserProfileById(
        authToken,
        authenticatedUser.id
      );
      setUserData(data);
      setLoading(false);
    })();
  }, []);
  return (
    <div className="catalog-wrapper">
      {loading ? (
        <Row gutter={8} justify="center">
          <Col>
            <Spin />
          </Col>
        </Row>
      ) : (
        <EditProfileForm User={userData} updateBilling={true} />
      )}
    </div>
  );
};

export default BillingInformation;
