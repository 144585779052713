import React, { FC } from "react";
import {
    Row,
    Col,
} from "antd";
interface TermsContentProps { }

const TermsContent: FC<TermsContentProps> = (
    props: TermsContentProps
) => {
    // const {} = props;
    return (
        <Row gutter={8}>
            <Col span={24} >
                <div>
                    <h2><b><u>Terms of Use</u></b></h2>
                    <p><b><u>Acceptance of the Terms of Use</u></b></p>
                    <p>
                        This Terms of Use Agreement ("Agreement") is entered into by
                        and between you ("you," "your," or "user")
                        and Collector's Catalogs, LLC
                        ("Company," "we," or "us") and is a legal
                        agreement between you and the Company.
                        The following terms and conditions, together with any documents they
                        expressly incorporate by reference (collectively,
                        "Terms of Use"), govern your
                        access to and use of collectorscatalog.com, including any content,
                        functionality, and services offered on or through collectorscatalog.com (the
                        "Website"), whether as a guest or a registered user.
                    </p>
                    <p>
                        Please read the Terms of Use carefully before you start to
                        use the Website. By using the Website or
                        by clicking to accept or agree to the Terms of Use when this option is made
                        available to you, you indicate that you have read, understand, and accept and
                        agree to be bound and abide by these Terms of Use and our Privacy Policy, found
                        at [PRIVACY POLICY URL], incorporated herein by reference.
                        If you do not accept and agree to these Terms
                        of Use and/or the Privacy Policy, you must not access or use the Website.
                    </p>
                    <p>
                        This Website is offered and available to users who are at
                        least 18 years of age or older, and reside in the
                        United States or any of its territories or possessions.
                        By using this Website, you represent and
                        warrant that you are of legal age to form a binding contract with the Company
                        and meet all of the foregoing eligibility
                        requirements. If you are under the age
                        of 18 you may not use this Website.
                    </p>
                    <p><b>Changes to the Terms of Use</b></p>
                    <p>
                        We may revise, amend and/or update these Terms of Use from
                        time to time in our sole discretion. All
                        changes are effective immediately when we post them, and
                        apply to all access to and use of the Website thereafter.
                        However, any changes to the dispute
                        resolution provisions set out in the Governing Law and Jurisdiction section
                        will not apply to any disputes for which the parties have actual notice on or
                        before the date the change is posted on the Website.
                    </p>
                    <p>
                        Your continued use of the Website following the posting of
                        revised Terms of Use means that you accept and agree to the changes.
                        You are expected to check this page each time
                        you access this Website so you are aware of any
                        changes, as they are binding on you.
                    </p>
                    <p><b><u>Accessing the Website and Account Security</u></b></p>
                    <p>
                        We reserve the right to withdraw or amend this Website, and
                        any service or material we provide on the Website, in our sole discretion
                        without notice. We will not be liable if
                        for any reason all or any part of the Website is unavailable at any time or for
                        any period. From time to time, we may
                        restrict access to some parts of the Website, or the entire Website,
                        to users, including registered users.
                    </p>
                    <p>You are responsible for both:</p>
                    <ul>
                        <li>
                            Making all arrangements necessary for you to
                            have access to the Website.
                        </li>
                        <li>
                            Ensuring that all persons who access the Website
                            through your internet connection are aware of these Terms of Use and comply
                            with them.
                        </li>
                    </ul>
                    <p>
                        To access the Website or some of the resources it offers,
                        you may be asked to provide certain registration details or other information. It is a condition of your use of the Website
                        that all the information you provide on the Website is correct, current, and
                        complete. You agree that all information
                        you provide to register with this Website or otherwise, including, but not
                        limited to, through the use of any interactive
                        features on the Website, if any, is governed by our Privacy Policy [LINK TO
                        PRIVACY POLICY], and you consent to all actions we take with respect to your
                        information consistent with our Privacy Policy.
                    </p>
                    <p>
                        If you choose, or are provided with, a user
                        name, password, or any other piece of information as part of our
                        security procedures, you must treat such information as confidential, and you
                        must not disclose it to any other person or entity. You also acknowledge that your account is
                        personal to you and agree not to provide any other person with access to this
                        Website or portions of it using your user name,
                        password, or other security information.
                        You agree to notify us immediately of any unauthorized access to or use
                        of your user name or password or any other breach of
                        security. You also agree to ensure that
                        you exit from your account at the end of each session.
                        You should use particular
                        caution when accessing your account from a public or shared computer so
                        that others are not able to view or record your password or other personal
                        information.
                    </p>
                    <p>
                        We have the right to disable any user name,
                        password, or other identifier, whether chosen by you or provided by us, at any
                        time in our sole discretion for any or no reason, including if, in our opinion,
                        you have violated any provision of these Terms of Use.
                    </p>
                    <p><b><u>Intellectual Property Rights</u></b></p>
                    <p>
                        The Website and its entire contents, features, and
                        functionality (including but not limited to all information, software, text,
                        displays, images, video, and audio, and the design, selection, and arrangement
                        thereof) are owned by the Company, its licensors, or other providers of such
                        material and are protected by United States and international copyright,
                        trademark, patent, trade secret, and other intellectual property or proprietary
                        rights laws.
                    </p>
                    <p>
                        These Terms of Use permit you to use the Website for your
                        personal, non-commercial use only. You must not reproduce, distribute, modify,
                        create derivative works of, publicly display, publicly perform, republish,
                        download, store, or transmit any of the material on our Website,
                        except as follows:
                    </p>
                    <ul>
                        <li>
                            Your computer may temporarily store copies of
                            such materials in RAM incidental to your accessing and viewing those materials.
                        </li>
                        <li>
                            You may store files that are automatically
                            cached by your Web browser for display enhancement purposes.
                        </li>
                        <li>
                            You may print or download one copy of a
                            reasonable number of pages of the Website for your own personal, non-commercial
                            use and not for further reproduction, publication, or distribution.
                        </li>
                        <li>
                            If we provide desktop, mobile, or other
                            applications for download, you may download a single copy to your computer or
                            mobile device solely for your own personal, non-commercial use, provided you
                            agree to be bound by our end user license agreement for such applications.
                        </li>
                        <li>
                            If we provide social media features with certain
                            content, you may take such actions as are enabled by such features.
                        </li>
                    </ul>
                    <p>You must not:</p>
                    <ul>
                        <li>Modify copies of any materials from this site.</li>
                        <li>
                            Use any illustrations, photographs, video or
                            audio sequences, or any graphics separately from the accompanying text.
                        </li>
                        <li>
                            Delete or alter any copyright, trademark, or
                            other proprietary rights notices from copies of materials from this site.
                        </li>
                        <li>
                            You must not access or use for any commercial purposes any
                            part of the Website or any services or materials available through the Website.
                        </li>
                        <li>
                            If you wish to make any use of material on the Website other
                            than that set out in this section, please address your request to: [EMAIL
                            ADDRESS].
                        </li>
                        <li>
                            If you print, copy, modify, download, or otherwise use or
                            provide any other person with access to any part of the Website in breach of
                            the Terms of Use, your right to use the Website will stop immediately and you
                            must, at our option, return or destroy any copies of the materials you have
                            made. No right, title, or interest in or to the Website or any content on the
                            Website is transferred to you, and all rights not expressly granted are
                            reserved by the Company. Any use of the Website not expressly permitted by
                            these Terms of Use is a breach of these Terms of Use and may violate copyright,
                            trademark, and other laws.
                        </li>
                    </ul>
                    <p><b><u>Trademarks</u></b></p>
                    <p>
                        The Company name, the terms [COMPANY TRADEMARKS], the
                        Company logo, and all related names, logos, product and service names, designs,
                        and slogans are trademarks of the Company or its affiliates or licensors. You
                        must not use such marks without the prior written permission of the Company.
                        All other names, logos, product and service names, designs, and slogans on this
                        Website are the trademarks of their respective owners.
                    </p>
                    <p><b><u>Prohibited Uses</u></b></p>
                    <p>
                        You may use the Website only for lawful purposes and in
                        accordance with these Terms of Use. You agree not to use the Website:
                    </p>
                    <ul>
                        <li>
                            In any way that violates any applicable federal,
                            state, local, or international law or regulation (including, without
                            limitation, any laws regarding the export of data or software to and from the
                            U.S. or other countries).
                        </li>
                        <li>
                            To create, disseminate or transmit files,
                            graphics, software, recordings or other materials that actually or potentially
                            violate any law or infringe the intellectual property, privacy, publicity or
                            other rights of any third party;
                        </li>
                        <li>
                            To create, share, disseminate or transmit files,
                            graphics, software, recording or other materials that actually or potentially
                            (a) violates the Terms of Use, and other terms and policies that apply to your
                            use of the Website; (b) that is unlawful, misleading, discriminatory, or fraudulent;
                            (c) that infringes or violates someone else's rights, including their tangible
                            property and intellectual property rights; or (d) otherwise violates the law.
                        </li>
                        <li>
                            To access or collect data from our  "Website using automated means (without our prior permission)
                            or attempt to access data you do not have permission to access.
                        </li>
                        <li>
                            For the purpose of
                            exploiting, harming, or attempting to exploit or harm minors in any way by
                            exposing them to inappropriate content, asking for personally identifiable
                            information, or otherwise.
                        </li>
                        <li>
                            To send, knowingly receive, upload, download,
                            use, or re-use any material that does not comply with the Content Standards set
                            out below in these Terms of Use.
                        </li>
                        <li>
                            To transmit, or procure the sending of, any
                            advertising or promotional material, including any "junk mail,"
                            "chain letter," "spam," or any other similar solicitation.
                        </li>
                        <li>
                            To impersonate or attempt to impersonate the
                            Company, a Company employee, another user, or any other person or entity
                            (including, without limitation, by using email addresses or screen names
                            associated with any of the foregoing).
                        </li>
                        <li>
                            To obtain or attempt to obtain any materials or
                            information through the Website by any means not intentionally made available
                            or provided by the Company.
                        </li>
                        <li>
                            To engage in any other conduct that restricts or
                            inhibits anyone's use or enjoyment of the Website, or which, as determined by
                            us, may harm the Company or users of the Website, or expose them to liability.
                        </li>
                    </ul>
                    <p>Additionally, you agree not to:</p>
                    <ul>
                        <li>
                            Use the Website in any manner that could
                            disable, overburden, damage, or impair the site or interfere with any other
                            party's use of the Website, including their ability to engage in real time
                            activities through the Website.
                        </li>
                        <li>
                            Use any robot, spider, or other automatic
                            device, process, or means to access the Website for any purpose, including
                            monitoring or copying any of the material on the Website.
                        </li>
                        <li>
                            Use any manual process to monitor or copy any of
                            the material on the Website, or for any other purpose not expressly authorized
                            in these Terms of Use, without our prior written consent.
                        </li>
                        <li>
                            Use any device, software, or routine that
                            interferes with the proper working of the Website.
                        </li>
                        <li>
                            Introduce any viruses, Trojan horses, worms,
                            logic bombs, or other material that is malicious or technologically harmful.
                        </li>
                        <li>
                            Attempt to gain unauthorized access to,
                            interfere with, damage, or disrupt any parts of the Website, the server on
                            which the Website is stored, or any server, computer, or database connected to
                            the Website.
                        </li>
                        <li>
                            Attack the Website via a denial-of-service
                            attack or a distributed denial-of-service attack.
                        </li>
                        <li>
                            Otherwise attempt to interfere with the proper
                            working of the Website.
                        </li>
                    </ul>
                    <p><b><u>User Contributions</u></b></p>
                    <p>
                        The Website may contain blogs, message boards, chat rooms,
                        personal web pages or profiles, forums, bulletin boards, and other interactive
                        features (collectively, "Interactive Services") that allow users to
                        post, share, store, submit, publish, display, transmit or otherwise disseminate
                        to other users or other persons (hereinafter, "post" or "posted")
                        content, historical information, photographs, or other materials (collectively,
                        "User Contributions") on or through the Website.
                    </p>
                    <p>
                        All User Contributions must comply with the Content
                        Standards set out in these Terms of Use.
                    </p>
                    <p>
                        Any User Contribution you post to the Website will be
                        considered non-confidential and non-proprietary. By providing any User
                        Contribution on the Website, you grant us and our affiliates and service
                        providers, and each of their and our respective licensees, successors, and
                        assigns the right to use, reproduce, modify, perform, display, distribute, and
                        otherwise disclose to third parties any such material according to your account
                        settings and the Terms of Use.
                    </p>
                    <p>You represent and warrant that: </p>
                    <ul>
                        <li>
                            You own or control all rights in and to the User
                            Contributions and have the right to grant the license granted above to us and
                            our affiliates and service providers, and each of their and our respective
                            licensees, successors, and assigns.
                        </li>
                        <li>
                            You have the legal right to post the User
                            Contributions to the Website.
                        </li>
                        <li>
                            The User Contributions and the purpose for
                            posting it complies with all laws, rules, and regulations that may apply.
                        </li>
                        <li>
                            The User Contributions do not infringe the
                            intellectual property rights (such as copyrights and trademark rights) of any
                            other person or entity.
                        </li>
                        <li>
                            The User Contributions do not include non-public
                            personal private information belonging to someone else, such as another
                            person's birthdate, home address, or telephone number.
                        </li>
                        <li>
                            That you are responsible for your use of the
                            Website and for any User Contributions or other content that you post or that
                            is otherwise posted to or by your profile and/or account. We do not endorse, support, represent, or
                            affirm the completeness, truthfulness, accuracy, or reliability of any of the
                            User Contributions posted through the Website by you, other users, or any other
                            third party. Further, we do not endorse
                            any opinions expressed through the Website.
                            All User Contributions are the sole responsibility of the person who
                            originated the User Contributions, and we do not take responsibility for such
                            User Contributions.
                        </li>
                        <li>
                            All of your User
                            Contributions do and will comply with these Terms of Use.
                        </li>
                    </ul>
                    <p>
                        You understand and acknowledge that you are responsible for
                        any User Contributions you submit or contribute, and you, not the Company, have
                        full responsibility for such content, including its legality, reliability,
                        accuracy, and appropriateness. We are
                        not responsible or liable to any user or third party for the content or
                        accuracy of any User Contributions posted by you, any other user, or any third
                        party of the Website.
                    </p>
                    <p>
                        The Website provides a venue for collectors and/or artifact
                        enthusiasts to store and share their artifacts with other collectors. The
                        Website and our services are provided as is, with no warranty or guarantee as
                        to their usefulness for any particular purpose. You acknowledge and agree that
                        you are solely responsible for creating backup copies of and replacing any User
                        Content you post, share, or otherwise store on the Website at your sole cost
                        and expense. We are not responsible for
                        any loss, including but not limited to any loss of data and we do not guarantee
                        the availability or accessibility of any of your data. We recommend that you
                        download and maintain a copy of all of your data on
                        your computer's hard drive, so that you may restore your data, in the event of
                        a data loss.
                    </p>
                    <p><b><u>Monitoring and Enforcement; Termination</u></b></p>
                    <p>We have the right to:</p>
                    <ul>
                        <li>
                            Remove or refuse to post any User Contributions
                            for any or no reason in our sole discretion.
                        </li>
                        <li>
                            Take any action with respect to any User
                            Contribution that we deem necessary or appropriate in our sole discretion,
                            including if we believe that such User Contribution violates the Terms of Use,
                            including the Content Standards, infringes any intellectual property right or
                            other right of any person or entity, threatens the personal safety of users of
                            the Website or the public, or could create liability for the Company.
                        </li>
                        <li>
                            Disclose your identity or other information
                            about you to any third party who claims that any User Contributions or other
                            material posted by you violates their rights, including their intellectual
                            property rights or their right to privacy.
                        </li>
                        <li>
                            Take appropriate legal action, including without
                            limitation, referral to law enforcement, for any illegal or unauthorized use of
                            the Website.
                        </li>
                        <li>
                            Terminate or suspend your access to all or part
                            of the Website for any or no reason, including without limitation, any
                            violation of these Terms of Use. If your
                            access is terminated or suspended you will not receive
                            a refund for any amounts that have been charged.
                        </li>
                        <li>
                            Without limiting the foregoing, we have the right to
                            cooperate fully with any law enforcement authorities or court order requesting
                            or directing us to disclose the identity or other information of anyone posting
                            any materials on or through the Website. YOU WAIVE AND HOLD HARMLESS THE
                            COMPANY AND ITS AFFILIATES, LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS
                            RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING, OR
                            TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER SUCH
                            PARTIESOR LAW ENFORCEMENT AUTHORITIES.
                        </li>
                        <li>
                            However, we cannot and do not undertake to review all
                            material before it is posted on the Website, and
                            cannot ensure prompt removal of objectionable material after it has been
                            posted. Accordingly, we assume no liability for any action or inaction
                            regarding transmissions, communications, User Contributions, or other content
                            provided by any user or third party. We have no liability or responsibility to
                            anyone for performance or nonperformance of the activities described in this
                            section.
                        </li>
                    </ul>
                    <p><b><u>Content Standards</u></b></p>
                    <ul>
                        <li>
                            These content standards apply to  "any and all User Contributions and use of Interactive
                            Services. User Contributions must in their entirety comply with all applicable
                            federal, state, local, and international laws and
                            regulations. Without limiting the foregoing, User Contributions must not:
                        </li>
                        <li>
                            Contain any material that is defamatory,
                            obscene, indecent, abusive, offensive, harassing, violent, hateful,
                            inflammatory, or otherwise objectionable.
                        </li>
                        <li>
                            Promote sexually explicit or pornographic
                            material, violence, or discrimination based on race, sex, religion,
                            nationality, disability, sexual orientation, or age.
                        </li>
                        <li>
                            Infringe any patent, trademark, trade secret,
                            copyright, other intellectual property rights, or any other rights of any other
                            person or entity.
                        </li>
                        <li>
                            Violate the legal rights (including the rights
                            of publicity and privacy) of others or contain any material that could give
                            rise to any civil or criminal liability under applicable laws or regulations or
                            that otherwise may be in conflict with these Terms of
                            Use and our Privacy Policy [LINK TO PRIVACY POLICY].
                        </li>
                        <li>Be likely to deceive any person.</li>
                        <li>Promote any illegal activity, or advocate, promote, or assist any unlawful act.</li>
                        <li>Cause annoyance, inconvenience, or needless anxiety or be likely to upset, embarrass, alarm, or annoy any other person.</li>
                        <li>
                            Impersonate any person, or misrepresent your identity or affiliation with any person or organization.
                        </li>
                        <li>
                            Involve commercial activities or sales, such as	contests, sweepstakes, and other sales promotions, barter, or advertising.
                        </li>
                        <li>Give the impression that they emanate from or are endorsed by us or any other person or entity, if this is not the case.</li>
                    </ul>
                    <p><b><u>Copyright Infringement</u></b></p>
                    <p>
                        If you believe that any User Contributions violate your
                        copyright, please send us a notice of copyright infringement. It is the policy
                        of the Company to terminate the user accounts of repeat infringers. If your
                        copyright has been infringed by any User Contributions or other content on the
                        Website and you did not grant a license for this use by uploading your
                        copyrighted work to the Website, you may submit a notice that meets all of the
                        requirements of the Digital Millennium Copyright Act (DMCA), 17 U.S.C.
                        512(c)(3), to the Company's designated Copyright Agent, _____________ or
                        [PHYSICAL ADDRESS]. Your notice must
                        include: (1) electronic or physical signature of the copyrighted work owner (or
                        person authorized by the copyright owner), (2) a description of the copyrighted
                        work, including the URL where the infringing content is available, or a copy of
                        it, (3) contact details of the person submitting the notice, including emails
                        address, telephone, and mailing address, (4) statement in "good faith belief"
                        that the work is not authorized by the copyright owner, and (5) a statement by
                        the sender that all of the above information is accurate, and that the person
                        sending the notice is either the copyright owner or is authorized to act on
                        behalf of the copyright owner. Upon
                        receiving a notice satisfying these requirements, we will take whatever action,
                        in its sole discretion, it deems appropriate, including removal of the
                        challenged content from the services.
                        You acknowledge and agree that if you fail to comply with
                        "all of the requirements set forth in this Copyright
                        Infringement section, your DMCA notice may not be valid.
                    </p>
                    <p><b><u>Reliance on Information Posted</u></b></p>
                    <p>
                        The information presented on or through the Website is made
                        available solely for general information purposes. We do not warrant the
                        accuracy, completeness, or usefulness of this information. Any reliance you
                        place on such information is strictly at your own risk. We disclaim all
                        liability and responsibility arising from any reliance placed on such materials
                        by you, other user, visitor, or any other third parties to the Website, or by
                        anyone who may be informed of any of its contents.
                    </p>
                    <p>
                        This Website includes content provided by third parties,
                        which may include materials provided by other users, bloggers, and third-party
                        licensors, syndicators, aggregators, and/or reporting services. All statements
                        and/or opinions expressed in these materials, and all articles and responses to
                        questions and other content are solely the opinions and the responsibility of
                        the person or entity providing those materials. These materials do not
                        necessarily reflect the opinion of the Company.
                        We are not responsible, or liable to you or any third party, for the
                        content or accuracy of any materials provided by any third parties.
                    </p>
                    <p><b><u>Changes to the Website</u></b></p>
                    <p>
                        We may update the content on this Website from time to time,
                        but its content is not necessarily complete or up-to-date.
                        Any of the material on the Website may be out of date at any given time, and we
                        are under no obligation to update such material.
                    </p>
                    <p>Information About You and Your Visits to the Website</p>
                    <p>
                        All information we collect on this Website is subject to our
                        Privacy Policy [LINK TO PRIVACY POLICY]. By using the Website, you acknowledge
                        that you have read, understand, and are otherwise informed of our Privacy
                        Policy and that you consent to our Privacy Policy all actions taken by us with
                        respect to your information in compliance with the Privacy Policy.
                    </p>
                    <p>
                        <b>
                            <u>
                                Subscriptions and Billing; Online Purchases and Other
                                Terms and Conditions
                            </u>
                        </b>
                    </p>
                    <p>
                        By signing up for your subscription to the Website, you are
                        expressly agreeing that we are authorized to charge you a monthly or yearly
                        subscription fee, any other fees for additional services you may purchase, and
                        any applicable taxes in connection with your use of the Website to the credit
                        card or other payment method accepted by us or our third party payment
                        processor ("Payment Method") that you provided during registration. If you want
                        to use a different Payment Method than the one you signed up for during
                        registration, or if there is a change in your credit card validity or
                        expiration date, you may edit your Payment Method information by clicking on
                        the Subscription tab. If your Payment Method expires and you do not edit your
                        Payment Method information or cancel your account, you authorize us to continue
                        billing, and you will remain responsible for any uncollected amounts.
                    </p>
                    <p>
                        As used in these Terms of Use, "billing" shall indicate
                        either a charge or debit, as applicable, against your Payment Method. The
                        subscription fee will be billed at the beginning of your subscription or
                        expiration of your free trial period, if any, whichever is earlier, and on each
                        monthly or yearly renewal thereafter unless and until you cancel your
                        subscription or the account or service is otherwise suspended, terminated, or
                        discontinued pursuant to these Terms of Use. To see the commencement date for
                        your next renewal period, go to the billing information section on the
                        Subscription tab.
                    </p>
                    <p>
                        We automatically bill your Payment Method each month or
                        year, whichever is applicable, on the calendar day corresponding to the
                        commencement of your subscription. However, if you change your Payment Method,
                        this could result in changing the calendar day on which you are billed. In the
                        event your subscription began on a day not contained in a
                        given month, we bill your Payment Method on the last day of such month.
                        For example, if you became a paying subscriber on January 31, your Payment
                        Method would next be billed on February 28. You acknowledge that the amount
                        billed each month or year may vary due to promotional offers, changes in your
                        subscription plan, and changes in applicable taxes, and you authorize us to
                        charge your Payment Method for the corresponding amounts. If our Company changes
                        the subscription fee or other charges in connection with the Website from time
                        to time, we will give you advance notice of these changes by e-mail. However,
                        we will not be able to notify you of changes in any applicable taxes.
                    </p>
                    <p>
                        If you cancel after your subscription renewal date, you will
                        not receive a refund for any amounts that have been charged. Your cancellation
                        will be effective at the end of your then-current subscription period, subject
                        to applicable law, and you may use the Website until your cancellation is
                        effective (unless your access is suspended or terminated as set forth herein).
                    </p>
                    <p><b><u>Ongoing Subscription and Cancellation. </u></b></p>
                    <p>
                        Your subscription will continue in effect on a
                        month-to-month or year-to-year (based upon your selection at the time of
                        registration) basis unless and until you cancel your subscription or the
                        account or service is otherwise suspended, terminated, or discontinued pursuant
                        to these Terms of Use. You must cancel your subscription before it renews each
                        month or year, whichever is applicable, in order to avoid the next  "month's or year's billing. We will bill the monthly or
                        yearly subscription fee and any applicable taxes to the Payment Method you
                        provide to us during registration (or to a different Payment Method if you
                        change your account information). You can cancel your account by going to the
                        Subscription tab, choosing to "edit" your subscription under "Billing
                        Information," and clicking on the "cancel" option.
                    </p>
                    <p><b><u>Unpaid Amounts. </u></b></p>
                    <p>
                        In order to sustain the Website and
                        our services, it is important that each user of the Website honor the payment
                        obligations to which the user agreed. Accordingly, we reserve the right to
                        pursue any amounts you fail to pay in connection with your subscription to the
                        Website. You will remain liable to the Company for all such amounts and all
                        costs we incur in connection with the collection of these amounts, including,
                        without limitation, collection agency fees, reasonable attorneys' fees, and
                        arbitration or court costs.
                    </p>
                    <p>Linking to the Website and Social Media Features</p>
                    <p>
                        You may link to our Website,
                        provided you do so in a way that is fair and legal and does not damage our
                        reputation or take advantage of it, but you must not establish a link in such a
                        way as to suggest any form of association, approval, or endorsement on our part
                        without our express written consent.
                    </p>
                    <p>
                        This Website may provide certain social media features that
                        enable you to:
                    </p>
                    <p>
                        Link from your own or certain third-party websites to
                        certain content on this Website.
                    </p>
                    <p>
                        Send emails or other communications with certain content, or
                        links to certain content, on this Website.
                    </p>
                    <p>
                        Cause limited portions of content on this Website to be
                        displayed or appear to be displayed on your own or certain third-party
                        websites.
                    </p>
                    <p>
                        You may use these features solely as they are provided by  "us[,/ and] solely with respect to the content they are
                        displayed with[,] [and otherwise in accordance with any additional terms and
                        conditions we provide with respect to such features]. Subject to the foregoing,
                        you must not:
                    </p>
                    <p>Establish a link from any website that is not owned by you.</p>
                    <p>
                        Cause the Website or portions of it to be displayed on, or
                        appear to be displayed by, any other site, for example, framing, deep linking,
                        or in-line linking.
                    </p>
                    <p>
                        <b>
                            <u>
                                Link to any part of the Website other than the
                                homepage.
                            </u>
                        </b>
                    </p>
                    <p>
                        Otherwise take any action with respect to the materials on
                        this Website that is inconsistent with any other provision of these Terms of
                        Use.
                    </p>
                    <p>
                        The website from which you are linking, or on which you make
                        certain content accessible, must comply in all respects with the Content
                        Standards set out in these Terms of Use.
                    </p>
                    <p>
                        You agree to cooperate with us in causing any unauthorized
                        framing or linking immediately to stop. We reserve the right to withdraw
                        linking permission without notice.
                    </p>
                    <p>
                        We may disable all or any social media features and any
                        links at any time without notice in our discretion.
                    </p>
                    <p><b><u>Links from the Website</u></b></p>
                    <p>
                        If the Website contains links to other websites,
                        applications, and resources provided by other users and/or third parties
                        ("Linked Sites"), these Linked Sites are provided for your convenience only and
                        do not constitute an endorsement by us of Linked Sites and the third-party
                        content found there. These Linked Sites
                        have separate and independent privacy policies.
                        We have no control over the contents of those Linked Sites, including
                        without limitation any link contained on a Linked Site, and we accept no
                        responsibility for them or for any loss or damage that may arise from your use
                        of them. Further, we are not responsible if the Linked Site is not working
                        correctly or for any viruses, malware, or other harms resulting from your use
                        of a Linked Site. You are responsible for viewing and abiding by the privacy
                        policies and terms of use posted on the Linked Sites.
                        If you decide to access any of the
                        third-party websites linked to this Website, you do so entirely at your own
                        risk. Any such access or dealings are between you and such third party.
                    </p>
                    <p>
                        Further, we do not warrant or make any representation
                        regarding the legality, accuracy, or authenticity of content presented by such
                        websites or any products or services offered by third parties and shall have no
                        liability for any loss or damages arising from the access or use of such
                        websites, products or services.
                    </p>
                    <p><b><u>Geographic Restrictions</u></b></p>
                    <p>
                        The owner of the Website is based in the State of Illinois
                        in the United States. We provide this Website for use only by persons located
                        in the United States. We make no claims that the Website or any of its content
                        is accessible or appropriate outside of the United States. Access to the
                        Website may not be legal by certain persons or in certain countries. If you
                        access the Website from outside the United States, you do so on your own
                        initiative and are responsible for compliance with local laws. Further, if you choose to use this Website
                        you consent to the Terms of Use, our Privacy Policy
                        and all other policies of this Website.
                        You also acknowledge that you have read, understand, and are otherwise
                        informed of the Terms of Use and that your consent is freely given, specific,
                        and unambiguous.
                    </p>
                    <p><b><u>Disclaimer of Warranties</u></b></p>
                    <p>
                        You understand that we cannot and do not guarantee or
                        warrant that files available for downloading from the
                        internet, the Website, or any Linked Site will be free of viruses or other
                        destructive code. You are responsible for implementing sufficient procedures
                        and checkpoints to satisfy your particular requirements
                        for anti-virus protection and accuracy of data input and output, and for
                        maintaining a means external to our site for any reconstruction of any lost
                        data. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS
                        OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER
                        TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT,
                        COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE
                        WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR
                        DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
                    </p>
                    <p>
                        YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR
                        ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS
                        CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON
                        AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES
                        OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON
                        ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT
                        TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY
                        OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE
                        ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS
                        CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE
                        ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE
                        CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF
                        VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE WEBSITE OR ANY SERVICES OR
                        ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR
                        EXPECTATIONS.
                    </p>
                    <p>
                        TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY
                        DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR
                        OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY,
                        TITLE, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
                    </p>
                    <p>
                        THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE
                        EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                    </p>
                    <p><b><u>Limitation on Liability</u></b></p>
                    <p>
                        TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE
                        COMPANY, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES,
                        AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY
                        LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO
                        USE, THE WEBSITE, ANY LINKED SITE, ANY CONTENT ON THE WEBSITE OR SUCH OTHER
                        WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL,
                        OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND
                        SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF
                        BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA,
                        LOSS OF INFORMATION, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH
                        OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.
                    </p>
                    <p>
                        The limitation of liability set out above does not apply to
                        liability resulting from our gross negligence or willful misconduct.
                    </p>
                    <p>
                        THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE
                        EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                    </p>
                    <p><b><u>Indemnification</u></b></p>
                    <p>
                        You agree to defend, indemnify, and hold harmless the
                        Company, its affiliates, licensors, and service providers, and its and their
                        respective officers, directors, employees, contractors, agents, licensors,
                        suppliers, successors, and assigns from and against any claims, liabilities,
                        damages, judgments, awards, losses, costs, expenses, or fees (including
                        reasonable attorneys' fees) arising out of or relating to your violation of
                        these Terms of Use, the Privacy Policy, or your use of the Website, including,
                        but not limited to, your User Contributions, any use of the Website's content,
                        services, and products other than as expressly authorized in these Terms of
                        Use, the Privacy Policy, or your use of any information obtained from the
                        Website.
                    </p>
                    <p><b><u>Governing Law and Jurisdiction</u></b></p>
                    <p>
                        The Company is located in and
                        operates the Website from Salem, Illinois.
                        The Company provides the Website for use only by persons located in the
                        United States, and makes no claims that the Website or
                        any of the User Contributions or other content is accessible or appropriate
                        outside of the United States. All
                        matters relating to the Website, these Terms of Use, the Privacy Policy, and
                        any dispute or claim arising therefrom or related thereto (in each case,
                        including non-contractual disputes or claims), shall be governed by and
                        construed in accordance with the internal laws of the State of Illinois, United
                        States of America, without giving effect to any choice or conflict of law
                        provision or rule (whether of the State of Illinois or any other jurisdiction).
                    </p>
                    <p>
                        Any legal suit, action, or proceeding arising out of, or
                        related to, these Terms of Use or the Website shall be instituted exclusively
                        in the courts of the State of Illinois, located in the City of Salem and County
                        of Marion, although we retain the right to bring any suit, action, or
                        proceeding against you for breach of these Terms of Use in your country of
                        residence or any other relevant country. You waive any and
                        all objections to the exercise of jurisdiction over you by such courts
                        and to venue in such courts.
                    </p>
                    <p><b><u>Arbitration</u></b></p>
                    <p>
                        At Company's sole discretion, it may require you to submit
                        any disputes arising from these Terms of Use or use of the Website, including
                        disputes arising from or concerning their interpretation, violation, invalidity,
                        non-performance, or termination, to final and binding arbitration under the
                        Rules of Arbitration of the American Arbitration Association applying Illinois
                        law.
                    </p>
                    <p>Limitation on Time to File Claims</p>
                    <p>
                        ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR
                        RELATING TO THESE TERMS OF USE OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1)
                        YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR
                        CLAIM IS PERMANENTLY BARRED.
                    </p>
                    <p><b><u>Waiver and Severability</u></b></p>
                    <p>
                        No waiver by the Company of any term or condition set out in
                        these Terms of Use shall be deemed a further or continuing waiver of such term
                        or condition or a waiver of any other term or condition, and any failure of the
                        Company to assert a right or provision under these Terms of Use shall not constitute
                        a waiver of such right or provision.
                    </p>
                    <p>
                        If any provision of these Terms of Use is held by a court or
                        other tribunal of competent jurisdiction to be invalid, illegal, or
                        unenforceable for any reason, such provision shall be eliminated or limited to
                        the minimum extent such that the remaining provisions of the Terms of Use will
                        continue in full force and effect.
                    </p>
                    <p><b><u>Entire Agreement</u></b></p>
                    <p>
                        The Terms of Use, and our Privacy Policy which is
                        incorporated into this Agreement by reference constitute the sole and entire
                        agreement between you and Collector's Catalogs, LLC regarding the Website and
                        supersede all prior and contemporaneous understandings, agreements,
                        representations, and warranties, both written and oral, regarding the Website. If you do not agree to the Terms of Use or
                        the Privacy Policy, you may not access or use the Website.
                    </p>
                    <p><b><u>Severability. </u></b></p>
                    <p>
                        If a provision of this Agreement is rendered invalid, the
                        remaining provisions shall remain in full force and effect.
                    </p>
                    <p>
                        <b>
                            <u>
                                No Partnership and Assigns.
                            </u>
                        </b>
                    </p>
                    <p>
                        You agree that no joint venture, partnership, employment, or
                        agency relationship exists between you and the Company as a
                        result of this Agreement or use of the Website. You may not assign this Agreement without the
                        prior written consent of the Company in all instances.
                        The Company may assign this Agreement, in
                        whole or in part, at any time.
                    </p>
                    <p><b><u>Your Comments and Concerns</u></b></p>
                    <p>
                        This website (collectorscatalog.com) is operated by
                        Collector's Catalogs, LLC.
                    </p>
                    <p>
                        All notices of copyright infringement claims should be sent
                        to the copyright agent designated in our Copyright Infringement section and in
                        the manner and by the means set out therein.
                    </p>
                    <p>
                        All other feedback, comments, requests for technical
                        support, and other communications relating to the Website should be directed
                        to: [EMAIL ADDRESS].
                    </p>
                </div>
                <br />
                <div>
                    <h1>Privacy Policy</h1>
                    <div>
                        <p>
                            <b><u>Introduction</u></b><b>
                            </b>
                        </p>
                        <p>
                            Collector Catalog,
                            LLC (<b>"Company"</b> or<b> "we" </b>or<b> "us"</b>)
                            respect your privacy and are committed to protecting it through our compliance
                            with this policy (the "<b>Privacy Policy</b>" or "<b>Policy</b>").
                        </p>
                        <p>
                            This Privacy Policy
                            describes the types of information we may collect from you or that you may
                            provide when you visit the website collectorscatalog.com (our "<b>Website</b>")
                            and our practices for collecting, using, maintaining, protecting, and
                            disclosing that information.
                        </p>
                        <p>
                            We process and store
                            your information in the U.S. We neither
                            sell your information to third parties nor share your information with foreign
                            governments except in the limited circumstances described in this Privacy Policy
                            (See "How We Share the Information We Collect" below for more detail.)
                        </p>
                        <p>
                            This Policy applies
                            to information we collect:
                        </p>
                        <li>
                            On this Website, or any mobile version thereof.
                        </li>
                        <li>
                            In
                            email, text, and other electronic messages between you and this Website or the
                            Company (including our affiliates and subsidiaries), if any.
                        </li>
                        <li>
                            Through
                            mobile and desktop applications, if any, that you download from this Website,
                            which provide dedicated non-browser-based interaction between you and this
                            Website.
                        </li>
                        <p>
                            It does not apply to
                            information collected by:
                        </p>
                        <li>
                            Us
                            offline or through any other means, including on any other website operated by
                            Company or any third party (including our affiliates and subsidiaries); or
                        </li>
                        <li>
                            Any
                            third party (including our affiliates and subsidiaries), including through any
                            application or content (including advertising, if any) that may link to or be
                            accessible from or on the Website.
                        </li>
                        <p>
                            Please read this
                            Policy carefully to understand our policies and practices regarding your
                            information and how we will treat it. Please also read our Terms of Use ("<b>
                                Terms
                                of Use
                            </b>"), incorporated herein by reference, which governs your use of the
                            Website. If you do not agree with our
                            terms, policies, and/or practices, your choice is not to use our Website. By accessing, registering to, or using this
                            Website, you agree to the Terms of Use and this Privacy Policy. This Policy may change from time to time (see
                            "[Changes to Our Privacy Policy]"). You are expected to check this page each time
                            you access this Website so you are aware of any changes, as they are binding on
                            you. Your continued use of this Website
                            after we make changes is deemed to be acceptance of those changes.
                        </p>
                        <p>
                            <b>
                                <u>
                                    Children
                                    Under the Age of 18
                                </u>
                            </b>
                        </p>
                        <p>
                            Our Website is not
                            intended for children who are under 18 years of age. By using this Website, you
                            represent and warrant that you are at least 18 years of age. No one under the age of 18 may provide any
                            personal information to or on the Website. We do not knowingly collect personal
                            information from children under the age of 18. If you are under 18 years of
                            age, do not use or provide any information on this Website or through any of
                            its features, register on the Website, make any purchases through the Website,
                            use any of the interactive or public comment features of this Website, or
                            provide any information about yourself to us, including but not limited to your
                            name, address, telephone number, email address, or any screen name or user name
                            you may use. If we learn we have collected or received personal information
                            from a child under 18 without verification of parental consent, we will delete
                            that information. If you believe we might have any information from or about a
                            child under 18 years of age, please contact us at [CONTACT INFORMATION].
                        </p>
                        <p>
                            <b>
                                <u>
                                    Information
                                    We Collect About You and How We Collect It
                                </u>
                            </b>
                        </p>
                        <p>
                            In order to operate
                            this Website and provide you with access to certain services and features that
                            we offer through the site, we collect several types of information from and
                            about users of our Website, including information:
                        </p>
                        <ul>
                            <li>
                                By
                                which you may be personally identified, such as name, date of birth, postal
                                address, e-mail address, telephone number, profile photo, passwords, credit and
                                debit card information and other financial institution account information, or
                                any other personal information collected or identifier by which you may be
                                contacted online or offline (hereinafter referred to as "<b>
                                    personal
                                    information
                                </b>").
                            </li>
                            <li>
                                That
                                you otherwise provide to us in any way.
                            </li>
                        </ul>
                        <p>
                            We may collect this
                            information:
                        </p>
                        <ul>
                            <li>
                                Directly
                                from you when you provide it to us.
                            </li>
                            <li>
                                From
                                third parties, for example, our business partners, if any, and/or third-party
                                payment processor.
                            </li>
                            <li>
                                Automatically
                                as you navigate through the site. Information collected automatically may
                                include usage details, IP addresses, and information collected through cookies,
                                web beacons, and other tracking technologies.
                                However, we do not use any automatic data collection methods at this
                                time.
                            </li>
                        </ul>
                        <p>
                            <b>
                                <i>
                                    Information You
                                    Provide to Us
                                </i>
                            </b>
                        </p>
                        <p>
                            The information we
                            collect on or through our Website may include the following:
                        </p>
                        <ul>
                            <li>
                                <b>Registration, Subscription and Profile Information.</b>
                                Information by which you may be personally
                                identified, such as name, date of birth, postal address, e-mail address,
                                telephone number, profile photo, passwords, credit and debit card information
                                and other financial institution account information, any other personal
                                information collected, or any other identifier by which you may be contacted
                                online or offline ("<b>personal information</b>");
                            </li>
                            <li>
                                <b>Other Information.</b>
                                Any
                                information that you provide by filling in forms, posting, sharing, storing,
                                commenting, or otherwise making available on our Website. This includes but is
                                not limited to information provided at the time of registering to use our
                                Website, subscribing to our service, posting material, or requesting further
                                services. We may also ask you for information when you report a problem with
                                our Website.
                            </li>
                            <li>
                                <b>Communications.</b>
                                Records and copies of your correspondence and any attachments or other
                                additional information provided by you (including email addresses), if you
                                contact us.
                            </li>
                            <li>
                                <b>Payment Information.</b>
                                Details of any purchase and/or subscription you make through our Website,
                                your payment-related information, such as your credit card, debit card or other
                                financial institution account information, is collected and stored by us and/or
                                our third-party payment processor on our behalf. However, such third-party service providers are
                                not authorized by us to use your personal information for any other
                                purpose. We shall not be liable in any
                                manner for any act, omission, or other mishandling related to your information
                                that may be caused by the third-party payment processor.
                            </li>
                            <li>
                                <b>Verification Information.</b>
                                If we provide a verification process and you
                                choose to go through the verification process, we may ask for and you may
                                provide information that can be used to verify your identity.
                            </li>
                            <li>
                                <b>
                                    Survey Responses
                                </b>. Your
                                responses to surveys that we might ask you to complete for research purposes.
                            </li>
                            <li>
                                <b>Search Queries.</b>
                                Your search queries
                                on the Website.
                            </li>
                        </ul>
                        <p>
                            The Website may
                            contain blogs, message boards, chat rooms, personal web pages or profiles,
                            forums, bulletin boards, and other interactive features (collectively, "<b>
                                Interactive
                                Services
                            </b>") that allow users to post, share, store, submit, publish,
                            display, transmit or otherwise disseminate to other users or other persons
                            (hereinafter, "<b>post</b>" or "<b>posted</b>") content, historical
                            information, photographs, or other materials (collectively, "<b>
                                User
                                Contributions
                            </b>") on or through the Website. Your User Contributions are posted on and
                            transmitted to others at your own risk.
                            Although you may set certain privacy settings for such information by
                            logging into your account profile, please be aware that no security measures
                            are perfect or impenetrable.
                            Additionally, we cannot control the actions of third parties or other
                            users of the Website with whom you may choose to share your User Contributions. Therefore, we cannot and do not guarantee
                            that your User Contributions will not be viewed by unauthorized persons.
                        </p>
                        <p>
                            <b>
                                <i>
                                    Information We
                                    Collect Through Automatic Data Collection Technologies
                                </i>
                            </b><i>
                            </i>
                        </p>
                        <p>
                            We do not currently
                            collect any information through automatic data collection technologies. However, we reserve the right to do so in the
                            future so read following provisions carefully.
                            In the event we begin collecting information through automatic data
                            collection, we will amend and/or modify this Privacy Policy to inform you of
                            such activity.
                        </p>
                        <p>
                            As you navigate
                            through and interact with our Website, we may use automatic data collection
                            technologies to collect certain information about your equipment, browsing
                            actions, and patterns, including:
                        </p>
                        <ul>
                            <li>
                                Details
                                of your visits to our Website, including traffic data, location data, logs, and
                                other communication data and the resources that you access and use on the
                                Website.
                            </li>
                            <li>
                                Information
                                about your computer and internet connection, including your IP address,
                                operating system, and browser type.
                            </li>
                        </ul>
                        <p>
                            The information we
                            collect automatically may include personal information, or we may maintain it
                            or associate it with personal information we collect in other ways or receive
                            from third parties. It may be used to helps us to improve our Website and to
                            deliver a better and more personalized service, including by enabling us to:
                        </p>
                        <ul>
                            <li>
                                Estimate
                                our audience size and usage patterns.
                            </li>
                            <li>
                                Store
                                information about your preferences, allowing us to customize our Website
                                according to your individual interests.
                            </li>
                            <li>
                                Speed
                                up your searches.
                            </li>
                            <li>
                                Recognize
                                you when you return to our Website.
                            </li>
                        </ul>
                        <p>
                            The technologies we
                            may use for this automatic data collection may include:
                        </p>
                        <ul>
                            <li>
                                <b>Cookies (or browser cookies).</b>
                                A cookie is a small
                                file placed on the hard drive of your computer. You may refuse to accept
                                browser cookies by activating the appropriate setting on your browser. However,
                                if you select this setting you may be unable to access certain parts of our
                                Website. Unless you have adjusted your browser setting so that it will refuse
                                cookies, our system may issue cookies when you direct your browser to our
                                Website.
                            </li>
                            <li>
                                <b>Flash Cookies.</b>
                                Certain features of our Website may use
                                local stored objects (or Flash cookies) to collect and store information about
                                your preferences and navigation to, from, and on our Website. Flash cookies are
                                not managed by the same browser settings as are used for browser cookies. For
                                information about managing your privacy and security settings for Flash
                                cookies, see [Choices About How We Use and Disclose Your Information]
                            </li>
                            <li>
                                <b>Web Beacons.</b>
                                Pages of the Website and our e-mails may
                                contain small electronic files known as web beacons (also referred to as clear
                                gifs, pixel tags, and single-pixel gifs) that permit the Company, for example,
                                to count users who have visited those pages or opened an email and for other
                                related website statistics (for example, recording the popularity of certain
                                website content and verifying system and server integrity).
                            </li>
                        </ul>
                        <p>
                            We do not currently
                            collect personal information automatically, but we may tie this information to
                            personal information about you that we collect from other sources or you
                            provide to us.
                        </p>
                        <p>
                            <b>
                                <u>
                                    Third-Party Use of
                                    Cookies and Other Tracking Technologies
                                </u>
                            </b>
                        </p>
                        <p>
                            Some content or
                            applications, including advertisements, if any, on the Website may be served by
                            third-parties, including but not limited to advertisers, ad networks and
                            servers, content providers, third party payment processors, and application
                            providers. These third parties may use cookies alone or in conjunction with web
                            beacons or other tracking technologies to collect information about you when
                            you use our Website. The information they collect may be associated with your
                            personal information or they may collect information, including personal
                            information, about your online activities over time and across different
                            websites and other online services. They may use this information to provide
                            you with interest-based (behavioral) advertising or other targeted content.
                        </p>
                        <p>
                            We do not control
                            these third parties' tracking technologies or how they may be used. If you have
                            any questions about an advertisement or other targeted content, if any, you
                            should contact the responsible provider directly.
                        </p>
                        <p>
                            <b>
                                <u>
                                    How We Use Your Information
                                </u>
                            </b>
                        </p>
                        <p>
                            We use information
                            that we collect about you or that you provide to us, including any personal
                            information:
                        </p>
                        <ul>
                            <li>
                                To
                                present our Website and its contents to you.
                            </li>
                            <li>
                                To
                                provide, maintain, and improve our Website and services associated therewith.
                            </li>
                            <li>
                                To
                                personalize your experience on our Website, such as by providing tailored
                                content and recommendations, if applicable.
                            </li>
                            <li>
                                To
                                understand and analyze how our users use our Website and services and develop
                                new products, services, features, and/or functionality.
                            </li>
                            <li>
                                To
                                provide you with information, products, or services that you request from us.
                            </li>
                            <li>
                                To
                                communicate with you, provide you with updates and other information relating
                                to our Website and services, provide information that you request, respond to
                                comments, and questions, and otherwise provide customer support.
                            </li>
                            <li>
                                To
                                facilitate transactions, redemptions, and payments.
                            </li>
                            <li>
                                To
                                verify your identity and determine your eligibility to join our network.
                            </li>
                            <li>
                                To
                                find and prevent fraud and respond to trust and safety issues.
                            </li>
                            <li>
                                To
                                fulfill any other purpose for which you provide it.
                            </li>
                            <li>
                                To
                                provide you with notices about your account/subscription, including but not
                                limited to expiration and renewal notices, if any.
                            </li>
                            <li>
                                To
                                carry out our obligations and enforce our rights arising from any contracts
                                entered into between you and us, including but not limited to for billing and
                                collection.
                            </li>
                            <li>
                                To
                                notify you about changes to our Website or any products or services we offer or
                                provide though it.
                            </li>
                            <li>
                                To
                                allow you to participate in interactive features on our Website.
                            </li>
                            <li>
                                For
                                compliance purposes, including enforcing our Terms of Service or other legal
                                rights, or as may be required by applicable laws and regulations or requested
                                by any judicial process or governmental agency.
                            </li>
                            <li>
                                In
                                any other way we may describe when you provide the information.
                            </li>
                            <li>
                                For
                                any other purpose with your consent.
                            </li>
                        </ul>
                        <p>
                            We may also use your
                            information to contact you about goods and services that may be of interest to
                            you. If
                            you
                            do not want us to use your information in this way, please adjust your user
                            preferences in your account profile or email us at [EMAIL]. For more
                            information, see "[Choices About How We Use and Disclose Your Information]"
                            section found herein.
                        </p>
                        <p>
                            We may use the
                            information we have collected from you to enable us to display advertisements
                            to our advertisers' target audiences. Even though we do not disclose your
                            personal information for these purposes without your consent, if you click on
                            or otherwise interact with an advertisement, the advertiser may assume that you
                            meet its target criteria.
                        </p>
                        <p>
                            <b>
                                <u>
                                    Disclosure of Your
                                    Information
                                </u>
                            </b>
                        </p>
                        <p>
                            We will not share
                            personal information with any third parties except as described in this Privacy
                            Policy, including the following:
                        </p>
                        <ul>
                            <li>
                                <b>
                                    Subsidiaries and Affiliates.
                                </b>
                                We may share any information we receive to
                                our subsidiaries and affiliates, if any.
                            </li>
                            <li>
                                <b>
                                    Contractors, Service Providers, Vendors.
                                </b>
                                We may share any information we receive to
                                contractors, service providers, vendors, and other third parties we use to
                                support our business.
                            </li>
                            <li>
                                <b>
                                    Content you Share.
                                </b>
                                Our
                                services allow you to post, find, and enjoy User Contributions and other
                                content on our Website. Your name,
                                display name, profile photo, email, User Contributions, and contacts (i.e.,
                                users you are following) may be viewable and/or searchable by other users. The User Contributions and any other content
                                you post to our Website will be displayed and viewable by other users by
                                default. Although this content is public
                                by default, in the "Choices About How We Use and Disclose Your Information"
                                section below, we describe the account settings and controls you can use to
                                limit the sharing of certain information.
                                We are not responsible for other users' and/or other third parties use
                                of available information, so you should carefully consider whether and what to
                                post and how you identify yourself on our Website.
                            </li>
                            <li>
                                <b>
                                    Marketing.
                                </b>
                                We do
                                not and will not rent, sell, or share information about you with nonaffiliated
                                third parties for their commercial or direct marketing purposes unless we have
                                your affirmative express consent.
                            </li>
                            <li>
                                <b>
                                    Merger, Divesture, Restructuring, Sale, other Sale or
                                    Transfer.
                                </b>
                                We may share any information we receive to a
                                buyer or other successor in the event of a merger, divestiture, restructuring,
                                reorganization, dissolution, or other sale or transfer of some or all of our
                                assets, whether as a going concern or as part of bankruptcy, liquidation, or
                                similar proceeding, in which personal information held by us about our Website
                                users is among the assets transferred.
                            </li>
                            <li>
                                <b>
                                    Responding to Your Request.
                                </b>
                                We may access, preserve, and disclose your
                                information if we believe doing so is necessary and/or appropriate to respond
                                to your requests.
                            </li>
                            <li>
                                <b>
                                    Purpose for Providing.
                                </b>
                                We may share any information we receive to fulfill the purpose for which
                                you provide it.
                            </li>
                            <li>
                                <b>
                                    Other Purposes.
                                </b>
                                We
                                may share any information we receive for any other purpose disclosed by us when
                                you provide the information.
                            </li>
                            <li>
                                <b>
                                    Consent.
                                </b>
                                We
                                may also disclose your information with your permission.<b><u></u></b>
                            </li>
                            <ul>
                                <p>
                                    We may also disclose
                                    your personal information:
                                </p>
                                <li>
                                    <b>
                                        As Required by Law and Similar Disclosure.
                                    </b>
                                    We may access, preserve, and disclose your
                                    information if we believe doing so is necessary and/or appropriate to (a)
                                    comply with any court order, law, or legal process, including to respond to any
                                    government or regulatory request; (b) protect the rights, property, or safety
                                    of the Company and/or any others persons or entities; (c) to investigate and
                                    help prevent security threats, fraud, or other malicious activity; or (d) to
                                    respond to an emergency which we believe in good faith requires us to disclose
                                    such information to assist in preventing the death or serious bodily injury of
                                    any person or Company employee. For the avoidance of doubt, the disclosure of
                                    your information may occur if you post any objectionable content through our
                                    Website.
                                </li>
                                <li>
                                    <b>
                                        Enforcement Purposes.
                                    </b>
                                    To enforce or apply our Terms of Use, Privacy Policy and other
                                    agreements, including but not limited to billing and collection purposes.
                                </li>
                            </ul>
                            <p>
                                <b>
                                    <u>
                                        Blogspace, Chat
                                        Rooms, Forums, Comments/Message Boards, or Other Sharing Features
                                    </u>
                                </b>
                            </p>
                            <p>
                                Please note that
                                blogspace, chat rooms, forums, comments, message boards, and/or other
                                sharing/storage features may be available through the Website. Any information you disclose in these areas
                                is or may be deemed to be public information and you must exercise caution and
                                discretion before you post any User Contributions or any of your personal
                                information on the Website.
                            </p>
                            <p>
                                <b>
                                    <u>
                                        Choices About How We
                                        Use and Disclose Your Information
                                    </u>
                                </b>
                            </p>
                            <p>
                                We strive to provide
                                you with choices regarding the personal information you provide to us. We have
                                created mechanisms to provide you with the following control over your
                                information:
                            </p>
                            <li>
                                <b>
                                    Sharing Preferences.
                                </b>
                                We
                                provide you with settings to allow you to set your sharing preferences
                                regarding your account and the content you post to our Website. Certain information, such as your
                                name/username, may always be publicly available to others, and other
                                information is made publicly available to others by default. To change whether certain information or
                                content is publicly viewable, you can adjust the setting in your account.
                            </li>
                            <li>
                                <b>
                                    Tracking Technologies and Advertising.
                                </b>
                                You can set your
                                browser to refuse all or some browser cookies, or to alert you when cookies are
                                being sent. To learn how you can manage your Flash cookie settings, visit the
                                Flash player settings page on Adobe's website. If you disable or refuse
                                cookies, please note that some parts of this site may then be inaccessible or
                                not function properly.
                            </li>
                            <li>
                                <b>
                                    Disclosure of Your Information for Third-Party
                                    Advertising.
                                </b>
                                If you do not want us to share your personal information with unaffiliated or
                                non-agent third parties for promotional purposes, you can opt-out by logging
                                into the Website and adjusting your user preferences in your account profile or
                                by sending us an email with your request to [EMAIL ADDRESS].
                            </li>
                            <li>
                                <b>
                                    Promotional Offers from the Company.
                                </b>
                                If you do not wish
                                to have your email address/contact information used by the Company to promote
                                our own or third parties' products or services, you can opt-out by logging into
                                the Website and adjusting your user preferences in your account profile or by
                                sending us an email stating your request to [EMAIL ADDRESS]. If we have sent
                                you a promotional email, you may send us a return email asking to be omitted
                                from future email distributions. This opt out does not apply to information
                                provided to the Company as a result of a product purchase, warranty
                                registration, product service experience or other transactions.
                            </li>
                            <li>
                                <b>
                                    Targeted Advertising.
                                </b>
                                If you do not want us to use
                                information that we collect or that you provide to us to deliver advertisements
                                according to our advertisers' target-audience preferences, you can opt-out by
                                [OPT-OUT METHOD].
                            </li>
                        </ul>
                        <p>
                            We do not control
                            third parties' collection or use of your information to serve interest-based
                            advertising. However, these third parties may provide you with ways to choose
                            not to have your information collected or used in this way. You can opt out of
                            receiving targeted ads from members of the Network Advertising Initiative
                            ("<b>NAI</b>") on the NAI's website.
                        </p>
                        <p>
                            California residents
                            may have additional personal information rights and choices. Please see the "Your California Privacy Rights" section
                            below for more information.
                        </p>
                        <p>
                            Nevada residents who
                            wish to exercise their sale opt-out rights under Nevada Revised Statutes
                            Chapter 603A may submit a request to this designated address: [EMAIL
                            Address]. However, please know we do not
                            currently sell data triggering that statute's opt-out requirements.
                        </p>
                        <p>
                            <b>
                                <u>
                                    Third Parties
                                </u>
                            </b>
                        </p>
                        <p>
                            Our Website may
                            contain links to other websites, products, or services that we do not own or
                            operate. We are not responsible for the
                            privacy practices of these third parties.
                            Please be aware that this Privacy Policy does not apply to your
                            activities on these third-party services or any information you disclose to
                            these third parties. We encourage you to
                            read their privacy polices before providing any information to them.
                        </p>
                        <p>
                            <b>
                                <u>
                                    Accessing and Correcting
                                    Your Information
                                </u>
                            </b>
                        </p>
                        <p>
                            You can review and
                            change your personal information by logging into the Website and visiting your
                            account profile page.
                        </p>
                        <p>
                            You may also send us
                            an email at [EMAIL ADDRESS] to request access to, correct or delete any
                            personal information that you have provided to us. We may not be able to delete
                            your personal information except by also deleting your user account. We may not
                            accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.
                        </p>
                        <p>
                            If you delete your
                            User Contributions or any other content from the Website, copies of your User
                            Contributions may remain viewable in cached and archived pages, or might have
                            been copied or stored by other Website users and/or third parties. Proper access
                            and use of information provided on the Website, including User Contributions,
                            is governed by our terms of use [URL LINK TO WEBSITE'S TERMS OF USE].
                        </p>
                        <p>
                            California residents
                            may have additional personal information rights and choices. Please see [Your California Privacy Rights] for more
                            information.
                        </p>
                        <p>
                            <b>
                                <u>
                                    Your California
                                    Privacy Rights
                                </u>
                            </b>
                        </p>
                        <p>
                            If you are a
                            California resident, California law may provide you with additional rights
                            regarding our use of your personal information.
                        </p>
                        <p>
                            Other than as
                            disclosed in this privacy policy, the Website does not track users over time
                            and across third-party websites to provide targeted advertising.
                            Therefore, the Website does not operate any differently when it receives Do Not
                            Track ("<b>DNT</b>") signals from your internet web browser.
                        </p>
                        <p>
                            California Civil Code
                            Section � 1798.83 permits users of the Website that are California residents to
                            request certain information regarding our disclosure of personal information to
                            third parties for their direct marketing purposes.  To make such a request,
                            please send an email to [EMAIL], or write us at: [ADDRESS].
                        </p>
                        <p>
                            If you are a
                            California consumer, as defined by the California Consumer Privacy Act of 2018,
                            you may be afforded additional rights with respect to your "personal
                            information" as that term is explicitly defined under California law.  Any
                            personal information we collect is collected for the commercial purpose of
                            effectively providing our Website and services to you, as well as enabling you
                            to learn more about, and benefit from, our Website. You may exercise each of
                            your rights as identified below, subject to our verification of your identity.
                        </p>
                        <p>
                            <b>
                                Access.
                            </b>
                            You may email
                            us at [EMAIL] to request a copy of the personal information our databases
                            currently contain.
                        </p>
                        <p>
                            <b>
                                Prohibit Data
                                Sharing.
                            </b>
                            When applicable, you may prohibit the sharing of your personal information by
                            submitting a request via email to [EMAIL]. In your email, please explain how
                            you wish us to prohibit the sharing of your personal information, and which
                            categories of third parties you want to prohibit from receiving your personal
                            information.  When such prohibitions are not possible to provide our
                            Website and services to you, we will advise you accordingly.  You can then
                            choose to exercise any other rights under this Privacy Policy.
                        </p>
                        <p>
                            <b>
                                Portability.
                            </b>
                            Upon request
                            and when possible, we can provide you with copies of your personal
                            information.  You may submit a request via email to [EMAIL]. When such a
                            request cannot be honored, we will advise you accordingly.  You can then
                            choose to exercise any other rights under this privacy policy.
                        </p>
                        <p>
                            <b>
                                Deletion.
                            </b>
                            If you should
                            wish to cease use of our Website and have your personal information deleted
                            from our database, then you may submit a request by emailing us at [EMAIL].
                            Upon receipt of such a request for deletion, we will confirm receipt and will
                            confirm once your personal information has been deleted.  Where
                            applicable, we will ensure such changes are shared with trusted third parties.
                        </p>
                        <p>
                            <b>
                                We do not sell your
                                personal information.
                            </b>
                            If we ever decide to sell personal information, we will update you via this
                            Policy and include a link entitled "Do Not Sell My Personal Information," to
                            provide you with an opportunity to opt out of sales of your personal
                            information.
                        </p>
                        <p>
                            In addition, if a
                            California resident exercises his or her rights under California law, including
                            the CCPA, we shall not discriminate against that California resident by denying
                            our goods or Services, charging different prices or rates to similarly situated
                            consumers, providing a different level or quality of our goods or Services, or
                            taking any other adverse action.
                        </p>
                        <p>
                            <b>
                                <u>
                                    Data Security
                                </u>
                            </b>
                        </p>
                        <p>
                            We have implemented
                            commercially reasonable measures designed to secure your personal information
                            from accidental loss and from unauthorized access, use, alteration,
                            modification, and disclosure. All
                            information you provide to us is stored on our secure servers behind firewalls.
                            Any payment transactions and other information provided to us or our
                            third-party payment processor will be encrypted using SSL technology.
                        </p>
                        <p>
                            The safety and security
                            of your information also depends on you. Where we have given you (or where you
                            have chosen) a password for access to certain parts of our Website, you are
                            responsible for keeping this password confidential. We ask you not to share
                            your password with anyone. We urge you to be careful about giving out
                            information in public areas of the Website like message boards. The information
                            you share in public areas may be viewed by any user of the Website.
                        </p>
                        <p>
                            Unfortunately, the
                            transmission of information via the internet is not completely secure. Although
                            we do our best to protect your personal information, we cannot guarantee the
                            security of your personal information transmitted to our Website. Any
                            transmission of personal information is at your own risk. We are not
                            responsible for circumvention of any privacy settings or security measures
                            contained on the Website.
                        </p>
                        <p>
                            <b>
                                <u>
                                    Changes to Our
                                    Privacy Policy
                                </u>
                            </b>
                        </p>
                        <p>
                            If we make any
                            changes to this Policy, a revised Policy will be posted on this webpage and the
                            date of the change will be reported in the "Last Revised" block above. You can
                            get to this page from any of our webpages by clicking on the "Privacy Policy"
                            link.
                        </p>
                        <p>
                            <b>
                                <u>
                                    International
                                    Transfers of Data
                                </u>
                            </b>
                        </p>
                        <p>
                            The owner of the
                            Website is based in the State of Illinois in the United States and the Website
                            and our services are intended for users located within the United States. If you choose to use our Services from the
                            European Union or other regions of the world with laws governing data
                            collection and use that may differ from U.S. law, then please be aware that you
                            are transferring your personal information outside of those regions to the
                            United States for storage and processing.
                            We do not warrant or represent that this Policy or the use of your
                            personal information by the Website complies with the laws of any other
                            jurisdiction. By providing any
                            information, including personal information, on our Website or to our Company,
                            you consent to such transfer, storage, and processing and do so at your own
                            risk. You also acknowledge that your
                            consent is freely given, specific, and unambiguous.
                        </p>
                    </div>
                </div>
            </Col>
        </Row>

    );
};

export default TermsContent;
