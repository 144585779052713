import React, { FC, useEffect, useContext } from "react";

import { useHistory } from "react-router-dom";

import { Row, Col, Card } from "antd";

import { AppContext } from "../../context";

import image from "../../assets/image/logo.png";
interface ContactUsProps {}

const ContactUs: FC<ContactUsProps> = (
  props: ContactUsProps
) => {
  // const {} = props;

  return (
    <Row className="" gutter={8} justify="center" align="middle">
      <Col className="columns" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
        <Card className="login-card">
          <Row gutter={8}>
            <Col span={24} className="text-center">
              <div className="logo">
                <img src={image} alt={"logo"} />
              </div>
            </Col>
          </Row>
          <Row gutter={8}>
                      <Col span={24} className="text-center">

                          <p>
                              <b>
                                  <u>
                                      Contact Information
                </u>
                              </b>
                          </p>

                          <p>

                              We value your
                              opinions and welcome your feedback. To
                              ask questions or comment about this Privacy Policy and our privacy practices,
                              contact us at:

        </p>

                          <p>

                              [ADDRESS/EMAIL
                              ADDRESS]

        </p>

                          <p>

                              or via our toll-free
                              number:

        </p>

                          <p>
                              [TOLL-FREE NUMBER]]
        </p>                      </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default ContactUs;
