import React, { FC, useContext } from "react";
import { Layout } from "antd";

import AppRoutes from "./AppRoutes";
import AppFooter from "./AppFooter";

import { AppContext } from "../context";

const { Content } = Layout;

interface AppContentProps {}

const AppContent: FC = (props: AppContentProps) => {
  const { isAuthenticated } = useContext(AppContext);

  return (
    <>
      <Content
        className={isAuthenticated ? "main-content" : "main-content-public"}
      >
        <div className={isAuthenticated ? "main-inner-content" : ""}>
          <AppRoutes />
        </div>
        {isAuthenticated ? <AppFooter /> : null}
      </Content>
    </>
  );
};

export default AppContent;
