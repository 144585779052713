import React, { FC, useContext, useEffect, useState } from "react";

import { Row, Col, Radio, Divider, Card, Button, Spin, Modal, Alert } from "antd";

import { UnorderedListOutlined, IdcardOutlined } from "@ant-design/icons";

import { AppContext } from "../../context";

import CatalogCard from "./_partials/CatalogCard";
import { useHistory } from "react-router";

import { getAllMemberCatalogs, memberAccountGetPendingTransfers, getUserProfileById, completeTransfer } from "../../api/catalog";
// import CatalogSearchForm from "../../forms/CatalogSearchForm";
import Catalog from "../../models/Catalog";
import { preProcessFile } from "typescript";

interface CatalogsProps { }

const Catalogs: FC = (props: CatalogsProps) => {
  const {
    authToken,
    authenticatedUser,
    memberCatalogs,
    seachedCatalog,
    userData,
    setMemberCatalogs,
  } = useContext(AppContext);

  const [displayMode, setDisplayMode] = useState<string>("card");

  const [loading, setLoading] = useState<boolean>(false);
  const [subscriptionModal, setSubscriptionModal] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [pendingTransferData, setpendingTransferData] = useState<any>({});
  const [memberAccountOfTransferingUser, setMemberAccountOfTransferingUser] = useState<any>({});
  // const [pendingTransferCheck, setpendingTransferCheck] = useState<string>();

  useEffect(() => {
    if (authenticatedUser.disabled && authenticatedUser.trailExpired) {
      setSubscriptionModal(true);
      setTimeout(() => {
        history.push("/subscription");
      }, 2000);
    }
    (async () => {
      setLoading(true);
      const { data } = await getAllMemberCatalogs(
        authToken,
        authenticatedUser.id
      );
      setMemberCatalogs(data);
      setLoading(false);
      const dataa = await memberAccountGetPendingTransfers(
        authToken,
        authenticatedUser.id
      );
      setpendingTransferData(dataa.data[0]);
    })();
  }, []);

  useEffect(() => {
    if (pendingTransferData?.pendingTransfer) {
      (async () => {
        const userData = await getUserProfileById(
          authToken,
          pendingTransferData.ownersMemberAccountId
        );
        setMemberAccountOfTransferingUser(userData.data);
      })();
      setVisible(!visible)
    }
  }, [pendingTransferData]);
  const handleOk = async (values: any) => {
    history.push(`/catalogs/artifacts/receive/${pendingTransferData.id}`);
    setVisible(false);
    setConfirmLoading(false);
  };
  const handleCancel = () => {
    setConfirmLoading(!confirmLoading);
    setVisible(false);
  };
  const history = useHistory();

  return (
    <div className="catalog-wrapper">
      <Modal title="Your Account Expired" onCancel={() => setSubscriptionModal(false)} onOk={() => setSubscriptionModal(false)} visible={subscriptionModal}>
        <Alert
          message=""
          description="Please update your billing Information."
          type="warning"
          showIcon
        />
      </Modal>
      {/* <Row className='search-form'>
        <Col xl={12} lg={12} md={20} sm={24} xs={24}>
          <CatalogSearchForm  />
        </Col>
      </Row>
      <Divider /> */}
      {pendingTransferData ? (
        <Modal
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          confirmLoading={confirmLoading}
          okText="Next"
          cancelText="Cancel"
        >
          <div className="modal-content">
            <p>You've received  <br />  <b>Item: {pendingTransferData.name}  </b> <br /> from <br />
              <b>{memberAccountOfTransferingUser.firstName + " " + memberAccountOfTransferingUser.lastName}</b><br />
              Click "Next" to add to a Catalog
            </p>
          </div>
        </Modal>
      ) : null}
      <Row>
        <Col span={24}>
          <Button
            className="btn-primary"
            type="primary"
            onClick={() => {
              history.push("/catalogs/new");
            }}
          >
            +Catalog
          </Button>
          <Divider type="vertical" />
          <Radio.Group
            defaultValue={displayMode}
            onChange={(e) => {
              setDisplayMode(e.target.value);
            }}
            buttonStyle="solid"
          >
            <Radio.Button className="btn-primary" value="list">
              <UnorderedListOutlined />
            </Radio.Button>
            <Radio.Button className="btn-primary" value="card">
              <IdcardOutlined />
            </Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
      <Divider />
      {!loading && ((memberCatalogs && memberCatalogs.length > 0) ? (
        <Row gutter={8}>
          {memberCatalogs.map((memberCatalog: Catalog) => {
            return (
              <CatalogCard
                itemId={`catalog-${memberCatalog}`}
                renderAs={displayMode}
                catalog={memberCatalog}
              />
            );
          })}
        </Row>
      ) : (
        <Row gutter={8} justify="center" align="middle">
          <Col xs={24} sm={24} md={20} lg={12} xl={8}>
            <Card className="text-center">
              <h2>Welcome to Collectors Catalog</h2>
              <h3>Click "+Add" to add a new Catalog</h3>
            </Card>
          </Col>
        </Row>
      ))}

      {/* {seachedCatalog && seachedCatalog.length > 0 ? (
        <Row gutter={8}>
          {seachedCatalog.map((Catalog: Catalog) => {
            return (
              <CatalogCard
                itemId={`catalog-${Catalog}`}
                renderAs={displayMode}
                catalog={Catalog}
              />
            );
          })}
        </Row>
      ) : null} */}
      {/* 
      {seachedCatalog && memberCatalogs ? null : (
        <Row gutter={8} justify="center" align="middle">
          <Col xs={24} sm={24} md={20} lg={12} xl={8}>
            <Card className="text-center">
              <h2>Welcome to Collectors Catalog</h2>
              <h3>Click "+Add" to start your first Catalog</h3>
            </Card>
          </Col>
        </Row>
      )} */}

      {loading ? (
        <Row gutter={8} justify="center">
          <Col>
            <Spin />
          </Col>
        </Row>
      ) : null}
    </div>
  );
};

export default Catalogs;
