import React, { FC, useContext, useEffect } from "react";

import { useHistory } from "react-router-dom";

import { Row, Col, Card } from "antd";

import { Link } from "react-router-dom";

import ResetPasswordForm from "../../forms/ResetPasswordForm";
import image from "../../assets/image/logo.png";
import { AppContext } from "../../context";
import { t } from "../../utils/helpers";

interface ResetPasswordProps { }

const ResetPassword: FC<ResetPasswordProps> = (
    props: ResetPasswordProps
) => {
    // const {} = props;

    const history = useHistory();

    const { authenticatedUser } = useContext(AppContext);

    useEffect(() => {
        if (authenticatedUser) {
            history.push("/catalogs");
        }
    }, [authenticatedUser]);

    return (
        <Row gutter={8} className="loginForm" justify="center" align="middle">
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                <Card className="forgot-password-card login-card">
                    <Row gutter={8}>
                        <Col span={24} className="text-center">
                            <div className="logo">
                                <img src={image} alt={"logo"} />
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={24} className="text-center">
                            <ResetPasswordForm />
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={24} className="text-center">
                            <p className="back-to-login-text">
                                {t("Back to")} <Link to={t("/login")}>{t("Login")}</Link>
                            </p>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    );
};

export default ResetPassword;
