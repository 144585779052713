import React, { FC, useContext, useEffect, useState } from "react";

import { Row, Col, Modal, Alert } from "antd";

import CatalogForm from "../../forms/CatalogForm";
import { AppContext } from "../../context";
import { useHistory } from "react-router";

interface CreateCatalogProps { }

const CreateCatalog: FC = (props: CreateCatalogProps) => {
  const [subscriptionModal, setSubscriptionModal] = useState<boolean>(false);
  const {
    authenticatedUser,
  } = useContext(AppContext);
  const history = useHistory();
  useEffect(() => {
    if (authenticatedUser.disabled && authenticatedUser.trailExpired) {
      setSubscriptionModal(true);
      setTimeout(() => {
        history.push("/subscription");
      }, 2000);
    }
  }, [])

  return (
    <Row gutter={8}>
      <Modal title="Your Account Expired" onCancel={() => setSubscriptionModal(false)} onOk={() => setSubscriptionModal(false)} visible={subscriptionModal}>
        <Alert
          message=""
          description="Please update your billing Information."
          type="warning"
          showIcon
        />
      </Modal>
      <Col span={24}>
        <CatalogForm />
      </Col>
    </Row>
  );
};

export default CreateCatalog;
