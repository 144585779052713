import React, { FC, useEffect, useContext } from "react";

import { useHistory } from "react-router-dom";

import { Row, Col, Card } from "antd";

import { AppContext } from "../../context";

import image from "../../assets/image/logo.png";
interface FaqsProps {}

const Faqs: FC<FaqsProps> = (
  props: FaqsProps
) => {
  // const {} = props;

  return (
    <Row className="" gutter={8} justify="center" align="middle">
      <Col className="columns" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
        <Card className="login-card">
          <Row gutter={8}>
            <Col span={24} className="text-center">
              <div className="logo">
                <img src={image} alt={"logo"} />
              </div>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24} >
            <h3>Why should I catalog my collectibles?</h3><p>
Collectors are most often interested in the history of an item as much as the actual item. Cataloging your items will help keep the history attached to the items. 

</p><h3>Can I purchase items from collectors listed?</h3><p>
You can contact another member of Collector’s Catalogs and try and reach a deal through private interaction. 

</p><h3>What items should I catalog?</h3><p> 
Any item has the potential to be cataloged.  Some of the most common items might have historical significance that set that item apart from its similar items.   Connecting the story to family heirlooms are great examples of this. 

</p><h3>Who can see my catalog?</h3><p>
You have the option to share your catalog and the entries, or you can keep all your entries private and no one else can see your collection. 
 
</p><h3>How does cataloging my collection help me?</h3><p>
It allows the collector to keep all the information in one central location.  This allows you to share it with family or your insurance company. 
</p>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default Faqs;
