import React, { FC, useContext, useEffect, useState } from "react";

import { Divider, Button, Row, Col, Card, Popconfirm, Typography } from "antd";
import User from "../../../models/User";
import { AppContext } from "../../../context";
import { useHistory } from "react-router";
import { EditOutlined } from "@ant-design/icons";

import DefaultUserProfilePicture from "../../../assets/image/default-user-profile-image.png";

interface UserCard {
  renderAs?: string;
}

const UserCard: FC<UserCard> = () => {
  const [duplicateLoading, setduplicateLoading] = useState<boolean>(false);
  const { userData } = useContext(AppContext);
  const { Title } = Typography;
  const [loading, setLoading] = useState<boolean>(false);
  const [profileImage, setProfileImage] = useState<string>("");
  const history = useHistory();
  useEffect(() => {
    setProfileImage(userData.profileImage);
  }, [profileImage]);
  return (
    <>
      <Row gutter={12}>
        <Col xl={4} lg={4} md={6} sm={10} xs={12}>
          <div className={"user-profile-picture"}>
            <img
              src={profileImage ? profileImage : DefaultUserProfilePicture}
            />
          </div>
        </Col>
        <Col xl={20} lg={20} md={18} sm={14} xs={12}>
          <Row gutter={12}>
            <Col xl={8} lg={8} md={8} sm={12} xs={24}>
              <Title level={4}>First Name</Title>
              <p style={{ minHeight: 24 }}>
                {userData.firstName ? userData.firstName : " "}
              </p>
            </Col>
            <Col xl={8} lg={8} md={8} sm={12} xs={24}>
              <Title level={4}>Last Name</Title>
              <p style={{ minHeight: 24 }}>
                {userData.lastName ? userData.lastName : " "}
              </p>
            </Col>
            <Col xl={8} lg={8} md={8} sm={12} xs={24}>
              <Title level={4}>Email</Title>
              <p style={{ minHeight: 24 }}>
                {userData.email ? userData.email : " "}
              </p>
            </Col>
            <Col xl={8} lg={8} md={8} sm={12} xs={24}>
              <Title level={4}>Phone Number</Title>
              <p style={{ minHeight: 24 }}>
                {userData.phoneNumber ? userData.phoneNumber : " "}
              </p>
            </Col>
            <Col xl={8} lg={8} md={8} sm={12} xs={24}>
              <Title level={4}>Address Line 1</Title>
              <p style={{ minHeight: 24 }}>
                {userData.addressLine1 ? userData.addressLine1 : " "}
              </p>
            </Col>
            <Col xl={8} lg={8} md={8} sm={12} xs={24}>
              <Title level={4}>Address Line 2</Title>
              <p style={{ minHeight: 24 }}>
                {userData.addressLine2 ? userData.addressLine2 : " "}
              </p>
            </Col>
            <Col xl={8} lg={8} md={8} sm={12} xs={24}>
              <Title level={4}>City</Title>
              <p style={{ minHeight: 24 }}>
                {userData.city ? userData.city : " "}
              </p>
            </Col>
            <Col xl={8} lg={8} md={8} sm={12} xs={24}>
              <Title level={4}>State</Title>
              <p style={{ minHeight: 24 }}>
                {userData.state ? userData.state : " "}
              </p>
            </Col>
            <Col xl={8} lg={8} md={8} sm={12} xs={24}>
              <Title level={4}>Zip Code</Title>
              <p style={{ minHeight: 24 }}>
                {userData.zipCode ? userData.zipCode : " "}
              </p>
            </Col>            
          </Row>
          <Divider />
          <Row gutter={12}>
            <Col span={24}>
              <Button
                className="btn-primary"
                style={{ marginBottom: "20px" }}
                onClick={() => {
                  history.push(`/my-profile/edit`);
                }}
              >
                <EditOutlined /> Edit My Profile
              </Button>
              &nbsp;
              <Button
                className="btn-primary"
                onClick={() => {
                  history.goBack();
                }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default UserCard;
