import React, { FC, useEffect, useContext } from "react";

import { useHistory } from "react-router-dom";

import { Row, Col, Card } from "antd";

import { AppContext } from "../../context";

import image from "../../assets/image/logo.png";
import TermsContent from "./Partials/termsContent";
interface TermsAndConditionsProps { }

const TermsAndConditions: FC<TermsAndConditionsProps> = (
    props: TermsAndConditionsProps
) => {
    // const {} = props;

    return (
        <Row className="" gutter={8} justify="center" align="middle">
            <Col className="columns" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                <Card className="login-card">
                    <Row gutter={8}>
                        <Col span={24} className="text-center">
                            <div className="logo">
                                <img src={image} alt={"logo"} />
                            </div>
                        </Col>
                    </Row>
                    <TermsContent />
                </Card>
            </Col>
        </Row>
    );
};

export default TermsAndConditions;
