import React, { FC, useContext } from "react";

import Helmet from "react-helmet";

import { AppContext } from "../context";

interface AppMetaProps {}

const AppMeta: FC = (props: AppMetaProps) => {
  const {
    metaTitle,
    metaDescription,
    metaKeywords,
    metaAuthor,
    metaUrl,
    metaImageUrl,
    metaImageAltTxt,
    metaSiteName,
    metaTwitterSite,
    metaFbAppId,
  } = useContext(AppContext);

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
        <meta name="author" content={metaAuthor} />

        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={metaImageUrl} />
        <meta property="og:url" content={metaUrl} />
        <meta property="og:site_name" content={metaSiteName} />

        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={metaImageUrl} />
        <meta name="twitter:card" content={metaImageUrl} />
        <meta name="twitter:image:alt" content={metaImageAltTxt} />
        <meta name="twitter:site" content={metaTwitterSite} />

        <meta property="fb:app_id" content={metaFbAppId} />
      </Helmet>
    </>
  );
};

export default AppMeta;
