import React, { FC, useEffect, useContext } from "react";

import { useHistory } from "react-router-dom";

import { Row, Col, Card } from "antd";

import { AppContext } from "../../context";

import image from "../../assets/image/logo.png";
interface AboutProps {}

const About: FC<AboutProps> = (
  props: AboutProps
) => {
  // const {} = props;

  return (
    <Row className="" gutter={8} justify="center" align="middle">
      <Col className="columns" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
        <Card className="login-card">
          <Row gutter={8}>
            <Col span={24} className="text-center">
              <div className="logo">
                <img src={image} alt={"logo"} />
              </div>
            </Col>
          </Row>
          <Row gutter={8}>
                      <Col span={24} className="text-center">

                          <p >My name is Mitch Lewis, and to be completely honest, I do
                          not remember a period in my life when I was not collecting artifacts. I found
                          my first arrowhead on our farm when I was 4-5 years old, walking the fields
                          with my grandfather. He was always encouraging and always willing to take time
                          to teach me something new about the artifacts that he had collected. Then, I
                          started reading everything I could on different artifacts and the collectors
that had assembled their own collections over the last 100 years.</p>

                          <p >I read hundreds of books on artifacts, and I would try to
                          remember each and every piece so I could try to find them at the shows I went
                          to later on. I would go to a show and ask seasoned lifelong collectors, "Was
                          this point owned by this collector?" or, "Is this piece pictured in this book?"
                          Sometimes the answer was, "Yes, it is, and how did you know that?" Others would
                          comment, "I do not know, I didn't get any history when I bought it." Answers
                          like the latter drove me to study more and try to find more information. I was
                          having fun being a 10-12-year-old talking with collectors 40-60 years older than
me, and they were treating me like a seasoned collector.</p>

                          <p >As time went on, people started asking me about pieces and
                          collectors: if I knew where a person's collections were pictured, did I
                          recognize the writing on a piece, and countless other questions. Most of the
                          time, I could help. However, I started to realize that some of the information about
                          the pieces was getting lost along the way as they traded hands and traveled. This
                          is where the idea for Collector's Corner came from: a more convenient way to
                          catalog the pedigree of pieces, allowing the history of each piece to travel easier
                          with it. We as collectors spend countless hours acquiring pieces, but keeping
records in a consistent manner is a challenge. </p>

                          <p >Another issue I saw was when a collector passes away, all
                          the information that they have built through their lifetime goes with them and is
                          most likely lost for good. Of course, we love to talk about our collections
                          with other collectors, but it's rare that a collector's passion is shared by
                          someone else within their family. Most often, other collectors know more about
                          the collection than that collector's children or spouse. This can put the
                          family at a disadvantage and leave a worry with a collector's heirs after they
pass.</p>

                          <p >I love collecting, and hopefully this website and app will
allow people to build their own legacy and leave a pedigree to every piece. </p>

                      </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default About;
