import React, { FC, useContext, useEffect, useState } from "react";

import { Row, Col, Radio, RadioChangeEvent, Modal, message, Button, Alert } from "antd";

import { useParams, useHistory } from "react-router-dom";
import { getArtifactById, updateArtifact, completeTransfer } from "../../api/catalog";
import { AppContext } from "../../context";
import Artifact from "../../models/Artifact";
interface ReceiveArtifactProps { }

const ReceiveArtifact: FC = (props: ReceiveArtifactProps) => {
  //@ts-ignoreF
  const { authToken, memberCatalogs, authenticatedUser } = useContext(AppContext);
  const [visible, setVisible] = useState(false);
  const [transferModalVisible, setTransferModalVisible] = useState(false);
  const [subscriptionModal, setSubscriptionModal] = useState<boolean>(false);
  const [dataMemberValue, setDataMemberValue] = useState<[]>();
  const [selectedCatalogObject, setSelectedCatalogObject] = useState<any>({});
  const [transferedArtifactObject, setTransferedArtifactObject] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const { transferedArtifactId } = useParams<any>();
  const history = useHistory();
  useEffect(() => {
    if (authenticatedUser.disabled && authenticatedUser.trailExpired) {
      setSubscriptionModal(true);
      setTimeout(() => {
        history.push("/subscription");
      }, 2000);
    }
    // (async () => {    
    if (memberCatalogs.length == 0) {
      setVisible(true);
    }
  }, []);

  useEffect(() => {
    (async () => {
      const { data } = await getArtifactById(authToken, transferedArtifactId);
      setTransferedArtifactObject(data);
    })();
  }, [transferedArtifactId]);

  const handleOk = () => {
    history.push('/catalogs/new');
  }

  const handleCancel = () => {
    history.push('/catalogs');
  }
  const handleTransferOk = async () => {
    const {
      // coverPhotoUrl,
      name,
      description,
      finder,
      location,
      material,
      collectorHistory,
      notes,
    } = transferedArtifactObject;
    const artifactData = {
      name: name,
      // artifactImages: [""],
      description: description,
      finder: finder,
      material: material,
      collectorHistory: collectorHistory,
      notes: notes,
      location: location,
      ownersMemberAccountId: authenticatedUser.id,
      memberCatalogId: selectedCatalogObject.id,
    };
    const updateReq = await updateArtifact(
      authToken,
      transferedArtifactId as string,
      { ...artifactData, id: transferedArtifactId } as Artifact
    );
    setTransferModalVisible(false);
    const complete = completeTransfer(authToken, transferedArtifactId, authenticatedUser.id);
    history.push('/catalogs');
  }

  const handleTransferCancle = () => {
    history.push('/catalogs');
  }

  useEffect(() => {
    if (typeof (selectedCatalogObject.id) != "undefined") {
      setTransferModalVisible(true);
    }
  }, [JSON.stringify(selectedCatalogObject)]);

  const onChange = async (e: RadioChangeEvent) => {
    setDataMemberValue(e.target.value);
    const memberObject = memberCatalogs.filter((element: { id: any; }) => {
      return element.id == e.target.value;
    })[0]
    setSelectedCatalogObject(memberObject);
  };
  return (
    <>
      <Modal title="Your Account Expired" onCancel={() => setSubscriptionModal(false)} onOk={() => setSubscriptionModal(false)} visible={subscriptionModal}>
        <Alert
          message=""
          description="Please update your billing Information."
          type="warning"
          showIcon
        />
      </Modal>
      <Modal
        visible={visible}
        onOk={handleOk}
        okText="Add Catalog"
        cancelText="Cancel"
        onCancel={handleCancel}
      // confirmLoading={confirmLoading}
      >
        <div className="modal-content">
          <p>
            You don't any catalog to choose. Please add first.
          </p>
        </div>
      </Modal>
      <Modal
        visible={transferModalVisible}
        onOk={handleTransferOk}
        okText="Ok"
        cancelText="Cancel"
        onCancel={handleTransferCancle}
      // confirmLoading={confirmLoading}
      >
        <div className="modal-content">
          <p>
            You'r adding <br /> <b>Item: {transferedArtifactObject ? transferedArtifactObject.name : null} </b><br />
            to <br />
            {selectedCatalogObject?.name}
          </p>
        </div>
      </Modal>
      <Row gutter={8}>
        <Col span={24}>
          <Radio.Group onChange={onChange} className='audio-group-wrapper' value={dataMemberValue}>
            {memberCatalogs
              ? memberCatalogs.map((Catalog: any) => {
                const value = Catalog.id;
                return (
                  <Radio
                    key={Catalog.id}
                    className="checbox-wrapper "
                    // onChange={(e) => onChange(e)}
                    value={value}
                  >
                    <img className="image" src={Catalog.coverPhotoUrl ? Catalog.coverPhotoUrl : "https://via.placeholder.com/100"} />
                    <div className="name">
                      {Catalog.name}
                    </div>
                  </Radio>
                );
              })
              : null}
          </Radio.Group>
        </Col>
      </Row>
    </>
  );
};

export default ReceiveArtifact;
