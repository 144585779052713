import React, { FC, useContext, useEffect, useState } from "react";

import { Row, Col, Spin, Alert, Modal } from "antd";

import CatalogForm from "../../forms/CatalogForm";
import { useHistory, useParams } from "react-router-dom";

import { getCatalogById } from "../../api/catalog";
import { AppContext } from "../../context";
import Catalog from "../../models/Catalog";

interface EditCatalogProps { }

const EditCatalog: FC = (props: EditCatalogProps) => {
  //@ts-ignore
  const { catalogId } = useParams();
  const history = useHistory();
  const [subscriptionModal, setSubscriptionModal] = useState<boolean>(false);
  const { authToken, authenticatedUser } = useContext(AppContext);

  const [catalog, setCatalog] = useState<Catalog | null>(null);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (authenticatedUser.disabled && authenticatedUser.trailExpired) {
      setSubscriptionModal(true);
      setTimeout(() => {
        history.push("/subscription");
      }, 2000);
    }
    (async () => {
      setLoading(true);
      const { data } = await getCatalogById(authToken, catalogId);
      setCatalog(data);
      setLoading(false);
    })();
  }, []);

  return (
    <Row gutter={8}>
      <Modal title="Your Account Expired" onCancel={() => setSubscriptionModal(false)} onOk={() => setSubscriptionModal(false)} visible={subscriptionModal}>
        <Alert
          message=""
          description="Please update your billing Information."
          type="warning"
          showIcon
        />
      </Modal>
      <Col span={24}>
        {loading ? (
          <>
            <Spin size="large" />
          </>
        ) : (
          <>
            <CatalogForm catalog={catalog} />
          </>
        )}
      </Col>
    </Row>
  );
};

export default EditCatalog;
