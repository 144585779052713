import React, { FC, useContext, useEffect, useState } from "react";

import {
    Form,
    Input,
    Button,
    message,
    Row,
    Popconfirm,
    Col,
    Upload,
    Modal,
    Radio,
    Spin,
} from "antd";
import { useParams, useHistory } from "react-router-dom";
import { getArtifactById } from "../../api/catalog";
import { AppContext } from "../../context";
import Artifact from "../../models/Artifact";
import { useForm } from "antd/lib/form/Form";
interface ArtifactCollectionProps { }

const ArtifactCollection: FC = (props: ArtifactCollectionProps) => {
    const [form] = useForm();
    //@ts-ignoreF
    const { authToken, authenticatedUser } = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const { artifactId } = useParams<any>();
    const [collectionList, setCollectionList] = useState<any[]>([]);
    const [collection, setCollection] = useState<any[]>([]);
    useEffect(() => {
        (async () => {
            const dataa = [];
            setLoading(true);
            const { data } = await getArtifactById(authToken, artifactId);
            dataa.push(data);
            setCollectionList(dataa);
            setLoading(false);
        })();
    }, [artifactId]);
    useEffect(() => {
        setCollection(collectionList);
    }, [collectionList])
    return (
        <>
            <Row gutter={15} align="middle" justify="center">
                <Col span={24}>
                    <Form
                        form={form}
                        name="catalog-form"
                        layout="vertical"
                    >
                        {collection ?
                            collection.map((list: Artifact) => {
                                var images = list.artifactImages;
                                return (
                                    <Row gutter={20} style={{ marginTop: "50px" }} justify="center" align="middle" >
                                        <Col span={20}>
                                            <Form.Item
                                                label={"Name"}
                                            >
                                                <Input className={"input-primary"} value={list.name} placeholder={("Name")} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={20}>
                                            <Form.Item
                                                label={"Description"}
                                            >
                                                <Input className={"input-primary"} value={list.description} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={20}>
                                            <Form.Item
                                                label={"Finder"}
                                            >
                                                <Input className={"input-primary"} value={list.finder} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={20}>
                                            <Form.Item
                                                label={"Location"}
                                            >
                                                <Input className={"input-primary"} value={list.location} />
                                            </Form.Item>
                                        </Col>
                                        {/* <Col span={20}>
                                            <Form.Item
                                                label={"Material"}
                                            >
                                                <Input className={"input-primary"} value={list.material ? list.material : ""} />
                                            </Form.Item>
                                        </Col> */}
                                        <Col span={20}>
                                            <Form.Item
                                                label={"Size"}
                                            >
                                                <Input className={"input-primary"} value={list.size} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={20}>
                                            <Form.Item
                                                label={"Collection History"}
                                            >
                                                <Input className={"input-primary"} value={list.collectorHistory} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={20}>
                                            <Form.Item
                                                label={"Notes"}
                                            >
                                                <Input.TextArea rows={5} className={"input-primary"} value={list.notes} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={20}>
                                            {
                                                images?.map((img) => {
                                                    return (
                                                        <div className="imagee-holder">
                                                            <img src={img.photoUrl} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Col>

                                    </Row>
                                );
                            }
                            )
                            : null}
                    </Form>
                </Col>
                <Col span={20} style={{ marginTop: "25px" }} >
                    <Button
                        className="btn-primary"
                        type="link"
                        htmlType="button"
                        onClick={() => {
                            history.goBack();
                        }}
                    >Back</Button>
                </Col>
            </Row>
        </>
    );
};

export default ArtifactCollection;
