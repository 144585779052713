import React, { FC, useContext, useEffect, useState } from "react";

import { Form, Input, Button, message, Row, Col, Upload, Radio } from "antd";

import { useHistory, Link } from "react-router-dom";

import { AppContext } from "../context";

import { useForm } from "antd/lib/form/Form";

import { SearchOutlined, SendOutlined } from "@ant-design/icons";

import { searchCatalogByText } from "../api/catalog";

import { set } from "lodash";
// interface CatalogSearchFormProps {
//   catalog?: null | null;
// }

const CatalogSearchForm: FC<any> = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const [form] = useForm();

  const history = useHistory();

  const { authToken, authenticatedUser, setMemberCatalogs, setSeachedCatalog } =
    useContext(AppContext);

  const onFinish = async (values: any) => {
    const { search } = values;    
    try {
      setLoading(true);

      const createReq = await searchCatalogByText(
        authToken,
        search,
        authenticatedUser.id
      );
      // setSeachedCatalog(createReq.data);
      setMemberCatalogs(createReq.data);
      setLoading(false);
      //   history.push("/catalogs");
    } catch (err) {      
      message.error("Invalid Username and Password Combination!");
      setLoading(false);
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      form={form}
      name="catalog-search-form"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      layout="horizontal"
    >
      <Form.Item name="search">
        <Input className={"input-primary"} placeholder={"Search"} />
      </Form.Item>
      <Form.Item className="submit-btn">
        <Button className="btn-primary" type="link" htmlType="submit">
          <span className="send">
            <SearchOutlined />
          </span>
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CatalogSearchForm;
