import React, { FC, useContext, useEffect, useState } from "react";

import {
  Form,
  Input,
  Button,
  message,
  Row,
  Col,
  Upload,
  Radio,
  Popconfirm,
  Modal,
} from "antd";
import { FacebookShareButton }
  from "react-share";
import { useHistory, Link } from "react-router-dom";

import { AppContext } from "../context";

import { getBase64, t } from "../utils/helpers";

import { useForm } from "antd/lib/form/Form";

import { UploadOutlined, EditOutlined, CopyOutlined } from "@ant-design/icons";

import {
  createCatalog,
  updateCatalog,
  duplicateCatalog,
  getAllMemberCatalogs,
  postCatalogImage,
  updateCatalogImage,
  deleteCatalogById,
  getLinkCatalog,
  getExportLinkCatalog,
} from "../api/catalog";
import Catalog from "../models/Catalog";

import { set } from "lodash";

interface CatalogFormProps {
  catalog?: Catalog | null;
}
function countDown() {
  let secondsToGo = 5;
  const modal = Modal.success({
    title: "Link has been copied to your clipboard",
  });
  const timer = setInterval(() => {
    secondsToGo -= 1;
  }, 1000);
  setTimeout(() => {
    clearInterval(timer);
    modal.destroy();
  }, secondsToGo * 1000);
}
const CatalogForm: FC<CatalogFormProps> = (props: CatalogFormProps) => {
  const { catalog } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [duplicateLoading, setduplicateLoading] = useState<boolean>(false);
  const [getLinkLoading, setgetLinkLoading] = useState<boolean>(false);
  const [exportLinkLoading, setExportLinkLoading] = useState<boolean>(false);
  const [catalogLink, setCatalogLink] = useState<any>(null);
  const [transferOwnershipLoading, setTransferOwnershipLoading] =
    useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string | null | void>(null);

  const [fileType, setFileType] = useState<any>("");

  const [fileExt, setFileExt] = useState<string>(".jpg");

  const [originalFileObj, setOriginalFileObj] = useState<Blob | null>(null);

  const [form] = useForm();

  const history = useHistory();

  const { authToken, authenticatedUser, setMemberCatalogs } =
    useContext(AppContext);

  useEffect(() => {
    console.log('image url ', imageUrl)
    setImageUrl(imageUrl);
  }, [imageUrl]);
  useEffect(() => {
    setImageUrl(catalog?.coverPhotoUrl);
    (async () => {
      const catalogShareLink = await getLinkCatalog(
        authToken,
        catalog?.id as string
      );
      setCatalogLink(catalogShareLink.data);
    })();
  }, [catalog]);

  const onFinish = async (values: any) => {
    const { coverPhotoUrl, name, description1, description2, notes, isPublic } =
      values;
    console.log('coverPhotoUrl ', coverPhotoUrl)
    const catalogData = {
      name: name,
      description1: description1,
      description2: description2,
      notes: notes,
      isPublic: isPublic,
      ownersMemberAccountId: authenticatedUser.id,
    };
    try {
      setLoading(true);

      if (catalog) {

        const updateReq = await updateCatalog(
          authToken,
          catalog.id as string,
          { ...catalogData, id: catalog.id } as Catalog
        );

        // const uploadImage = await updateCatalogImage(
        //   authToken,
        //   catalog.id as string,
        //   coverPhotoUrl.replace(/^data:image\/[a-z]+;base64,/, "")
        // );
        if (coverPhotoUrl) {
          if (coverPhotoUrl.includes("data:image/png;base64")) {
            console.log('coverPhotoUrl.includes("data:image/png;base64") ', coverPhotoUrl.includes("data:image/png;base64"))
            await postCatalogImage(
              authToken,
              catalog.id as string,
              coverPhotoUrl.replace(/^data:image\/[a-z]+;base64,/, "")
            );
          } else {
            const base64Image = btoa(coverPhotoUrl);
            console.log('base64Image ', base64Image)
            await postCatalogImage(
              authToken,
              catalog.id as string,
              base64Image
              // coverPhotoUrl.replace(/^data:image\/[a-z]+;base64,/, "")
            );
          }
        }
      } else {
        const createReq = await createCatalog(
          authToken,
          catalogData as Catalog
        );
        const newCreatedCatalogId = createReq.data.id;
        if (coverPhotoUrl) {
          await postCatalogImage(
            authToken,
            newCreatedCatalogId,
            coverPhotoUrl.replace(/^data:image\/[a-z]+;base64,/, "")
          );
        }
      }
      setLoading(false);
      history.push("/catalogs");
    } catch (err) {
      message.error("Invalid Username and Password Combination!");
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
  };

  return (
    <div className="catalog-form-fields-wrapper">
      <Form>
        <Row gutter={8}>
          <Col span={24}>
            <Form.Item>
              <Upload
                accept={"image/*"}
                multiple={false}
                className={"poster-upload"}
                showUploadList={false}
                onChange={async (info: any) => {
                  const originFileObj = info.file.originFileObj;
                  const isBlob = originFileObj instanceof Blob;

                  if (isBlob === true) {
                    console.log('originFileObj ', originFileObj)
                    getBase64(originFileObj as File, (data) => {
                      console.log('data.toString() ', data.toString())
                      setImageUrl(data.toString());
                      form.setFieldsValue({ coverPhotoUrl: data.toString() });
                    });
                  }
                  setFileType(info.file.type);
                }}
              >
                <div className={"poster_bg"}>
                  {imageUrl ? (
                    <img src={imageUrl} alt="avatar" />
                  ) :
                    null}
                  {catalog ? <EditOutlined /> : <UploadOutlined />}
                </div>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Form
        form={form}
        name="catalog-form"
        initialValues={catalog ? catalog : {}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
      >
        <Row gutter={8}>
          <Col span={24}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: t("Name is required!"),
                },
              ]}
            >
              <Input className={"input-primary"} placeholder={t("Name")} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={24}>
            <Form.Item label="Description Line 1" name="description1">
              <Input
                className={"input-primary"}
                placeholder={t("Description Line 1")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={24}>
            <Form.Item label="Description Line 2" name="description2">
              <Input
                className={"input-primary"}
                placeholder={t("Description Line 2")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={24}>
            <Form.Item label="Notes" name="notes">
              <Input.TextArea
                rows={5}
                className={"input-primary"}
                placeholder={t("Notes")}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={24}>
            <Form.Item
              label="Visibility"
              name="isPublic"
              rules={[
                {
                  required: true,
                  message: t("You must select visibility!"),
                },
              ]}
            >
              <Radio.Group buttonStyle="solid">
                <Radio.Button value={true}>Public</Radio.Button>
                <Radio.Button value={false}>Private</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={24}>
            <Form.Item name="coverPhotoUrl" style={{ display: "none" }}>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={24}>
            <>
              <Button
                disabled={catalog ? false : true}
                style={{ display: "block", marginBottom: "20px" }}
                loading={duplicateLoading}
                onClick={async () => {
                  // history.push(`/catalogs/edit/${catalog.id}`);
                  setduplicateLoading(true);
                  if (catalog) {
                    try {
                      await duplicateCatalog(authToken, catalog.id as string);
                      const { data } = await getAllMemberCatalogs(
                        authToken,
                        authenticatedUser.id
                      );
                      setMemberCatalogs(data);
                      setduplicateLoading(false);
                      history.push(`/catalogs/`);
                      // history.goBack();
                    } catch (error) {
                      setduplicateLoading(false);
                    }
                  }
                }}
              >
                Duplicate
              </Button>

              <Button
                disabled={catalog ? false : true}
                style={{ display: "block", marginBottom: "20px" }}
                loading={getLinkLoading}
                onClick={async () => {
                  setgetLinkLoading(true);
                  if (catalog) {
                    try {
                      const catalogShareLink = await getLinkCatalog(
                        authToken,
                        catalog.id as string
                      );
                      setCatalogLink(catalogShareLink.data);
                      const { data } = await getAllMemberCatalogs(
                        authToken,
                        authenticatedUser.id
                      );

                      if (catalogShareLink.data)
                        navigator.clipboard.writeText(catalogShareLink.data);

                      Modal.success({
                        title: "Link has been copied to your clipboard",
                      });
                      setMemberCatalogs(data);
                      setgetLinkLoading(false);
                    } catch (error) {
                      setgetLinkLoading(false);
                    }
                  }
                }}
              >
                Share Link
              </Button>
              <FacebookShareButton quote={catalog?.name} hashtag={catalog?.name} url={catalogLink ? catalogLink : ""} >
                <Button
                  style={{ display: "block", marginBottom: "20px" }}
                >
                  Share to Facebook
                </Button>
              </FacebookShareButton>

              <Button
                disabled={catalog ? false : true}
                style={{ display: "block", marginBottom: "20px" }}
                loading={exportLinkLoading}
                onClick={async () => {
                  // history.push(`/catalogs/edit/${catalog.id}`);
                  setExportLinkLoading(true);
                  if (catalog) {
                    try {
                      const exportLink = await getExportLinkCatalog(
                        authToken,
                        catalog.id as string
                      );
                      window.open(exportLink.data, "_blank");
                      const { data } = await getAllMemberCatalogs(
                        authToken,
                        authenticatedUser.id
                      );
                      setMemberCatalogs(data);
                      setExportLinkLoading(false);
                    } catch (error) {
                      setExportLinkLoading(false);
                    }
                  }
                }}
              >
                Export To XLS
              </Button>

              {catalog ? (
                <Popconfirm
                  style={{ display: "block", marginBottom: "20px" }}
                  title="Are you sure you want to delete?"
                  okText={"Yes"}
                  cancelText={"No"}
                  onConfirm={async () => {
                    setDeleteLoading(true);
                    if (catalog) {
                      await deleteCatalogById(authToken, catalog.id as string);
                      const { data } = await getAllMemberCatalogs(
                        authToken,
                        authenticatedUser.id
                      );
                      setMemberCatalogs(data);
                      history.push(`/catalogs/`);
                    }
                    setDeleteLoading(false);
                  }}
                  onCancel={() => { }}
                >
                  <Button
                    disabled={catalog ? false : true}
                    style={{ display: "block", marginBottom: "20px" }}
                    loading={deleteLoading}
                  >
                    Delete
                  </Button>
                </Popconfirm>
              ) : (
                <Button
                  disabled={catalog ? false : true}
                  style={{ display: "block", marginBottom: "20px" }}
                  loading={deleteLoading}
                >
                  Delete
                </Button>
              )}
            </>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={24}>
            <Button
              className="btn-primary"
              type="link"
              htmlType="submit"
              loading={loading}
            >
              {t("Save")}
            </Button>
            &nbsp;
            <Button
              className="btn-primary"
              type="link"
              htmlType="button"
              onClick={() => {
                history.goBack();
              }}
            >
              {t("Cancel")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CatalogForm;
