import React from "react";
import ReactDOM from "react-dom";

import { ConfigProvider } from "antd";

import { BrowserRouter as Router } from "react-router-dom";

import "./assets/app.default.css";
import "./assets/app.responsive.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import frFR from "antd/lib/locale/fr_FR";
import nbNO from "antd/lib/locale/nb_NO";
import enUS from "antd/lib/locale/en_US";
import enGB from "antd/lib/locale/en_GB";

import { ContextProvider } from "./context";
import {
  getLocalStorageItem,
  //setLocalStorageItem,
  DirectionType,
} from "./utils/helpers";

//setLocalStorageItem("collectible_artifacts_locale", "en_US", false);
const locale = getLocalStorageItem("collectible_artifacts_locale");

let lang = null;
let dir = null;
switch (locale) {
  case "fr_FR":
    lang = frFR;
    dir = "ltr";
    break;
  case "nb_NO":
    lang = nbNO;
    dir = "ltr";
    break;
  case "en_GB":
    lang = enGB;
    dir = "ltr";
    break;
  case "en_US":
  default:
    lang = enUS;
    dir = "ltr";
    break;
}

ReactDOM.render(
  <React.StrictMode>
    <ContextProvider>
      <ConfigProvider locale={lang} direction={dir as DirectionType}>
        <Router>
          <App />
        </Router>
      </ConfigProvider>
    </ContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
