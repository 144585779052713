import en_us from "./en_us";
import en_gb from "./en_gb";
import fr_fr from "./fr_fr";
import nb_no from "./nb_no";

const locales = {
  en_us: en_us,
  en_gb: en_gb,
  nb_no: nb_no,
  fr_fr: fr_fr,
};

export default locales;
