import React, { FC, useContext, useEffect, useState } from "react";
import { Layout } from "antd";

import { useLocation } from "react-router-dom";

import { AppContext } from "../context";

import AppMeta from "../components/AppMeta";
import AppHeader from "../components/AppHeader";
import AppContent from "../components/AppContent";
import AppLeftNavigation from "../components/AppLeftNavigation";
import { t } from "../utils/helpers";
import AppPublicHeader from "../components/AppPublicHeader";

interface ScreensProps { }

const Screens: FC<ScreensProps> = (props: ScreensProps) => {
  // const {} = props;
  const { Header, Sider, Content } = Layout;
  const location = useLocation();
  const { isAuthenticated, setCurrentPageTitle } = useContext(AppContext);

  const publicRoutes = [
    "/login",
    "/register",
    "/forgot-password",
    "/terms-and-conditions",
    "/contact-us",
    "/about",
    "/faqs",
  ];

  useEffect(() => {
    const pathname = location.pathname.toLowerCase();
    switch (true) {
      case pathname.match(t("/update-billing-information")) !== null:
        setCurrentPageTitle(t("Update Billing Information"));
        break;
      case pathname.match(t("/view/artifact")) !== null:
        setCurrentPageTitle(t("View Collection"));
        break;
      case pathname.match(t("/view/collection")) !== null:
        setCurrentPageTitle(t("View Collection"));
        break;
      case pathname.match(t("/subscription")) !== null:
        setCurrentPageTitle(t("Subscription"));
        break;
      case pathname.match(t("/add-artifact")) !== null:
        setCurrentPageTitle(t("Add Artifact"));
        break;
      case pathname.match(t("/members-directory")) !== null:
        setCurrentPageTitle(t("Member Directory"));
        break;
      case pathname.match(t("/transfer")) !== null:
        setCurrentPageTitle(t("Choose Recipient"));
        break;
      case pathname.match(t("/catalogs/new")) !== null:
        setCurrentPageTitle(t("Add Catalog"));
        break;
      case pathname.match(t("/catalogs/edit")) !== null:
        setCurrentPageTitle(t("Edit Catalog"));
        break;
      case pathname.match(t("/catalogs/view")) !== null:
        setCurrentPageTitle(t("View Catalog"));
        break;
      case pathname.match(t("/catalogs")) !== null:
        setCurrentPageTitle(t("Catalogs"));
        break;
      case pathname.match(t("/my-profile/edit")) !== null:
        setCurrentPageTitle(t("Edit Profile"));
        break;
      case pathname.match(t("/my-profile")) !== null:
        setCurrentPageTitle(t("My Profile"));
        break;
      case pathname.match(t("/artifact/edit")) !== null:
        setCurrentPageTitle(t("Edit Artifact"));
        break;
    }
  }, [location.pathname, setCurrentPageTitle]);

  return (
    <>
      <AppMeta />
      {isAuthenticated &&
        !publicRoutes.includes(location.pathname.toLowerCase()) ? (
        <Layout>
          {/* <Sider trigger={null} collapsible collapsed={collapsed}>
            <div className="logo"></div> */}
          <AppLeftNavigation />
          {/* </Sider> */}
          <Layout>
            {/* <Header className="site-layout-background" style={{ padding: 0 }}>
              <span
                onClick={() => {
                  setCollapsed(!collapsed);
                }}
              >
                {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              </span> */}

            <AppHeader />
            {/* </Header> */}
            <Content className="site-layout-background">
              <AppContent />
            </Content>
          </Layout>
        </Layout>
      ) : (
        <Layout>
          <AppPublicHeader />
          <AppContent />
        </Layout>
      )}
    </>
  );
};

export default Screens;
