import React, { FC, useContext, useEffect } from "react";

import { Row, Col, Button, Card } from "antd";
import Artifact from "../../../models/Artifact";
import { AppContext } from "../../../context";
import { useHistory, useParams } from "react-router";
import {
  EditOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
interface CatalogDetailProps { }

const CatalogDetail: FC<CatalogDetailProps> = (props: CatalogDetailProps) => {
  const { catalog } = useContext(AppContext);
  const history = useHistory();
  return (
    <Row justify="center">
      <Col xs={24} sm={24} md={16} lg={16} xl={16}>
        <Card
          title={catalog.name}
          extra={[
            <Button
              onClick={() => {
                history.push(`/catalogs/edit/${catalog.id}`);
              }}
            >
              <EditOutlined />
            </Button>, <Button
              style={{ marginLeft: "10px" }}
              onClick={() => {
                history.push(`/view/collection/${catalog.id}`);
              }}
            >
              <InfoCircleOutlined />
            </Button>,
          ]}
        >
          <Row className="catalog_detail" gutter={10} justify="center">
            <Col xs={24} sm={24} md={24} lg={8} xl={8} >
              <div className="image-holder">
                <img
                  src={
                    catalog.coverPhotoUrl
                      ? catalog.coverPhotoUrl
                      : "https://via.placeholder.com/500"
                  }
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16} xl={16} >
              <p>{catalog.description1}</p>
              <p>{catalog.description2}</p>
              <p>{catalog.notes}</p>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default CatalogDetail;
