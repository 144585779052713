import axios from "axios";
import { get } from "lodash";

import locales from "../locales";

const { REACT_APP_YOUTUBE_API_KEY } = process.env;

export declare type DirectionType = "ltr" | "rtl" | undefined;

export const getLocalStorageItem: any = (
  key: string,
  parse: boolean = false
) => {
  return parse
    ? JSON.parse(localStorage.getItem(key) as string)
    : localStorage.getItem(key);
};

export const setLocalStorageItem = (
  key: string,
  value: any,
  stringify: boolean = false
) => {
  return stringify
    ? localStorage.setItem(key, JSON.stringify(value))
    : localStorage.setItem(key, value);
};

export const t = (key: string) => {
  let locale = getLocalStorageItem("collectible_artifacts_locale");

  if (!locale || locale === null || locale === "" || locale === undefined) {
    locale = "en_US";
    setLocalStorageItem("collectible_artifacts_locale", locale, false);
  }

  const path = `${locale.toLowerCase()}.${key}`;
  return get(locales, path, key);
};

export const similarKeysToArray = async (obj: any, keyPrefix: string) => {
  let count = 0;

  let availableKeys = [];
  let returnArr = [];

  while (Object.keys(obj).includes(`${keyPrefix}${count}`)) {
    if (
      obj[`${keyPrefix}${count}`] &&
      obj[`${keyPrefix}${count}`] !== null &&
      obj[`${keyPrefix}${count}`] !== undefined &&
      obj[`${keyPrefix}${count}`] !== ""
    ) {
      returnArr.push(obj[`${keyPrefix}${count}`]);
    }
    availableKeys.push(`${keyPrefix}${count}`);
    count++;
  }

  return returnArr;
};

export const convertToMediaLinks = (
  urls: string[],
  type: "photo" | "video"
) => {
  return urls.map((url) => {
    return { type: type, url: url };
  });
};

export const getYoutubeVideoInfo = async (url: string) => {
  let videoId = url.split("v=")[1];
  let ampersandPosition = videoId.indexOf("&");
  if (ampersandPosition !== -1) {
    videoId = videoId.substring(0, ampersandPosition);
  }
  const requestUrl = `https://www.googleapis.com/youtube/v3/videos?part=id%2C+snippet%2C+contentDetails&id=${videoId}&key=${REACT_APP_YOUTUBE_API_KEY}`;

  const {
    data: { items },
  } = await axios.get(requestUrl);

  return items.length > 0 ? items[0] : null;
};

export const getBase64 = (file: File, callback: (result: any) => void) => {
  const reader = new FileReader();
  reader.onload = () => {
    callback(reader.result);
  };
  try {
    reader.readAsDataURL(file as Blob);
  } catch (ex) {    
  }
};
