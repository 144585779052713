import React, { FC, useContext, useEffect, useState } from "react";

import { Row, Col, Spin, Modal, Alert } from "antd";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context";
import EditProfileForm from "../../forms/EditProfileForm";
interface EditProfile { }

const EditProfile: FC = (props: EditProfile) => {
  const { userData, authenticatedUser } = useContext(AppContext);
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [subscriptionModal, setSubscriptionModal] = useState<boolean>(false);
  useEffect(() => {
    if (authenticatedUser.disabled && authenticatedUser.trailExpired) {
      setSubscriptionModal(true);
      setTimeout(() => {
        history.push("/subscription");
      }, 2000);
    }
  })
  return (
    <div className="catalog-wrapper">
      <Modal title="Your Account Expired" onCancel={() => setSubscriptionModal(false)} onOk={() => setSubscriptionModal(false)} visible={subscriptionModal}>
        <Alert
          message=""
          description="Please update your billing Information."
          type="warning"
          showIcon
        />
      </Modal>
      {userData ? <Row gutter={8}></Row> : ""}
      <EditProfileForm User={userData} />
      {loading ? (
        <Row gutter={8} justify="center">
          <Col>
            <Spin />
          </Col>
        </Row>
      ) : null}
    </div>
  );
};

export default EditProfile;
