import React, { FC, useContext, useEffect, useState } from "react";

import { Row, Col, Radio, Divider, Card, Button, Spin, Modal, Alert } from "antd";
import { UnorderedListOutlined, IdcardOutlined } from "@ant-design/icons";
import { useHistory, Link } from "react-router-dom";
import { AppContext } from "../../context";
import User from "../../models/User";
import UserCard from "./Partials/UserCard";
import { useParams } from "react-router";
import EditProfileForm from "../../forms/EditProfileForm";
import { getUserProfileById } from "../../api/catalog";
interface UserProfile { }

const UserProfile: FC = (props: UserProfile) => {
  const { authToken, authenticatedUser, userData, setUserData } =
    useContext(AppContext);
  const history = useHistory();
  const [displayMode, setDisplayMode] = useState<string>("card");
  const [subscriptionModal, setSubscriptionModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (authenticatedUser.disabled && authenticatedUser.trailExpired) {
      setSubscriptionModal(true);
      setTimeout(() => {
        history.push("/subscription");
      }, 2000);
    }
    (async () => {
      setLoading(true);
      const data = await getUserProfileById(authToken, authenticatedUser.id);
      setUserData(data.data);
      setLoading(false);
    })();
  }, [authenticatedUser.id]);
  return (
    <div className="catalog-wrapper">
      <Modal title="Your Account Expired" onCancel={() => setSubscriptionModal(false)} onOk={() => setSubscriptionModal(false)} visible={subscriptionModal}>
        <Alert
          message=""
          description="Please update your billing Information."
          type="warning"
          showIcon
        />
      </Modal>
      <Divider />
      {/* <Divider /> */}
      {userData ? (
        <Row gutter={8}>
          <Col span={24}>
            <UserCard renderAs={displayMode} />
          </Col>
        </Row>
      ) : (
        ""
      )}
      {loading ? (
        <Row gutter={8} justify="center">
          <Col>
            <Spin />
          </Col>
        </Row>
      ) : null}
    </div>
  );
};

export default UserProfile;
