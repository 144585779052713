import React, { FC, useContext, useState, useEffect } from "react";
import { Layout, Menu, Row, Col, Button, Popconfirm } from "antd";

import {
  DashboardOutlined,
  LogoutOutlined,
  UserOutlined,
  DollarOutlined,
  UserSwitchOutlined,
  QuestionOutlined,
  MailOutlined,
  InfoOutlined,
  BankOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

import { AppContext } from "../context";
import image from "../assets/image/logo.png";
import { t } from "../utils/helpers";
import moment from "moment";

interface AppLeftNavigationProps { }

const { Sider } = Layout;

const today = moment();

const AppLeftNavigation: FC = (props: AppLeftNavigationProps) => {
  const history = useHistory();
  const {
    currentPageTitle,
    setAuthenticatedUser,
    collapsed,
    setCollapsed,
    setIsAuthenticated,
    setAuthToken,
  } = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [logoutConfirmVisible, setLogoutConfirmVisible] =
    useState<boolean>(false);
  useEffect(() => { }, [collapsed]);
  return (
    // <Sider
    //   trigger={null}
    //   collapsible
    //   collapsed={collapsed}
    //   width={262}
    //   theme={"light"}
    //   className={"main-left-navigation hide-on-mobile"}
    // >
    <Sider
      trigger={null}
      className={`main-left-navigation mobile-menu ` + collapsed}
      collapsible
      width={260}
      collapsed={collapsed}
    >
      <Row>
        <Col span={24}>
          <div className="brand-name">
            <img
              onClick={() => {
                history.push(`/catalogs`);
              }}
              src={image}
              alt={"logo"}
            />
          </div>
        </Col>
      </Row>
      <Row>
        {/* bf13f860-4c6d-428f-1545-08d9577412f2 */}
        {/* 22579035-7c76-42c8-94dc-b2636e5a9777 */}
        <Col span={24}>
          <Menu
            defaultSelectedKeys={[currentPageTitle.toLowerCase()]}
            mode={"vertical"}
            theme={"light"}
          >
            {/* <Menu.Item key="view-collection" icon={<DashboardOutlined />}>
              <Link to={t("/view/collection/bf13f860-4c6d-428f-1545-08d9577412f2")}>{t("View Collection")}</Link>
            </Menu.Item>
            <Menu.Item key="view-artifact-collection" icon={<DashboardOutlined />}>
              <Link to={t("/view/artifact/e56cfde3-a41d-4b12-bdc2-08d95d8fab10")}>{t("View Artifact Collection")}</Link>
            </Menu.Item> */}
            <Menu.Item key="catalog" icon={<DashboardOutlined />}>
              <Link to={t("/catalogs")}>{t("My Collections")}</Link>
            </Menu.Item>
            <Menu.Item key="Profile" icon={<UserOutlined />}>
              <Link to={t("/my-profile")}>{t("Profile")}</Link>
            </Menu.Item>
            <Menu.Item key="Subscription" icon={<DollarOutlined />}>
              <Link to={t("/subscription")}>{t("Subscription")}</Link>
            </Menu.Item>
            <Menu.Item key="MemberDirectory" icon={<UserSwitchOutlined />}>
              <Link to={t("/members-directory")}>{t("Member Directory")}</Link>
            </Menu.Item>
            <Menu.Item key="About" icon={<InfoOutlined />}>
              <Link to={t("/about")}>{t("About Collector's Catalogs")}</Link>
            </Menu.Item>
            <Menu.Item key="Faqs" icon={<QuestionOutlined />}>
              <Link to={t("/faqs")}>{t("FAQ")}</Link>
            </Menu.Item>
            <Menu.Item key="Contact" icon={<MailOutlined />}>
              <Link to={t("/contact-us")}>{t("Contact Us")}</Link>
            </Menu.Item>
            <Menu.Item key="terms" icon={<BankOutlined />}>
              <Link to={t("/terms-and-conditions")}>
                {t("Terms and Condition")}
              </Link>
            </Menu.Item>
            <Menu.Item icon={<LogoutOutlined />}>
              <Popconfirm
                title={t("Are you sure you want to logout?")}
                onConfirm={() => {
                  setLoading(true);
                  setTimeout(function () {
                    setAuthenticatedUser("");
                    setIsAuthenticated("");
                    setAuthToken("");
                    history.push(`/`);
                    setLoading(false);
                  }, 2000);
                }}
                okButtonProps={{ loading: loading }}
                okText={t("Yes log me out!")}
                onCancel={() => {
                  setLogoutConfirmVisible(false);
                }}
                visible={logoutConfirmVisible}
                className={"popconfirm-primary"}
              >
                <Button
                  onClick={() => {
                    setLogoutConfirmVisible(true);
                  }}
                  type="link"
                  className="logout-btn"
                >
                  <span>{t("Logout")}</span>
                </Button>
              </Popconfirm>
            </Menu.Item>
          </Menu>
        </Col>
      </Row>
      <p className="versionInfo">
        Collector's Catalogs Version 1.0.0 &copy; {today.format("Y")}
      </p>
    </Sider>
  );
};

export default AppLeftNavigation;
