import React, { FC, useContext, useEffect, useState } from "react";
import * as _ from "lodash";
import { Row, Col, Card, Spin, Divider } from "antd";

import { useParams, useHistory } from "react-router-dom";
import { getCatalogCollectionById } from "../../api/catalog";
import { AppContext } from "../../context";
import Catalog from '../../models/Catalog';
import Artifact from "../../models/Artifact";
import CatalogCard from "../Catalogs/_partials/CatalogCard";
import { Button } from "antd/lib/radio";
import {
    InfoCircleOutlined,
} from "@ant-design/icons";
interface ViewCollectionProps { }

const ViewCollection: FC = (props: ViewCollectionProps) => {
    //@ts-ignoreF
    const { authToken, authenticatedUser } = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [showArtifactFlag, setShowArtifactFlag] = useState(false);
    const history = useHistory();
    var { collectionId } = useParams<any>();
    const [collectionList, setCollectionList] = useState<any[]>([]);
    const [collection, setCollection] = useState<any[]>([]);
    useEffect(() => {
        (async () => {
            const dataa = [];
            setLoading(true);
            const { data } = await getCatalogCollectionById(authToken, collectionId);
            dataa.push(data);
            setCollectionList(dataa);
            setLoading(false);
        })();
    }, [collectionId]);
    useEffect(() => {
        setCollection(collectionList);
    }, [collectionList])
    return (
        <>
            <Row gutter={15} style={{ marginTop: "50px" }}>
                <Col span={24}>
                    {
                        collection.map((list: Catalog) => {
                            return (
                                <>
                                    <Row gutter={20} justify="center" align="middle" >
                                        <Col
                                            xs={24}
                                            sm={24}
                                            md={8}
                                            lg={8}
                                            xl={8}
                                        >
                                            <Card
                                                onClick={() => {
                                                    setShowArtifactFlag(!showArtifactFlag);
                                                }}
                                                hoverable
                                                cover={
                                                    <img
                                                        onClick={() => {
                                                            setShowArtifactFlag(!showArtifactFlag);
                                                        }}
                                                        src={
                                                            list.coverPhotoUrl
                                                                ? list.coverPhotoUrl
                                                                : "https://via.placeholder.com/500"
                                                        }
                                                    />
                                                }
                                                className={"card-mode"}
                                            >
                                                <Card.Meta
                                                    className={"catalog-meta"}
                                                    title={list.name ? list.name : <span>&nbsp;</span>}
                                                    description={
                                                        list.description1 ? (
                                                            list.description1
                                                        ) : list.description2 ? (
                                                            list.description2
                                                        ) : null
                                                    }
                                                />
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Divider />
                                    {showArtifactFlag ? (
                                        <Row gutter={20} justify="center" align="middle" style={{ marginTop: "30px" }} >
                                            {
                                                list.artifactProfiles.map((art: Artifact) => {
                                                    return (
                                                        <Col
                                                            xs={24}
                                                            sm={24}
                                                            md={8}
                                                            lg={6}
                                                            xl={6}

                                                        >
                                                            <Card
                                                                actions={[
                                                                    <Button
                                                                        onClick={() => {
                                                                            history.push(`/view/artifact/${art.id}`);
                                                                        }}
                                                                    >
                                                                        <InfoCircleOutlined />
                                                                    </Button>,
                                                                ]}
                                                                // onClick={() => {
                                                                //     history.push(`/view/artifact/${art.id}`);
                                                                // }}
                                                                hoverable
                                                                cover={
                                                                    <img
                                                                        src={
                                                                            art.artifactImages
                                                                                ? _.map(art.artifactImages, "photoUrl").length > 1
                                                                                    ? _.map(art.artifactImages, "photoUrl")[1].toString()
                                                                                    : _.map(art.artifactImages, "photoUrl").toString()
                                                                                : "https://via.placeholder.com/500"
                                                                        }
                                                                    />
                                                                }
                                                                className={"card-mode"}
                                                            >
                                                                <Card.Meta
                                                                    title={art.name}
                                                                    description={art.description}
                                                                />
                                                            </Card>
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                    ) : null}
                                </>
                            )
                        })
                    }
                </Col>
            </Row>
        </>
    );
};

export default ViewCollection;
