import React, { FC, useState } from "react";

import { Form, Input, Button, message } from "antd";

import { t } from "../utils/helpers";
import { forgotPassword } from "../api/auth";
import { useHistory } from "react-router";

interface ForgotPasswordFormProps { }

const ForgotPasswordForm: FC<ForgotPasswordFormProps> = (
  props: ForgotPasswordFormProps
) => {
  // const {} = props;

  const history = useHistory();

  const [sent, setSent] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = async (values: any) => {
    const { email } = values;

    try {
      setLoading(true);

      const forgotPassReq = await forgotPassword(email);


      const { data } = forgotPassReq;

      if (data && data.status.toLowerCase() === "success") {
        message.success("Recovery Email Sent!");
        setSent(true);
        history.push("/login");
      } else {
        message.error("Could not complete your request!");
      }

      setLoading(false);
    } catch (err) {
      message.error("Could not register you, please try back later.");

      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      name="forgot-password-form"
      initialValues={{}}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <div className="forgot-password-form-fields-wrapper">
        {!sent ? (
          <>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: t("Email is required!"),
                },
                {
                  type: "email",
                  message: t("Must be a valid email address!"),
                },
              ]}
            >
              <Input className={"input-primary"} placeholder="Email" />
            </Form.Item>

            <Form.Item>
              <Button
                className="btn-primary"
                type="primary"
                htmlType="submit"
                block
                loading={loading}
              >
                Send Email
              </Button>
            </Form.Item>
          </>
        ) : (
          <>
            <p>
              {t(
                "An email has been sent. Please click the link when you get it on your email."
              )}
            </p>
          </>
        )}
      </div>
    </Form>
  );
};

export default ForgotPasswordForm;
