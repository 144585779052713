import React, { FC, useContext, useEffect, useState } from "react";

import { Row, Col, Radio, RadioChangeEvent, Modal, message, Alert } from "antd";

import { useParams, useHistory } from "react-router-dom";
import { getAllMemberAccount, transferArtifactToNewMemberAccount, getArtifactById } from "../../api/catalog";
import { AppContext } from "../../context";
import TransferOwnerShipSearchForm from "../../forms/TransferOwnerShipSearchForm";

interface TransferOwnerShipProps { }

const TransferOwnerShip: FC = (props: TransferOwnerShipProps) => {
  //@ts-ignoreF
  const { authToken, allMemberAccountList, authenticatedUser, setAllMemberAccountList } = useContext(AppContext);
  const [artifactByID, setArtifactByID] = useState<any>({});
  const [dataMemberValue, setDataMemberValue] = useState<[]>();
  const [subscriptionModal, setSubscriptionModal] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<any>();
  const [selectedUserName, setSelectedUserName] = useState<any>();
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { transferType, id } = useParams<any>();
  const history = useHistory();
  useEffect(() => {
    if (authenticatedUser.disabled && authenticatedUser.trailExpired) {
      setSubscriptionModal(true);
      setTimeout(() => {
        history.push("/subscription");
      }, 2000);
    }
    (async () => {
      const data = await getAllMemberAccount(authToken);
      setAllMemberAccountList(data.data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const artifactObject = await getArtifactById(authToken, id);
      setArtifactByID(artifactObject.data);
    })();
  }, [id]);

  const onChange = (e: RadioChangeEvent) => {
    setDataMemberValue(e.target.value);
    const myArr = e.target.value.split(",");
    setSelectedUserId(myArr[0]);
    setSelectedUserName(myArr[1]);
    setVisible(true);
  };
  const handleCancel = () => {
    setVisible(false);
  };
  const artifactTransfer = async () => {
    try {
      setLoading(true);
      setConfirmLoading(true);
      const createReq = await transferArtifactToNewMemberAccount(
        authToken,
        id,
        selectedUserId
      );
      setLoading(false);
      setConfirmLoading(false);
      setVisible(false);
      history.push("/catalogs");
    } catch (err) {
      message.error("Invalid Username and Password Combination!");
      setLoading(false);
      setConfirmLoading(false);
    }
  }

  const handleOk = async (values: any) => {
    artifactTransfer()
  };
  return (
    <>
      <Modal title="Your Account Expired" onCancel={() => setSubscriptionModal(false)} onOk={() => setSubscriptionModal(false)} visible={subscriptionModal}>
        <Alert
          message=""
          description="Please update your billing Information."
          type="warning"
          showIcon
        />
      </Modal>
      <Modal
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
      >
        <div className="modal-content">
          <p>You'r are transfering  <br />  <b>Item: {artifactByID ? artifactByID?.name : null} </b> <br /> to <br />
            <b>{selectedUserName}</b> <br />
            They will be emailed a link <br /> to confirm and accept
          </p>
        </div>
      </Modal>
      <Row gutter={8}>
        <Col span={24}>
          <TransferOwnerShipSearchForm />
          <Radio.Group onChange={onChange} className='audio-group-wrapper' value={dataMemberValue}>
            {allMemberAccountList
              ? allMemberAccountList.map((MemberAccount: any) => {
                const value = MemberAccount.id + "," + MemberAccount.firstName + " " + MemberAccount.lastName;
                return (
                  <Radio
                    className="checbox-wrapper "
                    onChange={(e) => onChange(e)}
                    value={value}
                  >
                    <img className="image" src={MemberAccount.profileImage ? MemberAccount.profileImage : "https://via.placeholder.com/100"} />
                    <div className="name">
                      {MemberAccount.firstName + " " + MemberAccount.lastName}
                      {MemberAccount.dealerAccount ? (
                        <i className="dealer"> D </i>
                      ) : null}
                    </div>
                  </Radio>
                );
              })
              : null}
          </Radio.Group>
        </Col>
      </Row>
    </>
  );
};

export default TransferOwnerShip;
