import React, { FC, useContext, useEffect, useState } from "react";

import {
  Form,
  Input,
  Button,
  message,
  Row,
  Popconfirm,
  Col,
  Upload,
  Modal,
  Spin,
} from "antd";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { FacebookShareButton } from "react-share";
import { AppContext } from "../context";

import { getBase64, t } from "../utils/helpers";
import { useForm } from "antd/lib/form/Form";

import {  
  UploadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import {
  createArtifact,
  updateArtifact,
  deleteArtifactById,
  duplicateArtifact,
  getExportLinkArtifact,
  getAllMemberCatalogs,
  getAllArtifactsByCatalogId,
  geArtifactShareLink,
  deleteArtifactImage,
  uploadArtifactImages,
  getArtifactById,
  updateImageOrderById,
} from "../api/catalog";

import Artifact from "../models/Artifact";
import { useParams } from "react-router";
// import { set } from "lodash";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
} from "react-sortable-hoc";
// import { collapseTextChangeRangesAcrossMultipleVersions, isConstructorDeclaration } from "typescript";

interface ArtifactFormProps {
  ExistingArtifact?: Artifact;
  ArtifactId?: String | null;
}

const ArtifactForm: FC<ArtifactFormProps> = (props: ArtifactFormProps) => {
  const { ArtifactId, ExistingArtifact } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [transferOwnershipLoading, setTransferOwnershipLoading] =
    useState<boolean>(false);
  const [artifactShareLink, setArtifactShareLink] = useState<any>(null);
  const [exportLinkLoading, setExportLinkLoading] = useState<boolean>(false);
  const [duplicateLoading, setDuplicateLoading] = useState<boolean>(false);
  const [shareLinkLoading, setShareLinkLoading] = useState<boolean>(false);
  const [shareLinkToFacebookLoading, setShareLinkToFacebookLoading] =
    useState<boolean>(false);
  const [shareLinkToInstagramLoading, setShareLinkToInstagramLoading] =
    useState<boolean>(false);
  const [deleteImageLoading, setDeleteImageLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string | null | void>(null);

  const [fileType, setFileType] = useState<any>("");
  const [fileExt, setFileExt] = useState<string>(".jpg");

  const [originalFileObj, setOriginalFileObj] = useState<Blob | null>(null);
  const [artifact, setArtifact] = useState<Artifact>();
  const [uploadedImages, setUploadedImages] = useState<any[]>([]);
  const [oldImagesOrder, setOldImagesOrder] = useState<any[]>([]);
  const [reloading, setReloading] = useState<boolean>(false);

  const [showCanclePopup, setShowCanclePopup] = useState<boolean>(false);

  const [form] = useForm();

  const history = useHistory();
  const {
    setCatalog,
    authToken,
    authenticatedUser,
    setArtifactImages,
    artifactImages,
    setMemberCatalogs,
  } = useContext(AppContext);
  const { catalogId, artifactId } = useParams<any>();

  useEffect(() => {
    if (reloading) {
      let ar: { position: any; id: any; photoUrl: any }[] = [];
      uploadedImages.map(
        (el: { displayOrder: any; id: any; photoUrl: any }) => {
          var obj = {
            position: el.displayOrder,
            id: el.id,
            photoUrl: el.photoUrl,
          };
          ar.push(obj);
        }
      );
      ar.sort(function (a: any, b: any) {
        return a.position - b.position;
      });
      setOldImagesOrder(ar);
      setReloading(false);
    }
  }, [reloading]);

  useEffect(() => {
    if (ArtifactId) {
      (async () => {
        const { data } = await getArtifactById(authToken, artifactId);
        setArtifact(data);
        const firstImage =
          data.artifactImages && data.artifactImages.length > 0
            ? data.artifactImages[0]
            : "";
        const artifactImages =
          data && data.artifactImages ? data.artifactImages : [];
        setUploadedImages(artifactImages);
        const shareLink = await geArtifactShareLink(
          authToken,
          ArtifactId as string
        );
        setArtifactShareLink(shareLink.data);

        let ar: { position: any; id: any; photoUrl: any }[] = [];
        data.artifactImages.map(
          (el: { displayOrder: any; id: any; photoUrl: any }) => {
            var obj = {
              position: el.displayOrder,
              id: el.id,
              photoUrl: el.photoUrl,
            };
            ar.push(obj);
          }
        );

        ar.sort(function (a: any, b: any) {
          return a.position - b.position;
        });

        setOldImagesOrder(ar);
      })();
    }
  }, [ArtifactId]);

  const onFinish = async (values: any) => {
    const {
      // coverPhotoUrl,
      name,
      description,
      finder,
      location,
      material,
      purchaseInfo,
      size,
      collectorHistory,
      notes,
    } = values;

    const artifactData = {
      name: name,
      // artifactImages: [""],
      description: description,
      finder: finder,
      material: material,
      size: size,
      collectorHistory: collectorHistory,
      notes: notes,
      location: location,
      ownersMemberAccountId: authenticatedUser.id,
      purchaseInfo: purchaseInfo,
      memberCatalogId: catalogId,
    };
    console.log('artifact ', artifact)
    try {
      setLoading(true);
      if (artifact) {
        const updateReq = await updateArtifact(
          authToken,
          artifact?.id as string,
          { ...artifactData, id: artifact?.id } as Artifact
        );

        // const uploads = uploadArtifactImages(
        //   authToken,
        //   artifactId,
        //   oldImagesOrder,
        //   oldImagesOrder.length
        // );
        setArtifactImages([]);
        setOldImagesOrder([]);
      } else {
        const createReq = await createArtifact(
          authToken,
          artifactData as Artifact
        );

        // const uploads = await uploadArtifactImages(
        //   authToken,
        //   artifactProfileId,
        //   oldImagesOrder,
        //   0
        // );

        setOldImagesOrder([]);
        setArtifactImages([]);
      }
      setLoading(false);
      history.push(`/catalogs/view/${catalogId}`);
    } catch (err) {
      message.error("Invalid Username and Password Combination!");
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => { };

  const onSortEnd = async (oldIndex: any, newIndex: any) => {
    // setDeleteLoading(true);
    const dataa = await updateImageOrderById(
      authToken,
      artifact?.id,
      oldIndex.oldIndex,
      oldIndex.newIndex
    );
    let arr = arrayMove(oldImagesOrder, oldIndex.oldIndex, oldIndex.newIndex);
    for (let i = 0; i < arr.length; i++) {
      arr[i].position = i;
    }
    setOldImagesOrder(arr);
    const { data } = await getArtifactById(authToken, artifactId);
    setUploadedImages(data.artifactImages);
    // setDeleteLoading(false);
    setReloading(true);
  };

  const SortAbleItem = SortableElement((value: any, index: any) => (
    <div className="box-wrapper">
      <div key={value.value.id} id={value.value.id} className="box">
        <img
          key={value.value?.id}
          id={value.value?.id}
          src={value.value?.photoUrl}
          alt=""
        />
      </div>
    </div>
  ));

  const SortAbeleImages = SortableContainer((items: any) => {
    return (
      <Row className="sortable-row">
        <Col span={24}>
          {items
            ? items.items
              .sort((a: any, b: any) => a.position - b.position)
              .map((value: any, index: any) => (
                <div className="image-holderr">
                  <Popconfirm
                    title={
                      <p style={{ textAlign: "center" }}>
                        Are you sure you want to delete the image?
                      </p>
                    }
                    cancelText={"No"}
                    okText={"Yes"}
                    onConfirm={async () => {
                      setDeleteImageLoading(true);
                      try {
                        await deleteArtifactImage(
                          authToken,
                          value?.id as string
                        );
                        const { data } = await getArtifactById(
                          authToken,
                          artifactId
                        );
                        setUploadedImages(data.artifactImages);
                        // setOldImagesOrder(data.artifactImages);
                        setDeleteImageLoading(false);
                        setReloading(true);
                      } catch (error) {
                        setDeleteImageLoading(false);
                      }
                    }}
                    onCancel={() => { }}
                  >
                    <DeleteOutlined />
                  </Popconfirm>
                  <SortAbleItem
                    value={value}
                    index={index}
                    key={value.id}
                    sortIndex={index}
                    useDragHandle={false}
                  />
                </div>
              ))
            : null}
        </Col>
      </Row>
    );
  });



  return (
    <div className="catalog-form-fields-wrapper">
      <Form
        form={form}
        name="catalog-form"
        initialValues={ExistingArtifact}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
      >
        <>
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                label="Title/Catalog Number"
                name="name"
                rules={[
                  {
                    required: true,
                    message: t("Name is required!"),
                  },
                ]}
              >
                <Input className={"input-primary"} placeholder={t("Name")} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item label="Description" name="description">
                <Input
                  className={"input-primary"}
                  placeholder={t("Description")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item label="Finder" name="finder">
                <Input className={"input-primary"} placeholder={t("Finder")} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item label="Location" name="location">
                <Input
                  className={"input-primary"}
                  placeholder={t("Location")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item label="Material" name="material">
                <Input
                  className={"input-primary"}
                  placeholder={t("Material")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item label="Size" name="size">
                <Input className={"input-primary"} placeholder={t("Size")} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item label="Purchase Info" name="purchaseInfo">
                <Input.TextArea
                  rows={5}
                  className={"input-primary"}
                  placeholder={t("Purchase Info")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item label="Collection History" name="collectorHistory">
                <Input
                  className={"input-primary"}
                  placeholder={t("Collection History")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item label="Notes" name="notes">
                <Input.TextArea
                  rows={5}
                  className={"input-primary"}
                  placeholder={t("Notes")}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={24}>
              <Form.Item name="coverPhotoUrl" style={{ display: "none" }}>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={24}>
              {artifact ? (
                <>
                  <Button
                    style={{ display: "block", marginBottom: "20px" }}
                    loading={duplicateLoading}
                    onClick={async () => {
                      setDuplicateLoading(true);
                      try {
                        await duplicateArtifact(
                          authToken,
                          artifact.id as string
                        );
                        const { data } = await getAllArtifactsByCatalogId(
                          authToken,
                          catalogId
                        );
                        setCatalog(data);
                        setDuplicateLoading(false);
                        history.goBack();
                      } catch (error) {
                        setDuplicateLoading(false);
                      }
                    }}
                  >
                    Duplicate
                  </Button>
                  <Button
                    style={{ display: "block", marginBottom: "20px" }}
                    loading={shareLinkLoading}
                    onClick={async () => {
                      // history.push(`/catalogs/edit/${catalog.id}`);
                      setShareLinkLoading(true);
                      try {
                        const shareLink = await geArtifactShareLink(
                          authToken,
                          artifact.id as string
                        );
                        setArtifactShareLink(shareLink.data);
                        const { data } = await getAllMemberCatalogs(
                          authToken,
                          authenticatedUser.id
                        );

                        if (shareLink.data)
                          navigator.clipboard.writeText(shareLink.data);

                        Modal.success({
                          title: "Link has been copied to your clipboard",
                        });
                        setMemberCatalogs(data);
                        setShareLinkLoading(false);
                      } catch (error) {
                        setShareLinkLoading(false);
                      }
                    }}
                  >
                    Share Link
                  </Button>
                  <Button
                    style={{ display: "block", marginBottom: "20px" }}
                    loading={exportLinkLoading}
                    onClick={async () => {
                      setExportLinkLoading(true);
                      try {
                        const exportLink = await getExportLinkArtifact(
                          authToken,
                          artifact.id as string
                        );
                        window.open(exportLink.data, "_blank");
                        setExportLinkLoading(false);
                      } catch (error) {
                        setExportLinkLoading(false);
                      }
                    }}
                  >
                    Export To XLS
                  </Button>
                  <FacebookShareButton
                    quote={artifact?.name}
                    hashtag={artifact?.description}
                    url={artifactShareLink ? artifactShareLink : ""}
                  >
                    <Button style={{ display: "block", marginBottom: "20px" }}>
                      Share to Facebook
                    </Button>
                  </FacebookShareButton>
                  <Popconfirm
                    title={
                      <p style={{ textAlign: "center" }}>
                        Your are transfering <br />{" "}
                        <b> Item : {artifact.name} </b> <br />
                        Click "Next" to choose the recipient
                      </p>
                    }
                    cancelText={"Cancle"}
                    okText={"Next"}
                    onConfirm={async () => {
                      setTransferOwnershipLoading(true);
                      setTimeout(function () {
                        history.push(`/transfer/${"artifact"}/${artifact.id}/`);
                        setTransferOwnershipLoading(false);
                      }, 1000);
                    }}
                    onCancel={() => { }}
                  >
                    <Button
                      style={{ display: "block", marginBottom: "20px" }}
                      loading={transferOwnershipLoading}
                    >
                      Transfer Ownership
                    </Button>
                  </Popconfirm>
                  <Popconfirm
                    style={{ display: "block", marginBottom: "20px" }}
                    title="Are you sure you want to delete?"
                    okText={"Yes"}
                    cancelText={"No"}
                    onConfirm={async () => {
                      setDeleteLoading(true);
                      await deleteArtifactById(
                        authToken,
                        artifact.id as string
                      );
                      const { data } = await getAllArtifactsByCatalogId(
                        authToken,
                        catalogId
                      );
                      setCatalog(data);
                      setDeleteLoading(false);
                      // history.push(`/catalogs/`);
                      history.goBack();
                    }}
                    onCancel={() => { }}
                  >
                    <Button
                      style={{ display: "block", marginBottom: "20px" }}
                      loading={deleteLoading}
                    >
                      Delete
                    </Button>
                  </Popconfirm>
                  ,
                </>
              ) : null}
            </Col>
          </Row>

          {oldImagesOrder.length > 0 ? (
            <SortAbeleImages
              onSortEnd={onSortEnd}
              items={oldImagesOrder}
              axis={"x"}
            />
          ) : null}
          <Row>
            {deleteImageLoading ? <Spin className="delete-loader" /> : null}
          </Row>


          {/* {artifact ? ( */}
          <Row style={{ marginTop: "15px", marginBottom: "15px" }}>
            <Col>
              <Upload
                listType="picture-card"
                multiple={false}
                showUploadList={false}
                fileList={artifactImages}
                onChange={async (info: any) => {

                  if (artifact) {
                    console.log('artiface exists ', artifact)
                    console.log('artiface id ', artifact.id)
                    const originFileObj = info.file.originFileObj;
                    const isBlob = originFileObj instanceof Blob;
                    setDeleteImageLoading(true);
                    if (isBlob === true) {
                      const id = artifact.id;
                      getBase64(originFileObj as File, async (data) => {
                        var image = [...artifactImages, data];
                        const uploads = await uploadArtifactImages(
                          authToken,
                          id,
                          image,
                          uploadedImages.length
                        );
                        if (uploads) {
                          setArtifactImages([]);
                        }
                        const dataa = await getArtifactById(
                          authToken,
                          id
                        );
                        setUploadedImages(dataa.data.artifactImages);
                        setDeleteImageLoading(false);
                        setReloading(true);
                      });
                    }
                  } else {
                    console.log('artiface does not exists ', artifact)
                    const artifactData = {
                      name: form.getFieldValue('name'),
                      // artifactImages: [""],
                      description: form.getFieldValue('description'),
                      finder: form.getFieldValue('finder'),
                      material: form.getFieldValue('material'),
                      size: form.getFieldValue('size'),
                      collectorHistory: form.getFieldValue('collectorHistory'),
                      notes: form.getFieldValue('notes'),
                      location: form.getFieldValue('location'),
                      ownersMemberAccountId: authenticatedUser.id,
                      purchaseInfo: form.getFieldValue('purchaseInfo'),
                      memberCatalogId: catalogId,
                    };
                    if (artifactData.name != "") {
                      const createReq = await createArtifact(
                        authToken,
                        artifactData as Artifact
                      );
                      const artifactProfileId = createReq.data.id;
                      console.log('artifactProfileId ', artifactProfileId)
                      const { data } = await getArtifactById(authToken, artifactProfileId);
                      console.log('data after image upload ', data)
                      setArtifact(data);
                      const originFileObj = info.file.originFileObj;
                      const isBlob = originFileObj instanceof Blob;
                      setDeleteImageLoading(true);
                      if (isBlob === true) {
                        getBase64(originFileObj as File, async (data) => {
                          var image = [...artifactImages, data];
                          const uploads = await uploadArtifactImages(
                            authToken,
                            artifactProfileId,
                            image,
                            uploadedImages.length
                          );
                          if (uploads) {
                            setArtifactImages([]);
                          }
                          const dataa = await getArtifactById(
                            authToken,
                            artifactProfileId
                          );
                          setUploadedImages(dataa.data.artifactImages);
                          setDeleteImageLoading(false);
                          setReloading(true);
                        });
                      }
                    }


                  }

                  // const originFileObj = info.file.originFileObj;
                  // const isBlob = originFileObj instanceof Blob;
                  // setDeleteImageLoading(true);
                  // if (isBlob === true) {
                  //   getBase64(originFileObj as File, async (data) => {
                  //     var image = [...artifactImages, data];
                  //     const uploads = await uploadArtifactImages(
                  //       authToken,
                  //       artifactId,
                  //       image,
                  //       uploadedImages.length
                  //     );
                  //     if (uploads) {
                  //       setArtifactImages([]);
                  //     }
                  //     const dataa = await getArtifactById(
                  //       authToken,
                  //       artifactId
                  //     );
                  //     setUploadedImages(dataa.data.artifactImages);
                  //     setDeleteImageLoading(false);
                  //     setReloading(true);
                  //   });
                  // }

                }}
              >
                <UploadOutlined />
              </Upload>
            </Col>
          </Row>
          {/* ) : null} */}

          <Row gutter={8}>
            <Col span={24}>
              <Button
                className="btn-primary"
                type="link"
                htmlType="submit"
                loading={loading}
              >
                {t("Save")}
              </Button>
              &nbsp;
              <Button
                className="btn-primary"
                type="link"
                htmlType="button"
                onClick={() => {
                  history.goBack();
                }}
              >
                {t("Cancel")}
              </Button>
            </Col>
          </Row>
        </>
      </Form>

      {
        showCanclePopup ?
          <Modal
            title="Are You Sure!"
            okText="Yes"
            cancelText="No"
            visible={showCanclePopup} onOk={
              async () => {
                await deleteArtifactById(
                  authToken,
                  artifact?.id as string
                );
                const { data } = await getAllArtifactsByCatalogId(
                  authToken,
                  catalogId
                );
                setCatalog(data);
                // history.push(`/catalogs/`);
                history.goBack();
              }
            } onCancel={() => {
              setShowCanclePopup(false)
              // history.goBack()
            }}>
            <div className="modal-content">
              <p>Are you sure you want to Cancle, All changes will be lost.
              </p>
            </div>
          </Modal>
          :
          ""
      }
    </div>
  );
};

export default ArtifactForm;
